@import "./styles/variables";

$icon-font-path: "../node_modules/bootstrap-sass/assets/fonts/bootstrap/";
$fa-font-path: "./font-awesome/fonts" !default;
$pswp__assets-path: './';
@import "../node_modules/bootstrap-sass/assets/stylesheets/_bootstrap.scss";

@import "./styles/custom_grid";
@import "./styles/bootstrap-dropdownhover";

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* Space out content a bit */
body {
  font-weight: 300;

  @media (max-width: $screen-sm-max) {
    margin-top: 60px;
  }

  max-width: 1920px;

  @media (min-width: $screen-xl-min) {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: $screen-sm-max) {

  h1,
  .h1 {
    font-size: $font-size-h1 * 0.9;
  }

  h2,
  .h2 {
    font-size: $font-size-h2 * 0.9;
  }

  h3,
  .h3 {
    font-size: $font-size-h3 * 0.9;
  }

  h4,
  .h4 {
    font-size: $font-size-h4 * 0.9;
  }

  h5,
  .h5 {
    font-size: $font-size-h5 * 0.9;
  }

  h6,
  .h6 {
    font-size: $font-size-h6 * 0.9;
  }
}


$page-padding-left-md: 15px;
$page-padding-left: 25px + 25px;
$page-padding-left-xl: 25px + 25px;

$page-padding-right-md: 15px;
$page-padding-right: 25px;
$page-padding-right-xl: 25px;

.container-fluid {
  max-width: 1920px;
  margin: 0 auto;

  @media (max-width: $screen-md-max) {
    padding-left: $page-padding-left-md;
    padding-right: $page-padding-right-md;

    .row {
      margin-left: -$page-padding-left-md;
      margin-right: -$page-padding-right-md;
    }

    [class*="col-"] {
      padding-left: $page-padding-left-md;
      padding-right: $page-padding-right-md;
    }
  }

  // @media (min-width: $screen-md-min) {
  //   margin-right: $page-padding-right;
  // }

  @media (min-width: $screen-lg-min) {
    margin-right: $page-padding-right;
  }

  @media (min-width: $screen-xl-min) {
    margin-right: $page-padding-right-xl;
  }
}

.page-padding {
  @media (min-width: $screen-md-min) {
    padding-left: $page-padding-left-md;
    padding-right: $page-padding-right-md;
  }

  @media (min-width: $screen-lg-min) {
    padding-left: $page-padding-left;
    padding-right: $page-padding-right;
  }

  @media (min-width: $screen-xl-min) {
    padding-left: $page-padding-left-xl;
    padding-right: $page-padding-right-xl;
  }
}

.row.row--small-gutters {
  margin-right: -5px;
  margin-left: -5px;
}

.row.row--small-gutters>[class^="col-"],
.row.row--small-gutters>[class*=" col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

@import "./styles/caption.scss";
@import "./styles/collage.scss";
@import "./styles/swiper/pagination";
@import "./styles/icons";
@import "./styles/cookiebanner";
@import "./styles/herounit/main";
@import "./styles/nav";
@import "./styles/content/main";
@import "./styles/content_breadcrumb";
@import "./styles/funding";
@import "./styles/slick/main";
@import "./styles/news/main";

.tw-grid {
  display: grid;
}

@media (min-width: $screen-sm-min) {
  .sm\:tw-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: $screen-md-min) {
  .md\:tw-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media (min-width: $screen-xl-min) {
  .xl\:tw-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.overflow-y-scroll {
  overflow-y: scroll;
}
