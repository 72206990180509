.navbar-stiftungen {
  border: none;
  border-bottom: 1px solid transparent;
  background: $white;
  margin: 0;

  .navbar-container {
    display: flex;

    @media (max-width: $screen-sm-max) {
      flex-direction: column;
    }
  }

  @media (min-width: $screen-md-min) {
    &.navbar-fixed-top {
      position: relative;
      top: auto;
    }
  }

  @media (min-width: $screen-md-min) {
    margin-top: 10px;
  }

  // header which is always shown
  .navbar-header {
    margin: 0;

    // contains site name and site select on larger screens
    .navbar-brand {
      height: auto;
      padding: 0;

      h2 {
        text-transform: uppercase;
      }

      @media (max-width: $screen-sm-max) {
        h2 {
          font-size: 16px;
          text-align: right;
          margin-top: 15px;
        }
      }

      @media (max-width: $screen-sm-max) {
        width: 100%;

        h2 {
          font-size: 16px;
          padding-left: 50px;
        }
      }

      @media (min-width: $screen-md-min) {
        h2 {
          font-size: 18px;
          margin-top: 15px;
          margin-bottom: 5px;
          padding-left: $page-padding-left-md;
        }
      }

      @media (min-width: $screen-lg-min) {
        h2 {
          font-size: 22px;
          margin-top: 26px;
          margin-bottom: 10px;
          white-space: nowrap;
          padding-left: $page-padding-left;
        }
      }

      @media (min-width: $screen-xl-min) {
        h2 {
          font-size: 24px;
          margin-top: 26px;
          margin-bottom: 10px;
        }
      }
    }

    .navbar-toggle {
      float: left;
      border: none;
      margin-right: 0;
      padding: 6px 0;

      span {
        background: $white;
        border: 1px solid $gray-lighter;
        border-radius: 0;
        height: 6px;
        width: 36px;
        margin-top: 3px;
      }
    }

  }

  .nav-mobile-foundation-select {
    h4 {
      font-size: 16px;
      margin-bottom: 0;
      text-transform: uppercase;
    }
  }

  .navbar-listnav {

    &>li>a {
      color: $gray;
      font-size: 12px;
      line-height: 19px;
      padding: 7px 0 4px;
      text-transform: uppercase;
      vertical-align: middle;

      &:hover,
      &:focus {
        background: none;
      }
    }

    &>li {
      border-bottom: 1px solid $brand-primary;

      @media (max-width: $screen-sm-max) {
        padding-left: 10px;
      }

      @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        padding-left: $page-padding-left - 20px;
        white-space: nowrap;
        padding-right: 10px;

        &>a {
          font-size: 13px;
        }
      }

      @media (min-width: $screen-lg-min) {
        padding-left: $page-padding-left;

        &>a {
          font-size: 15px;
        }
      }

      @media (min-width: $screen-xl-min) {
        padding-left: $page-padding-left;

        &>a {
          font-size: 16px;
        }
      }

      &:hover,
      &:focus {
        background: $gray-lighter;

        a {
          color: $brand-primary;
        }
      }

      &.active {
        background-color: $brand-primary;

        a {
          color: $white;
        }
      }
    }
  }

  .navbar-lines {
    .navbar-right {
      // margin-bottom: 0;
      // margin-top: 0;
      // margin-right: 0;
    }

    @media (min-width: $screen-sm-min) {
      .navbar-right {
        // margin-left: 0;
        // margin-right: 0;
      }
    }
  }

  // all definition for the actual in-site navigation
  .navbar-main-collapse {
    flex-grow: 1;

    @media (min-width: $screen-md-min) {
      margin-right: -$page-padding-left-md;
      margin-left: -$page-padding-right-md;
      padding-left: $page-padding-left-md;
      padding-right: $page-padding-right-md;
    }

  }

  .navbar-collapse {
    max-height: 100%;
    height: 100%;
  }

  .navbar-lines {
    display: flex;
    flex-direction: column;

    @media (min-width: $screen-sm-min) {
      height: 100%;

    }
  }

  // ################ first nav line (search, extra, sponsor) ###################
  .navbar-searchline {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;

    @media (min-width: $screen-sm-min) {
      flex-direction: row-reverse;
    }
  }

  .btn-borderless {
    border: none;
    color: $gray-light;
    background: $white;
    padding: 0;
    line-height: 1;

    &:focus {
      outline: none;
    }
  }

  // search form
  .navbar-form {
    .form-control.nav-search-typeahead {
      padding-bottom: 1px;
    }

    @media (min-width: $screen-sm-min) {
      padding-right: 15px;
    }

    @media (min-width: $screen-lg-min) {
      padding-right: 25px;
    }
  }

  @media (max-width: $screen-md-max) {
    .navbar-right {
      margin-right: -15px;
    }
  }

  .input-group .btn-borderless {
    margin-left: 2px;
  }

  .search-icon {
    font-size: 28px;
    line-height: 1;
    margin-left: 10px;

    // @media (min-width: $screen-lg-min) {
    //   height: 30px;
    //   width: 28px;
    // }
    &:hover {
      color: $brand-primary;
    }
  }

  .language-selector {
    &>li>a {
      font-size: 12px;
      padding: 8px 12px 5px;
    }
  }

  /*
   * ##################################
   * lower line, actual page navigation
   */
  .navbar-navline {

    .navbar-nav {
      margin: 0;

      @media (min-width: $screen-sm-min) {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

    .nav>li {
      background: $gray-lighter;
      font-size: 12px;
      float: none;
      margin-top: 2px;

      @media (min-width: $screen-sm-min) {
        font-size: 14px;
        margin-left: 2px;
      }

      @media (min-width: $screen-md-min) {
        font-size: 12px;
      }

      @media (min-width: $screen-lg-min) {
        font-size: 14px;
      }

      @media (min-width: $screen-xl-min) {
        font-size: 16px;
      }

      &>a {
        display: flex;
        align-items: center;
        color: $white;
        display: inline-block;
        line-height: 20px;
        padding: 11px 14px 6px;
        text-transform: uppercase;

        &:hover {
          background: transparent;
          color: lighten($brand-primary, 5%);
        }
      }

      &.active>a {
        background: $brand-primary;

        @media (max-width: $screen-xs-max) {
          width: 100%;
        }

        &:hover,
        &:focus {
          color: $gray-dark;
        }
      }
    }
  }

  // home-icon
  .nav li.nav-homepage {
    a {
      font-size: 23px;
      padding: 4px 22px;
      line-height: 22px;

      .glyphicon {
        top: 3px;
      }
    }

    @media (max-width: $screen-xs-max) {
      padding: 0;
    }

    @media (min-width: $screen-sm-min) {
      margin-left: 10px;
      flex-grow: 1;
      text-align: right;
    }
  }

  .dropdown-menu {
    box-shadow: none;
    background-color: #ccc;
    border: 1px solid #fff;
    border-right: 0;
    border-top-width: 2px;
  }

  .dropdown-menu>li>a {
    text-transform: uppercase;
  }

  .navbar-form .dropdown-menu {
    // right: auto;
    background-color: #eee;

    &>li>a {
      text-transform: none;
    }
  }
}


.no-flexbox {
  .navbar-stiftungen {
    .nav>li {
      float: left;
    }
  }
}
