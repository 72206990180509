/* @tailwind base; */

.tw-prose {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.tw-prose :where(p):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.tw-prose :where([class~="lead"]):not(:where([class~="tw-not-prose"] *)) {
  color: var(--tw-prose-lead);
  font-size: 1.25em;
  line-height: 1.6;
  margin-top: 1.2em;
  margin-bottom: 1.2em;
}

.tw-prose :where(a):not(:where([class~="tw-not-prose"] *)) {
  color: var(--tw-prose-links);
  text-decoration: underline;
  font-weight: 500;
}

.tw-prose :where(strong):not(:where([class~="tw-not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.tw-prose :where(a strong):not(:where([class~="tw-not-prose"] *)) {
  color: inherit;
}

.tw-prose :where(blockquote strong):not(:where([class~="tw-not-prose"] *)) {
  color: inherit;
}

.tw-prose :where(thead th strong):not(:where([class~="tw-not-prose"] *)) {
  color: inherit;
}

.tw-prose :where(ol):not(:where([class~="tw-not-prose"] *)) {
  list-style-type: decimal;
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
}

.tw-prose :where(ol[type="A"]):not(:where([class~="tw-not-prose"] *)) {
  list-style-type: upper-alpha;
}

.tw-prose :where(ol[type="a"]):not(:where([class~="tw-not-prose"] *)) {
  list-style-type: lower-alpha;
}

.tw-prose :where(ol[type="A" s]):not(:where([class~="tw-not-prose"] *)) {
  list-style-type: upper-alpha;
}

.tw-prose :where(ol[type="a" s]):not(:where([class~="tw-not-prose"] *)) {
  list-style-type: lower-alpha;
}

.tw-prose :where(ol[type="I"]):not(:where([class~="tw-not-prose"] *)) {
  list-style-type: upper-roman;
}

.tw-prose :where(ol[type="i"]):not(:where([class~="tw-not-prose"] *)) {
  list-style-type: lower-roman;
}

.tw-prose :where(ol[type="I" s]):not(:where([class~="tw-not-prose"] *)) {
  list-style-type: upper-roman;
}

.tw-prose :where(ol[type="i" s]):not(:where([class~="tw-not-prose"] *)) {
  list-style-type: lower-roman;
}

.tw-prose :where(ol[type="1"]):not(:where([class~="tw-not-prose"] *)) {
  list-style-type: decimal;
}

.tw-prose :where(ul):not(:where([class~="tw-not-prose"] *)) {
  list-style-type: disc;
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
}

.tw-prose :where(ol > li):not(:where([class~="tw-not-prose"] *))::marker {
  font-weight: 400;
  color: var(--tw-prose-counters);
}

.tw-prose :where(ul > li):not(:where([class~="tw-not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.tw-prose :where(hr):not(:where([class~="tw-not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.tw-prose :where(blockquote):not(:where([class~="tw-not-prose"] *)) {
  font-weight: 500;
  font-style: italic;
  color: var(--tw-prose-quotes);
  border-left-width: 0.25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "\201C""\201D""\2018""\2019";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
}

.tw-prose :where(blockquote p:first-of-type):not(:where([class~="tw-not-prose"] *))::before {
  content: open-quote;
}

.tw-prose :where(blockquote p:last-of-type):not(:where([class~="tw-not-prose"] *))::after {
  content: close-quote;
}

.tw-prose :where(h1):not(:where([class~="tw-not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 800;
  font-size: 2.25em;
  margin-top: 0;
  margin-bottom: 0.8888889em;
  line-height: 1.1111111;
}

.tw-prose :where(h1 strong):not(:where([class~="tw-not-prose"] *)) {
  font-weight: 900;
  color: inherit;
}

.tw-prose :where(h2):not(:where([class~="tw-not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 700;
  font-size: 1.5em;
  margin-top: 2em;
  margin-bottom: 1em;
  line-height: 1.3333333;
}

.tw-prose :where(h2 strong):not(:where([class~="tw-not-prose"] *)) {
  font-weight: 800;
  color: inherit;
}

.tw-prose :where(h3):not(:where([class~="tw-not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  font-size: 1.25em;
  margin-top: 1.6em;
  margin-bottom: 0.6em;
  line-height: 1.6;
}

.tw-prose :where(h3 strong):not(:where([class~="tw-not-prose"] *)) {
  font-weight: 700;
  color: inherit;
}

.tw-prose :where(h4):not(:where([class~="tw-not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  line-height: 1.5;
}

.tw-prose :where(h4 strong):not(:where([class~="tw-not-prose"] *)) {
  font-weight: 700;
  color: inherit;
}

.tw-prose :where(img):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.tw-prose :where(figure > *):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.tw-prose :where(figcaption):not(:where([class~="tw-not-prose"] *)) {
  color: var(--tw-prose-captions);
  font-size: 0.875em;
  line-height: 1.4285714;
  margin-top: 0.8571429em;
}

.tw-prose :where(code):not(:where([class~="tw-not-prose"] *)) {
  color: var(--tw-prose-code);
  font-weight: 600;
  font-size: 0.875em;
}

.tw-prose :where(code):not(:where([class~="tw-not-prose"] *))::before {
  content: "`";
}

.tw-prose :where(code):not(:where([class~="tw-not-prose"] *))::after {
  content: "`";
}

.tw-prose :where(a code):not(:where([class~="tw-not-prose"] *)) {
  color: inherit;
}

.tw-prose :where(h1 code):not(:where([class~="tw-not-prose"] *)) {
  color: inherit;
}

.tw-prose :where(h2 code):not(:where([class~="tw-not-prose"] *)) {
  color: inherit;
  font-size: 0.875em;
}

.tw-prose :where(h3 code):not(:where([class~="tw-not-prose"] *)) {
  color: inherit;
  font-size: 0.9em;
}

.tw-prose :where(h4 code):not(:where([class~="tw-not-prose"] *)) {
  color: inherit;
}

.tw-prose :where(blockquote code):not(:where([class~="tw-not-prose"] *)) {
  color: inherit;
}

.tw-prose :where(thead th code):not(:where([class~="tw-not-prose"] *)) {
  color: inherit;
}

.tw-prose :where(pre):not(:where([class~="tw-not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  overflow-x: auto;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1.7142857;
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
  border-radius: 0.375rem;
  padding-top: 0.8571429em;
  padding-right: 1.1428571em;
  padding-bottom: 0.8571429em;
  padding-left: 1.1428571em;
}

.tw-prose :where(pre code):not(:where([class~="tw-not-prose"] *)) {
  background-color: transparent;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}

.tw-prose :where(pre code):not(:where([class~="tw-not-prose"] *))::before {
  content: none;
}

.tw-prose :where(pre code):not(:where([class~="tw-not-prose"] *))::after {
  content: none;
}

.tw-prose :where(table):not(:where([class~="tw-not-prose"] *)) {
  width: 100%;
  table-layout: auto;
  text-align: left;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: 0.875em;
  line-height: 1.7142857;
}

.tw-prose :where(thead):not(:where([class~="tw-not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.tw-prose :where(thead th):not(:where([class~="tw-not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  vertical-align: bottom;
  padding-right: 0.5714286em;
  padding-bottom: 0.5714286em;
  padding-left: 0.5714286em;
}

.tw-prose :where(tbody tr):not(:where([class~="tw-not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.tw-prose :where(tbody tr:last-child):not(:where([class~="tw-not-prose"] *)) {
  border-bottom-width: 0;
}

.tw-prose :where(tbody td):not(:where([class~="tw-not-prose"] *)) {
  vertical-align: baseline;
}

.tw-prose :where(tfoot):not(:where([class~="tw-not-prose"] *)) {
  border-top-width: 1px;
  border-top-color: var(--tw-prose-th-borders);
}

.tw-prose :where(tfoot td):not(:where([class~="tw-not-prose"] *)) {
  vertical-align: top;
}

.tw-prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: rgb(0 0 0 / 50%);
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.tw-prose :where(video):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.tw-prose :where(figure):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.tw-prose :where(li):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.tw-prose :where(ol > li):not(:where([class~="tw-not-prose"] *)) {
  padding-left: 0.375em;
}

.tw-prose :where(ul > li):not(:where([class~="tw-not-prose"] *)) {
  padding-left: 0.375em;
}

.tw-prose :where(.tw-prose > ul > li p):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

.tw-prose :where(.tw-prose > ul > li > *:first-child):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 1.25em;
}

.tw-prose :where(.tw-prose > ul > li > *:last-child):not(:where([class~="tw-not-prose"] *)) {
  margin-bottom: 1.25em;
}

.tw-prose :where(.tw-prose > ol > li > *:first-child):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 1.25em;
}

.tw-prose :where(.tw-prose > ol > li > *:last-child):not(:where([class~="tw-not-prose"] *)) {
  margin-bottom: 1.25em;
}

.tw-prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

.tw-prose :where(hr + *):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 0;
}

.tw-prose :where(h2 + *):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 0;
}

.tw-prose :where(h3 + *):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 0;
}

.tw-prose :where(h4 + *):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 0;
}

.tw-prose :where(thead th:first-child):not(:where([class~="tw-not-prose"] *)) {
  padding-left: 0;
}

.tw-prose :where(thead th:last-child):not(:where([class~="tw-not-prose"] *)) {
  padding-right: 0;
}

.tw-prose :where(tbody td, tfoot td):not(:where([class~="tw-not-prose"] *)) {
  padding-top: 0.5714286em;
  padding-right: 0.5714286em;
  padding-bottom: 0.5714286em;
  padding-left: 0.5714286em;
}

.tw-prose :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="tw-not-prose"] *)) {
  padding-left: 0;
}

.tw-prose :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="tw-not-prose"] *)) {
  padding-right: 0;
}

.tw-prose :where(.tw-prose > :first-child):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 0;
}

.tw-prose :where(.tw-prose > :last-child):not(:where([class~="tw-not-prose"] *)) {
  margin-bottom: 0;
}

.tw-prose-2xl {
  font-size: 1.5rem;
  line-height: 1.6666667;
}

.tw-prose-2xl :where(p):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 1.3333333em;
  margin-bottom: 1.3333333em;
}

.tw-prose-2xl :where([class~="lead"]):not(:where([class~="tw-not-prose"] *)) {
  font-size: 1.25em;
  line-height: 1.4666667;
  margin-top: 1.0666667em;
  margin-bottom: 1.0666667em;
}

.tw-prose-2xl :where(blockquote):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 1.7777778em;
  margin-bottom: 1.7777778em;
  padding-left: 1.1111111em;
}

.tw-prose-2xl :where(h1):not(:where([class~="tw-not-prose"] *)) {
  font-size: 2.6666667em;
  margin-top: 0;
  margin-bottom: 0.875em;
  line-height: 1;
}

.tw-prose-2xl :where(h2):not(:where([class~="tw-not-prose"] *)) {
  font-size: 2em;
  margin-top: 1.5em;
  margin-bottom: 0.8333333em;
  line-height: 1.0833333;
}

.tw-prose-2xl :where(h3):not(:where([class~="tw-not-prose"] *)) {
  font-size: 1.5em;
  margin-top: 1.5555556em;
  margin-bottom: 0.6666667em;
  line-height: 1.2222222;
}

.tw-prose-2xl :where(h4):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 1.6666667em;
  margin-bottom: 0.6666667em;
  line-height: 1.5;
}

.tw-prose-2xl :where(img):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.tw-prose-2xl :where(video):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.tw-prose-2xl :where(figure):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.tw-prose-2xl :where(figure > *):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.tw-prose-2xl :where(figcaption):not(:where([class~="tw-not-prose"] *)) {
  font-size: 0.8333333em;
  line-height: 1.6;
  margin-top: 1em;
}

.tw-prose-2xl :where(code):not(:where([class~="tw-not-prose"] *)) {
  font-size: 0.8333333em;
}

.tw-prose-2xl :where(h2 code):not(:where([class~="tw-not-prose"] *)) {
  font-size: 0.875em;
}

.tw-prose-2xl :where(h3 code):not(:where([class~="tw-not-prose"] *)) {
  font-size: 0.8888889em;
}

.tw-prose-2xl :where(pre):not(:where([class~="tw-not-prose"] *)) {
  font-size: 0.8333333em;
  line-height: 1.8;
  margin-top: 2em;
  margin-bottom: 2em;
  border-radius: 0.5rem;
  padding-top: 1.2em;
  padding-right: 1.6em;
  padding-bottom: 1.2em;
  padding-left: 1.6em;
}

.tw-prose-2xl :where(ol):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 1.3333333em;
  margin-bottom: 1.3333333em;
  padding-left: 1.5833333em;
}

.tw-prose-2xl :where(ul):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 1.3333333em;
  margin-bottom: 1.3333333em;
  padding-left: 1.5833333em;
}

.tw-prose-2xl :where(li):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.tw-prose-2xl :where(ol > li):not(:where([class~="tw-not-prose"] *)) {
  padding-left: 0.4166667em;
}

.tw-prose-2xl :where(ul > li):not(:where([class~="tw-not-prose"] *)) {
  padding-left: 0.4166667em;
}

.tw-prose-2xl :where(.tw-prose-2xl > ul > li p):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 0.8333333em;
  margin-bottom: 0.8333333em;
}

.tw-prose-2xl :where(.tw-prose-2xl > ul > li > *:first-child):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 1.3333333em;
}

.tw-prose-2xl :where(.tw-prose-2xl > ul > li > *:last-child):not(:where([class~="tw-not-prose"] *)) {
  margin-bottom: 1.3333333em;
}

.tw-prose-2xl :where(.tw-prose-2xl > ol > li > *:first-child):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 1.3333333em;
}

.tw-prose-2xl :where(.tw-prose-2xl > ol > li > *:last-child):not(:where([class~="tw-not-prose"] *)) {
  margin-bottom: 1.3333333em;
}

.tw-prose-2xl :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 0.6666667em;
  margin-bottom: 0.6666667em;
}

.tw-prose-2xl :where(hr):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 3em;
  margin-bottom: 3em;
}

.tw-prose-2xl :where(hr + *):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 0;
}

.tw-prose-2xl :where(h2 + *):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 0;
}

.tw-prose-2xl :where(h3 + *):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 0;
}

.tw-prose-2xl :where(h4 + *):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 0;
}

.tw-prose-2xl :where(table):not(:where([class~="tw-not-prose"] *)) {
  font-size: 0.8333333em;
  line-height: 1.4;
}

.tw-prose-2xl :where(thead th):not(:where([class~="tw-not-prose"] *)) {
  padding-right: 0.6em;
  padding-bottom: 0.8em;
  padding-left: 0.6em;
}

.tw-prose-2xl :where(thead th:first-child):not(:where([class~="tw-not-prose"] *)) {
  padding-left: 0;
}

.tw-prose-2xl :where(thead th:last-child):not(:where([class~="tw-not-prose"] *)) {
  padding-right: 0;
}

.tw-prose-2xl :where(tbody td, tfoot td):not(:where([class~="tw-not-prose"] *)) {
  padding-top: 0.8em;
  padding-right: 0.6em;
  padding-bottom: 0.8em;
  padding-left: 0.6em;
}

.tw-prose-2xl :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="tw-not-prose"] *)) {
  padding-left: 0;
}

.tw-prose-2xl :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="tw-not-prose"] *)) {
  padding-right: 0;
}

.tw-prose-2xl :where(.tw-prose-2xl > :first-child):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 0;
}

.tw-prose-2xl :where(.tw-prose-2xl > :last-child):not(:where([class~="tw-not-prose"] *)) {
  margin-bottom: 0;
}

.tw-col-span-12 {
  grid-column: span 12 / span 12 !important;
}

.tw-col-span-3 {
  grid-column: span 3 / span 3 !important;
}

.tw-col-span-4 {
  grid-column: span 4 / span 4 !important;
}

.tw-col-span-8 {
  grid-column: span 8 / span 8 !important;
}

.tw-col-span-9 {
  grid-column: span 9 / span 9 !important;
}

.tw-clear-none {
  clear: none !important;
}

.tw-mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.tw-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.tw--mt-6 {
  margin-top: -1.5rem !important;
}

.tw-mb-3 {
  margin-bottom: 0.75rem !important;
}

.tw-mb-8 {
  margin-bottom: 2rem !important;
}

.tw-mb-auto {
  margin-bottom: auto !important;
}

.tw-ml-2 {
  margin-left: 0.5rem !important;
}

.tw-mt-4 {
  margin-top: 1rem !important;
}

.tw-mt-auto {
  margin-top: auto !important;
}

.tw-block {
  display: block !important;
}

.tw-flex {
  display: flex !important;
}

.tw-grid {
  display: grid !important;
}

.tw-hidden {
  display: none !important;
}

.tw-h-12 {
  height: 3rem !important;
}

.tw-h-20 {
  height: 5rem !important;
}

.tw-h-24 {
  height: 6rem !important;
}

.tw-h-4 {
  height: 1rem !important;
}

.tw-h-5 {
  height: 1.25rem !important;
}

.tw-h-64 {
  height: 16rem !important;
}

.tw-h-auto {
  height: auto !important;
}

.tw-h-full {
  height: 100% !important;
}

.tw-w-4 {
  width: 1rem !important;
}

.tw-w-5 {
  width: 1.25rem !important;
}

.tw-w-auto {
  width: auto !important;
}

.tw-w-full {
  width: 100% !important;
}

.tw-max-w-full {
  max-width: 100% !important;
}

.tw-max-w-screen-2xl {
  max-width: 1536px !important;
}

.tw-cursor-pointer {
  cursor: pointer !important;
}

.tw-break-inside-avoid {
  break-inside: avoid !important;
}

.tw-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

.tw-grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
}

.tw-flex-row-reverse {
  flex-direction: row-reverse !important;
}

.tw-flex-col {
  flex-direction: column !important;
}

.tw-items-center {
  align-items: center !important;
}

.tw-justify-end {
  justify-content: flex-end !important;
}

.tw-justify-center {
  justify-content: center !important;
}

.tw-justify-between {
  justify-content: space-between !important;
}

.tw-gap-4 {
  gap: 1rem !important;
}

.tw-gap-8 {
  gap: 2rem !important;
}

.tw-gap-x-4 {
  column-gap: 1rem !important;
}

.tw-gap-y-2 {
  row-gap: 0.5rem !important;
}

.tw-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse)) !important;
}

.tw-overflow-hidden {
  overflow: hidden !important;
}

.tw-whitespace-normal {
  white-space: normal !important;
}

.tw-break-words {
  overflow-wrap: break-word !important;
}

.tw-border {
  border-width: 1px !important;
}

.tw-border-b {
  border-bottom-width: 1px !important;
}

.tw-border-solid {
  border-style: solid !important;
}

.tw-border-transparent {
  border-color: transparent !important;
}

.tw-bg-gray-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity)) !important;
}

.tw-bg-gray-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity)) !important;
}

.tw-bg-gray-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(212 212 212 / var(--tw-bg-opacity)) !important;
}

.tw-bg-stiftungen-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(165 15 23 / var(--tw-bg-opacity)) !important;
}

.tw-bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.tw-object-contain {
  object-fit: contain !important;
}

.tw-object-center {
  object-position: center !important;
}

.tw-p-2 {
  padding: 0.5rem !important;
}

.tw-p-4 {
  padding: 1rem !important;
}

.tw-p-8 {
  padding: 2rem !important;
}

.tw-px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.tw-px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.tw-px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.tw-py-12 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.tw-pb-2 {
  padding-bottom: 0.5rem !important;
}

.tw-pl-0 {
  padding-left: 0px !important;
}

.tw-pt-0 {
  padding-top: 0px !important;
}

.tw-pt-1 {
  padding-top: 0.25rem !important;
}

.tw-pt-1\.5 {
  padding-top: 0.375rem !important;
}

.tw-pt-2 {
  padding-top: 0.5rem !important;
}

.tw-pt-4 {
  padding-top: 1rem !important;
}

.tw-text-left {
  text-align: left !important;
}

.tw-text-center {
  text-align: center !important;
}

.tw-text-2xl {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}

.tw-text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.tw-text-lg {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.tw-text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.tw-font-bold {
  font-weight: 700 !important;
}

.tw-font-semibold {
  font-weight: 600 !important;
}

.tw-uppercase {
  text-transform: uppercase !important;
}

.tw-leading-6 {
  line-height: 1.5rem !important;
}

.tw-text-gray-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(115 115 115 / var(--tw-text-opacity)) !important;
}

.tw-text-gray-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(64 64 64 / var(--tw-text-opacity)) !important;
}

.tw-text-gray-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(38 38 38 / var(--tw-text-opacity)) !important;
}

.tw-text-gray-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(23 23 23 / var(--tw-text-opacity)) !important;
}

.tw-text-green-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(5 150 105 / var(--tw-text-opacity)) !important;
}

.tw-text-red-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(220 38 38 / var(--tw-text-opacity)) !important;
}

.tw-text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.tw-text-yellow-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(202 138 4 / var(--tw-text-opacity)) !important;
}

.tw-opacity-100 {
  opacity: 1 !important;
}

.tw-shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1) !important;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.tw-transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.tw-transition-opacity {
  transition-property: opacity !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.tw-duration-300 {
  transition-duration: 300ms !important;
}

.tw-ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}

[x-cloak] {
  display: none;
}

.hover\:tw-border-b-gray-500:hover {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(115 115 115 / var(--tw-border-opacity)) !important;
}

.hover\:tw-bg-gray-300:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(212 212 212 / var(--tw-bg-opacity)) !important;
}

.hover\:tw-no-underline:hover {
  text-decoration-line: none !important;
}

.tw-group:hover .group-hover\:tw-bg-stiftungen-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(149 10 17 / var(--tw-bg-opacity)) !important;
}

.tw-group:hover .group-hover\:tw-opacity-75 {
  opacity: 0.75 !important;
}

@media print {

  .print\:tw-hidden {
    display: none !important;
  }
}

@media not all and (min-width: 768px) {

  .max-md\:tw-relative {
    position: relative !important;
  }

  .max-md\:tw-float-none {
    float: none !important;
  }

  .max-md\:tw-block {
    display: block !important;
  }

  .max-md\:tw-bg-white {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
  }
}

@media (min-width: 640px) {

  .sm\:tw-flex {
    display: flex !important;
  }

  .sm\:tw-hidden {
    display: none !important;
  }

  .sm\:tw-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .sm\:tw-px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}

@media (min-width: 768px) {

  .md\:tw-clear-both {
    clear: both !important;
  }

  .md\:tw-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .md\:tw-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .md\:tw-whitespace-nowrap {
    white-space: nowrap !important;
  }
}

@media (min-width: 1024px) {

  .lg\:tw-mx-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .lg\:tw-block {
    display: block !important;
  }

  .lg\:tw-h-64 {
    height: 16rem !important;
  }

  .lg\:tw-w-full {
    width: 100% !important;
  }

  .lg\:tw-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .lg\:tw-self-start {
    align-self: flex-start !important;
  }

  .lg\:tw-px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}

@media (min-width: 1280px) {

  .xl\:tw-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}
