.content-text {
  line-height: 1.75;
  color: $gray-light;

  h2 {
    font-size: 24px;
    text-transform: uppercase;
    text-align: left;

    @media (min-width: $screen-md-min) {
      font-size: 30px;
    }
  }

  h3 {
    color: $black;
    font-size: 20px;
    text-transform: uppercase;
    text-align: left;

    @media (min-width: $screen-md-min) {
      font-size: 24px;
    }
  }
}

.content {
  hr {
    // border-top: 1px solid $gray;
    // border-bottom: 1px solid $gray;
    // height: 4px;
  }

  .text-intro {
    color: $brand-primary;
  }
}

.content-header {
  margin-top: 8px;

  @media (min-width: $screen-sm-min) {
    margin-top: 25px;
  }
}

.content-full {
  @media (max-width: $screen-sm-max) {
    margin-top: 10px;
  }

  margin-bottom: 2em;

  .content-full-title {
    font-size: 20px;
    hyphens: auto;
    text-transform: uppercase;
    font-weight: normal;

    @media (min-width: $screen-sm-min) {
      font-size: 23px;
    }

    @media (min-width: $screen-md-min) {
      font-size: 26px;
    }

    @media (min-width: $screen-xl-min) {
      font-size: 30px;
    }

    &.has-condolence-mode {
      color: $black;
    }
  }

  .content-body {

    strong,
    b {
      color: $brand-primary;
    }

    &.has-condolence-mode {

      .content-intro,
      .text-intro,
      strong,
      b {
        color: $black;
      }
    }
  }

  .content-intro,
  .content-author {
    font-size: 15px;
    margin-bottom: 20px;
  }

  .content-intro {
    color: $brand-primary;
  }

  .content-author {}

  .content-body {
    text-align: justify;
  }
}

.herounit-gallery {
  padding-bottom: 20px;

  @media (max-width: $screen-xs-max) {
    margin-top: 15px;
  }
}

.content-thumbnail-container {
  padding-top: 10px;
  margin-left: 0px;
  padding-right: 0px;
}

.content-thumbnails {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;

  .slick-slider {
    width: 100%;
    overflow: hidden;
  }

  // .slider-detail .slick-arrow.arrow-right {
  //   right: 5px;
  // }

  // .item {
  //   padding-left: 5px;
  //   padding-right: 5px;
  // }

  .slick-list {
    overflow: hidden;
    width: calc(100% + 20px);
  }

  .slick-track {
    margin-left: -10px;
  }

  .slick-slide>div {
    margin: 0 10px;
    // padding-right: 30px;

  }

  // @media (min-width: $screen-sm-min) {
  //   .slider-detail {
  //     margin-left: -21px;
  //     margin-right: -7px;
  //   }

  //   .item {
  //     padding-left: 8px;
  //     padding-right: 8px;
  //   }
  // }

  // @media (min-width: $screen-md-min) {
  //   .slider-detail {
  //     margin-left: -10px;
  //     margin-right: -10px;
  //   }

  //   .item {
  //     padding-left: 10px;
  //     padding-right: 10px;
  //   }
  // }
}

.slider-hero-detail {

  .content-gallery img.img-responsive {
    min-width: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

.slide-info {
  position: absolute;
  background: #fff;
  top: 0px;
  left: 0px;
  /* overflow: hidden; */
  z-index: 1;
  padding: 5px 10px;
}

.content-gallery {
  position: relative;
  cursor: pointer;

  img.img-responsive {
    min-width: 100%;
  }

  a.gallery-enlarge {
    @extend .glyphicon-fullscreen;
  }

  a.gallery-enlarge:before {
    background: transparentize($gray-lighter, 0.4);
    color: $white;
    font-family: 'Glyphicons Halflings';
    font-size: 1.6em;
    line-height: 1.8em;
    padding: 0 0.4em;
    position: absolute;
    right: 1em;
    text-align: center;
    top: 0.75em;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }

  .caption {
    background: transparentize($gray-lighter, 0.4);
    bottom: 0;
    color: $white;
    cursor: auto;
    font-size: 12px;
    height: auto;
    padding: 10px;
    width: 100%;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.35);

    @media (min-width: $screen-md-min) {
      font-size: 13px;
    }

    @media (min-width: $screen-lg-min) {
      font-size: 14px;
    }

    .image-rights {
      text-align: right;
    }
  }

  a.gallery-enlarge:before,
  .caption {
    background: transparent;
    opacity: 0;
    transition: visibility 0.5s linear 0s, opacity .15s linear, background .15s linear;
    position: absolute;
    visibility: hidden;
  }

  @media (min-width: $screen-md-min) {
    &:hover {

      a.gallery-enlarge:before,
      .caption {
        visibility: visible;
        opacity: 1.0;
        background: transparentize($gray-lighter, 0.4);
      }
    }
  }
}

.uppercase {
  text-transform: uppercase;
}

.content-project-inline {
  margin-bottom: 15px;
}

.funding-inline {
  background: $brand-light;
  padding: 10px;
}

.content-inline-title {
  font-size: 105%;
  margin-bottom: 4px;
  text-transform: uppercase;
}

.content-inline-news {
  background: lighten($brand-gray, 5%);
  padding: 10px;

  .list-unstyled {
    margin-bottom: 0;
  }
}

.nav-tabs>li.active.parent>a {
  background: #ddd;
  cursor: pointer;
}

.embed--inline {
  margin-bottom: 2em;
}

@import "reel";
@import "persons";
@import "search";
@import "home";
@import "tabbed_media";
