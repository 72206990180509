@keyframes bounce {
  from, 20%, 53%, 80%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%, 43% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0, -4px, 0);
  }
}

.bounce {
  transform-origin: bottom;
  animation-name: bounce;
}

@keyframes flash {
  from, 50%, to {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

.flash {
  animation-name: flash;
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  animation-name: pulse;
}

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, .75, 1);
  }

  40% {
    transform: scale3d(.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, .85, 1);
  }

  65% {
    transform: scale3d(.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, .95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.rubberBand {
  animation-name: rubberBand;
}

@keyframes shake {
  from, to {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}

.shake {
  animation-name: shake;
}

@keyframes headShake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px)rotateY(-9deg);
  }

  18.5% {
    transform: translateX(5px)rotateY(7deg);
  }

  31.5% {
    transform: translateX(-3px)rotateY(-5deg);
  }

  43.5% {
    transform: translateX(2px)rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}

.headShake {
  animation-name: headShake;
  animation-timing-function: ease-in-out;
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    transform: rotate3d(0, 0, 1, 0);
  }
}

.swing {
  transform-origin: top;
  animation-name: swing;
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%, 20% {
    transform: scale3d(.9, .9, .9)rotate3d(0, 0, 1, -3deg);
  }

  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, 3deg);
  }

  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.tada {
  animation-name: tada;
}

@keyframes wobble {
  from {
    transform: translate3d(0, 0, 0);
  }

  15% {
    transform: translate3d(-25%, 0, 0)rotate3d(0, 0, 1, -5deg);
  }

  30% {
    transform: translate3d(20%, 0, 0)rotate3d(0, 0, 1, 3deg);
  }

  45% {
    transform: translate3d(-15%, 0, 0)rotate3d(0, 0, 1, -3deg);
  }

  60% {
    transform: translate3d(10%, 0, 0)rotate3d(0, 0, 1, 2deg);
  }

  75% {
    transform: translate3d(-5%, 0, 0)rotate3d(0, 0, 1, -1deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.wobble {
  animation-name: wobble;
}

@keyframes jello {
  from, 11.1%, to {
    transform: translate3d(0, 0, 0);
  }

  22.2% {
    transform: skewX(-12.5deg)skewY(-12.5deg);
  }

  33.3% {
    transform: skewX(6.25deg)skewY(6.25deg);
  }

  44.4% {
    transform: skewX(-3.125deg)skewY(-3.125deg);
  }

  55.5% {
    transform: skewX(1.5625deg)skewY(1.5625deg);
  }

  66.6% {
    transform: skewX(-.78125deg)skewY(-.78125deg);
  }

  77.7% {
    transform: skewX(.390625deg)skewY(.390625deg);
  }

  88.8% {
    transform: skewX(-.195313deg)skewY(-.195313deg);
  }
}

.jello {
  transform-origin: center;
  animation-name: jello;
}

@keyframes heartBeat {
  0% {
    transform: scale(1);
  }

  14% {
    transform: scale(1.3);
  }

  28% {
    transform: scale(1);
  }

  42% {
    transform: scale(1.3);
  }

  70% {
    transform: scale(1);
  }
}

.heartBeat {
  animation-name: heartBeat;
  animation-duration: 1.3s;
  animation-timing-function: ease-in-out;
}

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.bounceIn {
  animation-name: bounceIn;
  animation-duration: .75s;
}

@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.bounceInDown {
  animation-name: bounceInDown;
}

@keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.bounceInLeft {
  animation-name: bounceInLeft;
}

@keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.bounceInRight {
  animation-name: bounceInRight;
}

@keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.bounceInUp {
  animation-name: bounceInUp;
}

@keyframes bounceOut {
  20% {
    transform: scale3d(.9, .9, .9);
  }

  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
}

.bounceOut {
  animation-name: bounceOut;
  animation-duration: .75s;
}

@keyframes bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.bounceOutDown {
  animation-name: bounceOutDown;
}

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

.bounceOutLeft {
  animation-name: bounceOutLeft;
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

.bounceOutRight {
  animation-name: bounceOutRight;
}

@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

.bounceOutUp {
  animation-name: bounceOutUp;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDownBig {
  animation-name: fadeInDownBig;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeftBig {
  animation-name: fadeInLeftBig;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInRightBig {
  from {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRightBig {
  animation-name: fadeInRightBig;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUpBig {
  from {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUpBig {
  animation-name: fadeInUpBig;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.fadeOutDown {
  animation-name: fadeOutDown;
}

@keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.fadeOutDownBig {
  animation-name: fadeOutDownBig;
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

.fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

.fadeOutLeftBig {
  animation-name: fadeOutLeftBig;
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

.fadeOutRight {
  animation-name: fadeOutRight;
}

@keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

.fadeOutRightBig {
  animation-name: fadeOutRightBig;
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.fadeOutUp {
  animation-name: fadeOutUp;
}

@keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

.fadeOutUpBig {
  animation-name: fadeOutUpBig;
}

@keyframes flip {
  from {
    animation-timing-function: ease-out;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 0)rotate3d(0, 1, 0, -360deg);
  }

  40% {
    animation-timing-function: ease-out;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 150px)rotate3d(0, 1, 0, -190deg);
  }

  50% {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 150px)rotate3d(0, 1, 0, -170deg);
  }

  80% {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(.95, .95, .95)translate3d(0, 0, 0)rotate3d(0, 1, 0, 0);
  }

  to {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 0)rotate3d(0, 1, 0, 0);
  }
}

.animated.flip {
  backface-visibility: visible;
  animation-name: flip;
}

@keyframes flipInX {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.flipInX {
  animation-name: flipInX;
  backface-visibility: visible !important;
}

@keyframes flipInY {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(0, 1, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.flipInY {
  animation-name: flipInY;
  backface-visibility: visible !important;
}

@keyframes flipOutX {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.flipOutX {
  animation-name: flipOutX;
  animation-duration: .75s;
  backface-visibility: visible !important;
}

@keyframes flipOutY {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, -15deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }
}

.flipOutY {
  animation-name: flipOutY;
  animation-duration: .75s;
  backface-visibility: visible !important;
}

@keyframes lightSpeedIn {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(-30deg);
  }

  60% {
    opacity: 1;
    transform: skewX(20deg);
  }

  80% {
    transform: skewX(-5deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.lightSpeedIn {
  animation-name: lightSpeedIn;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(30deg);
  }
}

.lightSpeedOut {
  animation-name: lightSpeedOut;
  animation-timing-function: ease-in;
}

@keyframes rotateIn {
  from {
    transform-origin: center;
    opacity: 0;
    transform: rotate3d(0, 0, 1, -200deg);
  }

  to {
    transform-origin: center;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.rotateIn {
  animation-name: rotateIn;
}

@keyframes rotateInDownLeft {
  from {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }

  to {
    transform-origin: 0 100%;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.rotateInDownLeft {
  animation-name: rotateInDownLeft;
}

@keyframes rotateInDownRight {
  from {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }

  to {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.rotateInDownRight {
  animation-name: rotateInDownRight;
}

@keyframes rotateInUpLeft {
  from {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }

  to {
    transform-origin: 0 100%;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.rotateInUpLeft {
  animation-name: rotateInUpLeft;
}

@keyframes rotateInUpRight {
  from {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate3d(0, 0, 1, -90deg);
  }

  to {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.rotateInUpRight {
  animation-name: rotateInUpRight;
}

@keyframes rotateOut {
  from {
    transform-origin: center;
    opacity: 1;
  }

  to {
    transform-origin: center;
    opacity: 0;
    transform: rotate3d(0, 0, 1, 200deg);
  }
}

.rotateOut {
  animation-name: rotateOut;
}

@keyframes rotateOutDownLeft {
  from {
    transform-origin: 0 100%;
    opacity: 1;
  }

  to {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }
}

.rotateOutDownLeft {
  animation-name: rotateOutDownLeft;
}

@keyframes rotateOutDownRight {
  from {
    transform-origin: 100% 100%;
    opacity: 1;
  }

  to {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }
}

.rotateOutDownRight {
  animation-name: rotateOutDownRight;
}

@keyframes rotateOutUpLeft {
  from {
    transform-origin: 0 100%;
    opacity: 1;
  }

  to {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }
}

.rotateOutUpLeft {
  animation-name: rotateOutUpLeft;
}

@keyframes rotateOutUpRight {
  from {
    transform-origin: 100% 100%;
    opacity: 1;
  }

  to {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate3d(0, 0, 1, 90deg);
  }
}

.rotateOutUpRight {
  animation-name: rotateOutUpRight;
}

@keyframes hinge {
  0% {
    transform-origin: 0 0;
    animation-timing-function: ease-in-out;
  }

  20%, 60% {
    transform-origin: 0 0;
    animation-timing-function: ease-in-out;
    transform: rotate3d(0, 0, 1, 80deg);
  }

  40%, 80% {
    transform-origin: 0 0;
    opacity: 1;
    animation-timing-function: ease-in-out;
    transform: rotate3d(0, 0, 1, 60deg);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 700px, 0);
  }
}

.hinge {
  animation-name: hinge;
  animation-duration: 2s;
}

@keyframes jackInTheBox {
  from {
    opacity: 0;
    transform-origin: bottom;
    transform: scale(.1)rotate(30deg);
  }

  50% {
    transform: rotate(-10deg);
  }

  70% {
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.jackInTheBox {
  animation-name: jackInTheBox;
}

@keyframes rollIn {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)rotate3d(0, 0, 1, -120deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.rollIn {
  animation-name: rollIn;
}

@keyframes rollOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)rotate3d(0, 0, 1, 120deg);
  }
}

.rollOut {
  animation-name: rollOut;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomInDown {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, -1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }
}

.zoomInDown {
  animation-name: zoomInDown;
}

@keyframes zoomInLeft {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(-1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(10px, 0, 0);
  }
}

.zoomInLeft {
  animation-name: zoomInLeft;
}

@keyframes zoomInRight {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(-10px, 0, 0);
  }
}

.zoomInRight {
  animation-name: zoomInRight;
}

@keyframes zoomInUp {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, 1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }
}

.zoomInUp {
  animation-name: zoomInUp;
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.zoomOut {
  animation-name: zoomOut;
}

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }

  to {
    opacity: 0;
    transform-origin: bottom;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, 2000px, 0);
  }
}

.zoomOutDown {
  animation-name: zoomOutDown;
}

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(42px, 0, 0);
  }

  to {
    opacity: 0;
    transform-origin: 0;
    transform: scale(.1)translate3d(-2000px, 0, 0);
  }
}

.zoomOutLeft {
  animation-name: zoomOutLeft;
}

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(-42px, 0, 0);
  }

  to {
    opacity: 0;
    transform-origin: 100%;
    transform: scale(.1)translate3d(2000px, 0, 0);
  }
}

.zoomOutRight {
  animation-name: zoomOutRight;
}

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }

  to {
    opacity: 0;
    transform-origin: bottom;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, -2000px, 0);
  }
}

.zoomOutUp {
  animation-name: zoomOutUp;
}

@keyframes slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  animation-name: slideInDown;
}

@keyframes slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideInRight {
  from {
    visibility: visible;
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}

@keyframes slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  animation-name: slideInUp;
}

@keyframes slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }
}

.slideOutDown {
  animation-name: slideOutDown;
}

@keyframes slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
}

.slideOutLeft {
  animation-name: slideOutLeft;
}

@keyframes slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
}

.slideOutRight {
  animation-name: slideOutRight;
}

@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
  }
}

.slideOutUp {
  animation-name: slideOutUp;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.delay-1s {
  animation-delay: 1s;
}

.animated.delay-2s {
  animation-delay: 2s;
}

.animated.delay-3s {
  animation-delay: 3s;
}

.animated.delay-4s {
  animation-delay: 4s;
}

.animated.delay-5s {
  animation-delay: 5s;
}

.animated.fast {
  animation-duration: .8s;
}

.animated.faster {
  animation-duration: .5s;
}

.animated.slow {
  animation-duration: 2s;
}

.animated.slower {
  animation-duration: 3s;
}

@media (print), (prefers-reduced-motion: reduce) {
  .animated {
    transition-duration: 1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }
}

select.bs-select-hidden, select.selectpicker {
  display: none !important;
}

.bootstrap-select {
  width: 220px �;
}

.bootstrap-select > .dropdown-toggle {
  width: 100%;
  z-index: 1;
  padding-right: 25px;
}

.bootstrap-select > .dropdown-toggle.bs-placeholder, .bootstrap-select > .dropdown-toggle.bs-placeholder:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder:active {
  color: #999;
}

.bootstrap-select > select {
  border: none;
  bottom: 0;
  left: 50%;
  width: .5px !important;
  height: 100% !important;
  opacity: 0 !important;
  padding: 0 !important;
  display: block !important;
  position: absolute !important;
}

.bootstrap-select > select.mobile-device {
  z-index: 2;
  top: 0;
  left: 0;
  width: 100% !important;
  display: block !important;
}

.has-error .bootstrap-select .dropdown-toggle, .error .bootstrap-select .dropdown-toggle {
  border-color: #b94a48;
}

.bootstrap-select.fit-width {
  width: auto !important;
}

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 220px;
}

.bootstrap-select .dropdown-toggle:focus {
  outline-offset: -2px;
  outline: thin dotted #333 !important;
  outline: 5px auto -webkit-focus-ring-color !important;
}

.bootstrap-select.form-control {
  border: none;
  margin-bottom: 0;
  padding: 0;
}

.bootstrap-select.form-control:not([class*="col-"]) {
  width: 100%;
}

.bootstrap-select.form-control.input-group-btn {
  z-index: auto;
}

.bootstrap-select.form-control.input-group-btn:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.bootstrap-select.btn-group:not(.input-group-btn), .bootstrap-select.btn-group[class*="col-"] {
  float: none;
  margin-left: 0;
  display: inline-block;
}

.bootstrap-select.btn-group.dropdown-menu-right, .bootstrap-select.btn-group[class*="col-"].dropdown-menu-right, .row .bootstrap-select.btn-group[class*="col-"].dropdown-menu-right {
  float: right;
}

.form-inline .bootstrap-select.btn-group, .form-horizontal .bootstrap-select.btn-group, .form-group .bootstrap-select.btn-group {
  margin-bottom: 0;
}

.form-group-lg .bootstrap-select.btn-group.form-control, .form-group-sm .bootstrap-select.btn-group.form-control {
  padding: 0;
}

.form-group-lg .bootstrap-select.btn-group.form-control .dropdown-toggle, .form-group-sm .bootstrap-select.btn-group.form-control .dropdown-toggle {
  height: 100%;
  font-size: inherit;
  line-height: inherit;
  border-radius: inherit;
}

.form-inline .bootstrap-select.btn-group .form-control {
  width: 100%;
}

.bootstrap-select.btn-group.disabled, .bootstrap-select.btn-group > .disabled {
  cursor: not-allowed;
}

.bootstrap-select.btn-group.disabled:focus, .bootstrap-select.btn-group > .disabled:focus {
  outline: none !important;
}

.bootstrap-select.btn-group.bs-container {
  position: absolute;
  height: 0 !important;
  padding: 0 !important;
}

.bootstrap-select.btn-group.bs-container .dropdown-menu {
  z-index: 1060;
}

.bootstrap-select.btn-group .dropdown-toggle .filter-option {
  width: 100%;
  text-align: left;
  display: inline-block;
  overflow: hidden;
}

.bootstrap-select.btn-group .dropdown-toggle .caret {
  vertical-align: middle;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  right: 12px;
}

.bootstrap-select.btn-group[class*="col-"] .dropdown-toggle {
  width: 100%;
}

.bootstrap-select.btn-group .dropdown-menu {
  min-width: 100%;
  box-sizing: border-box;
}

.bootstrap-select.btn-group .dropdown-menu.inner {
  float: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
  position: static;
}

.bootstrap-select.btn-group .dropdown-menu li {
  position: relative;
}

.bootstrap-select.btn-group .dropdown-menu li.active small {
  color: #fff;
}

.bootstrap-select.btn-group .dropdown-menu li.disabled a {
  cursor: not-allowed;
}

.bootstrap-select.btn-group .dropdown-menu li a {
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.bootstrap-select.btn-group .dropdown-menu li a.opt {
  padding-left: 2.25em;
  position: relative;
}

.bootstrap-select.btn-group .dropdown-menu li a span.check-mark {
  display: none;
}

.bootstrap-select.btn-group .dropdown-menu li a span.text {
  display: inline-block;
}

.bootstrap-select.btn-group .dropdown-menu li small {
  padding-left: .5em;
}

.bootstrap-select.btn-group .dropdown-menu .notify {
  width: 96%;
  min-height: 26px;
  pointer-events: none;
  opacity: .9;
  box-sizing: border-box;
  background: #f5f5f5;
  border: 1px solid #e3e3e3;
  margin: 0 2%;
  padding: 3px 5px;
  position: absolute;
  bottom: 5px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
}

.bootstrap-select.btn-group .no-results {
  white-space: nowrap;
  background: #f5f5f5;
  margin: 0 5px;
  padding: 3px;
}

.bootstrap-select.btn-group.fit-width .dropdown-toggle .filter-option {
  position: static;
}

.bootstrap-select.btn-group.fit-width .dropdown-toggle .caret {
  margin-top: -1px;
  position: static;
  top: auto;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark {
  margin-top: 5px;
  display: inline-block;
  position: absolute;
  right: 15px;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text {
  margin-right: 34px;
}

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle {
  z-index: 1061;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle:before {
  content: "";
  border-bottom: 7px solid rgba(204, 204, 204, .2);
  border-left: 7px solid rgba(0, 0, 0, 0);
  border-right: 7px solid rgba(0, 0, 0, 0);
  display: none;
  position: absolute;
  bottom: -4px;
  left: 9px;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle:after {
  content: "";
  border-bottom: 6px solid #fff;
  border-left: 6px solid rgba(0, 0, 0, 0);
  border-right: 6px solid rgba(0, 0, 0, 0);
  display: none;
  position: absolute;
  bottom: -4px;
  left: 10px;
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:before {
  border-top: 7px solid rgba(204, 204, 204, .2);
  border-bottom: 0;
  top: -3px;
  bottom: auto;
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:after {
  border-top: 6px solid #fff;
  border-bottom: 0;
  top: -3px;
  bottom: auto;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:before {
  left: auto;
  right: 12px;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:after {
  left: auto;
  right: 13px;
}

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:before, .bootstrap-select.show-menu-arrow.open > .dropdown-toggle:after {
  display: block;
}

.bs-searchbox, .bs-actionsbox, .bs-donebutton {
  padding: 4px 8px;
}

.bs-actionsbox {
  width: 100%;
  box-sizing: border-box;
}

.bs-actionsbox .btn-group button {
  width: 50%;
}

.bs-donebutton {
  float: left;
  width: 100%;
  box-sizing: border-box;
}

.bs-donebutton .btn-group button {
  width: 100%;
}

.bs-searchbox + .bs-actionsbox {
  padding: 0 8px 4px;
}

.bs-searchbox .form-control {
  width: 100%;
  float: none;
  margin-bottom: 0;
}

.pswp {
  width: 100%;
  height: 100%;
  -ms-touch-action: none;
  touch-action: none;
  z-index: 1500;
  -webkit-text-size-adjust: 100%;
  -webkit-backface-visibility: hidden;
  outline: none;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.pswp * {
  box-sizing: border-box;
}

.pswp img {
  max-width: none;
}

.pswp--animate_opacity {
  opacity: .001;
  will-change: opacity;
  transition: opacity .333s cubic-bezier(.4, 0, .22, 1);
}

.pswp--open {
  display: block;
}

.pswp--zoom-allowed .pswp__img {
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.pswp--zoomed-in .pswp__img {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.pswp--dragging .pswp__img {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.pswp__bg {
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  will-change: opacity;
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateZ(0);
}

.pswp__scroll-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.pswp__container, .pswp__zoom-wrap {
  -ms-touch-action: none;
  touch-action: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.pswp__container, .pswp__img {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.pswp__zoom-wrap {
  width: 100%;
  transform-origin: 0 0;
  -webkit-transition: -webkit-transform .333s cubic-bezier(.4, 0, .22, 1);
  transition: transform .333s cubic-bezier(.4, 0, .22, 1);
  position: absolute;
}

.pswp__bg {
  will-change: opacity;
  transition: opacity .333s cubic-bezier(.4, 0, .22, 1);
}

.pswp--animated-in .pswp__bg, .pswp--animated-in .pswp__zoom-wrap {
  transition: none;
}

.pswp__container, .pswp__zoom-wrap {
  -webkit-backface-visibility: hidden;
}

.pswp__item {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.pswp__img {
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.pswp__img--placeholder {
  -webkit-backface-visibility: hidden;
}

.pswp__img--placeholder--blank {
  background: #222;
}

.pswp--ie .pswp__img {
  top: 0;
  left: 0;
  width: 100% !important;
  height: auto !important;
}

.pswp__error-msg {
  width: 100%;
  text-align: center;
  color: #ccc;
  margin-top: -8px;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 50%;
  left: 0;
}

.pswp__error-msg a {
  color: #ccc;
  text-decoration: underline;
}

.pswp__button {
  width: 44px;
  height: 44px;
  cursor: pointer;
  -webkit-appearance: none;
  float: right;
  opacity: .75;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  transition: opacity .2s;
  display: block;
  position: relative;
  overflow: visible;
}

.pswp__button:focus, .pswp__button:hover {
  opacity: 1;
}

.pswp__button:active {
  opacity: .9;
  outline: none;
}

.pswp__button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.pswp__ui--over-close .pswp__button--close {
  opacity: 1;
}

.pswp__button, .pswp__button--arrow--left:before, .pswp__button--arrow--right:before {
  width: 44px;
  height: 44px;
  background: url("default-skin.1a8c5431.png") 0 0 / 264px 88px no-repeat;
}

@media (-webkit-min-device-pixel-ratio: 1.1), (-webkit-min-device-pixel-ratio: 1.09375), (min-resolution: 105dpi), (min-resolution: 1.1dppx) {
  .pswp--svg .pswp__button, .pswp--svg .pswp__button--arrow--left:before, .pswp--svg .pswp__button--arrow--right:before {
    background-image: url("default-skin.dca027d6.svg");
  }

  .pswp--svg .pswp__button--arrow--left, .pswp--svg .pswp__button--arrow--right {
    background: none;
  }
}

.pswp__button--close {
  background-position: 0 -44px;
}

.pswp__button--share {
  background-position: -44px -44px;
}

.pswp__button--fs {
  display: none;
}

.pswp--supports-fs .pswp__button--fs {
  display: block;
}

.pswp--fs .pswp__button--fs {
  background-position: -44px 0;
}

.pswp__button--zoom {
  background-position: -88px 0;
  display: none;
}

.pswp--zoom-allowed .pswp__button--zoom {
  display: block;
}

.pswp--zoomed-in .pswp__button--zoom {
  background-position: -132px 0;
}

.pswp--touch .pswp__button--arrow--left, .pswp--touch .pswp__button--arrow--right {
  visibility: hidden;
}

.pswp__button--arrow--left, .pswp__button--arrow--right {
  width: 70px;
  height: 100px;
  background: none;
  margin-top: -50px;
  position: absolute;
  top: 50%;
}

.pswp__button--arrow--left {
  left: 0;
}

.pswp__button--arrow--right {
  right: 0;
}

.pswp__button--arrow--left:before, .pswp__button--arrow--right:before {
  content: "";
  height: 30px;
  width: 32px;
  background-color: rgba(0, 0, 0, .3);
  position: absolute;
  top: 35px;
}

.pswp__button--arrow--left:before {
  background-position: -138px -44px;
  left: 6px;
}

.pswp__button--arrow--right:before {
  background-position: -94px -44px;
  right: 6px;
}

.pswp__counter, .pswp__share-modal {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pswp__share-modal {
  width: 100%;
  height: 100%;
  z-index: 1600;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  will-change: opacity;
  background: rgba(0, 0, 0, .5);
  padding: 10px;
  transition: opacity .25s ease-out;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.pswp__share-modal--hidden {
  display: none;
}

.pswp__share-tooltip {
  z-index: 1620;
  width: auto;
  -webkit-transition: -webkit-transform .25s;
  -webkit-backface-visibility: hidden;
  will-change: transform;
  background: #fff;
  border-radius: 2px;
  transition: transform .25s;
  display: block;
  position: absolute;
  top: 56px;
  right: 44px;
  transform: translateY(6px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, .25);
}

.pswp__share-tooltip a {
  color: #000;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  display: block;
}

.pswp__share-tooltip a:hover {
  color: #000;
  text-decoration: none;
}

.pswp__share-tooltip a:first-child {
  border-radius: 2px 2px 0 0;
}

.pswp__share-tooltip a:last-child {
  border-radius: 0 0 2px 2px;
}

.pswp__share-modal--fade-in {
  opacity: 1;
}

.pswp__share-modal--fade-in .pswp__share-tooltip {
  transform: translateY(0);
}

.pswp--touch .pswp__share-tooltip a {
  padding: 16px 12px;
}

a.pswp__share--facebook:before {
  content: "";
  width: 0;
  height: 0;
  -webkit-pointer-events: none;
  -moz-pointer-events: none;
  pointer-events: none;
  border: 6px solid rgba(0, 0, 0, 0);
  border-bottom-color: #fff;
  display: block;
  position: absolute;
  top: -12px;
  right: 15px;
}

a.pswp__share--facebook:hover {
  color: #fff;
  background: #3e5c9a;
}

a.pswp__share--facebook:hover:before {
  border-bottom-color: #3e5c9a;
}

a.pswp__share--twitter:hover {
  color: #fff;
  background: #55acee;
}

a.pswp__share--pinterest:hover {
  color: #ce272d;
  background: #ccc;
}

a.pswp__share--download:hover {
  background: #ddd;
}

.pswp__counter {
  height: 44px;
  color: #fff;
  opacity: .75;
  padding: 0 10px;
  font-size: 13px;
  line-height: 44px;
  position: absolute;
  top: 0;
  left: 0;
}

.pswp__caption {
  width: 100%;
  min-height: 44px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.pswp__caption small {
  color: #bbb;
  font-size: 11px;
}

.pswp__caption__center {
  text-align: left;
  max-width: 420px;
  color: #ccc;
  margin: 0 auto;
  padding: 10px;
  font-size: 13px;
  line-height: 20px;
}

.pswp__caption--empty {
  display: none;
}

.pswp__caption--fake {
  visibility: hidden;
}

.pswp__preloader {
  width: 44px;
  height: 44px;
  opacity: 0;
  will-change: opacity;
  direction: ltr;
  margin-left: -22px;
  transition: opacity .25s ease-out;
  position: absolute;
  top: 0;
  left: 50%;
}

.pswp__preloader__icn {
  width: 20px;
  height: 20px;
  margin: 12px;
}

.pswp__preloader--active {
  opacity: 1;
}

.pswp__preloader--active .pswp__preloader__icn {
  background: url("preloader.9ad95bd8.gif") no-repeat;
}

.pswp--css_animation .pswp__preloader--active {
  opacity: 1;
}

.pswp--css_animation .pswp__preloader--active .pswp__preloader__icn {
  animation: .5s linear infinite clockwise;
}

.pswp--css_animation .pswp__preloader--active .pswp__preloader__donut {
  animation: 1s cubic-bezier(.4, 0, .22, 1) infinite donut-rotate;
}

.pswp--css_animation .pswp__preloader__icn {
  opacity: .75;
  width: 14px;
  height: 14px;
  background: none;
  margin: 0;
  position: absolute;
  top: 15px;
  left: 15px;
}

.pswp--css_animation .pswp__preloader__cut {
  width: 7px;
  height: 14px;
  position: relative;
  overflow: hidden;
}

.pswp--css_animation .pswp__preloader__donut {
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  background: none;
  border: 2px solid #fff;
  border-color: #fff #fff rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  border-radius: 50%;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 1024px) {
  .pswp__preloader {
    float: right;
    margin: 0;
    position: relative;
    top: auto;
    left: auto;
  }
}

@keyframes clockwise {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes donut-rotate {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(-140deg);
  }

  100% {
    transform: rotate(0);
  }
}

.pswp__ui {
  -webkit-font-smoothing: auto;
  visibility: visible;
  opacity: 1;
  z-index: 1550;
}

.pswp__top-bar {
  height: 44px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.pswp__caption, .pswp__top-bar, .pswp--has_mouse .pswp__button--arrow--left, .pswp--has_mouse .pswp__button--arrow--right {
  -webkit-backface-visibility: hidden;
  will-change: opacity;
  transition: opacity .333s cubic-bezier(.4, 0, .22, 1);
}

.pswp--has_mouse .pswp__button--arrow--left, .pswp--has_mouse .pswp__button--arrow--right {
  visibility: visible;
}

.pswp__top-bar, .pswp__caption {
  background-color: rgba(0, 0, 0, .5);
}

.pswp__ui--fit .pswp__top-bar, .pswp__ui--fit .pswp__caption {
  background-color: rgba(0, 0, 0, .3);
}

.pswp__ui--idle .pswp__top-bar, .pswp__ui--idle .pswp__button--arrow--left, .pswp__ui--idle .pswp__button--arrow--right {
  opacity: 0;
}

.pswp__ui--hidden .pswp__top-bar, .pswp__ui--hidden .pswp__caption, .pswp__ui--hidden .pswp__button--arrow--left, .pswp__ui--hidden .pswp__button--arrow--right {
  opacity: .001;
}

.pswp__ui--one-slide .pswp__button--arrow--left, .pswp__ui--one-slide .pswp__button--arrow--right, .pswp__ui--one-slide .pswp__counter {
  display: none;
}

.pswp__element--disabled {
  display: none !important;
}

.pswp--minimal--dark .pswp__top-bar {
  background: none;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: sans-serif;
}

body {
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  vertical-align: baseline;
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

[hidden], template {
  display: none;
}

a {
  background-color: rgba(0, 0, 0, 0);
}

a:active, a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

mark {
  color: #000;
  background: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code, kbd, pre, samp {
  font-family: monospace;
  font-size: 1em;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

@media print {
  *, :before, :after {
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    background: none !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]:after, a[href^="javascript:"]:after {
    content: "";
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #999;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }

  .navbar {
    display: none;
  }

  .btn > .caret, .dropup > .btn > .caret {
    border-top-color: #000 !important;
  }

  .label {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td, .table th {
    background-color: #fff !important;
  }

  .table-bordered th, .table-bordered td {
    border: 1px solid #ddd !important;
  }
}

@font-face {
  font-family: Glyphicons Halflings;
  src: url("glyphicons-halflings-regular.cca02454.eot");
  src: url("glyphicons-halflings-regular.cca02454.eot#iefix") format("embedded-opentype"), url("glyphicons-halflings-regular.a3cea8f3.woff2") format("woff2"), url("glyphicons-halflings-regular.3a458e4a.woff") format("woff"), url("glyphicons-halflings-regular.1a33c1eb.ttf") format("truetype"), url("glyphicons-halflings-regular.6e7c7fa3.svg#glyphicons_halflingsregular") format("svg");
}

.glyphicon {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Glyphicons Halflings;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
  position: relative;
  top: 1px;
}

.glyphicon-asterisk:before {
  content: "*";
}

.glyphicon-plus:before {
  content: "+";
}

.glyphicon-euro:before, .glyphicon-eur:before {
  content: "€";
}

.glyphicon-minus:before {
  content: "−";
}

.glyphicon-cloud:before {
  content: "☁";
}

.glyphicon-envelope:before {
  content: "✉";
}

.glyphicon-pencil:before {
  content: "✏";
}

.glyphicon-glass:before {
  content: "";
}

.glyphicon-music:before {
  content: "";
}

.glyphicon-search:before {
  content: "";
}

.glyphicon-heart:before {
  content: "";
}

.glyphicon-star:before {
  content: "";
}

.glyphicon-star-empty:before {
  content: "";
}

.glyphicon-user:before {
  content: "";
}

.glyphicon-film:before {
  content: "";
}

.glyphicon-th-large:before {
  content: "";
}

.glyphicon-th:before {
  content: "";
}

.glyphicon-th-list:before {
  content: "";
}

.glyphicon-ok:before {
  content: "";
}

.glyphicon-remove:before {
  content: "";
}

.glyphicon-zoom-in:before {
  content: "";
}

.glyphicon-zoom-out:before {
  content: "";
}

.glyphicon-off:before {
  content: "";
}

.glyphicon-signal:before {
  content: "";
}

.glyphicon-cog:before {
  content: "";
}

.glyphicon-trash:before {
  content: "";
}

.glyphicon-home:before {
  content: "";
}

.glyphicon-file:before {
  content: "";
}

.glyphicon-time:before {
  content: "";
}

.glyphicon-road:before {
  content: "";
}

.glyphicon-download-alt:before {
  content: "";
}

.glyphicon-download:before {
  content: "";
}

.glyphicon-upload:before {
  content: "";
}

.glyphicon-inbox:before {
  content: "";
}

.glyphicon-play-circle:before {
  content: "";
}

.glyphicon-repeat:before {
  content: "";
}

.glyphicon-refresh:before {
  content: "";
}

.glyphicon-list-alt:before {
  content: "";
}

.glyphicon-lock:before {
  content: "";
}

.glyphicon-flag:before {
  content: "";
}

.glyphicon-headphones:before {
  content: "";
}

.glyphicon-volume-off:before {
  content: "";
}

.glyphicon-volume-down:before {
  content: "";
}

.glyphicon-volume-up:before {
  content: "";
}

.glyphicon-qrcode:before {
  content: "";
}

.glyphicon-barcode:before {
  content: "";
}

.glyphicon-tag:before {
  content: "";
}

.glyphicon-tags:before {
  content: "";
}

.glyphicon-book:before {
  content: "";
}

.glyphicon-bookmark:before {
  content: "";
}

.glyphicon-print:before {
  content: "";
}

.glyphicon-camera:before {
  content: "";
}

.glyphicon-font:before {
  content: "";
}

.glyphicon-bold:before {
  content: "";
}

.glyphicon-italic:before {
  content: "";
}

.glyphicon-text-height:before {
  content: "";
}

.glyphicon-text-width:before {
  content: "";
}

.glyphicon-align-left:before {
  content: "";
}

.glyphicon-align-center:before {
  content: "";
}

.glyphicon-align-right:before {
  content: "";
}

.glyphicon-align-justify:before {
  content: "";
}

.glyphicon-list:before {
  content: "";
}

.glyphicon-indent-left:before {
  content: "";
}

.glyphicon-indent-right:before {
  content: "";
}

.glyphicon-facetime-video:before {
  content: "";
}

.glyphicon-picture:before {
  content: "";
}

.glyphicon-map-marker:before {
  content: "";
}

.glyphicon-adjust:before {
  content: "";
}

.glyphicon-tint:before {
  content: "";
}

.glyphicon-edit:before {
  content: "";
}

.glyphicon-share:before {
  content: "";
}

.glyphicon-check:before {
  content: "";
}

.glyphicon-move:before {
  content: "";
}

.glyphicon-step-backward:before {
  content: "";
}

.glyphicon-fast-backward:before {
  content: "";
}

.glyphicon-backward:before {
  content: "";
}

.glyphicon-play:before {
  content: "";
}

.glyphicon-pause:before {
  content: "";
}

.glyphicon-stop:before {
  content: "";
}

.glyphicon-forward:before {
  content: "";
}

.glyphicon-fast-forward:before {
  content: "";
}

.glyphicon-step-forward:before {
  content: "";
}

.glyphicon-eject:before {
  content: "";
}

.glyphicon-chevron-left:before {
  content: "";
}

.glyphicon-chevron-right:before {
  content: "";
}

.glyphicon-plus-sign:before {
  content: "";
}

.glyphicon-minus-sign:before {
  content: "";
}

.glyphicon-remove-sign:before {
  content: "";
}

.glyphicon-ok-sign:before {
  content: "";
}

.glyphicon-question-sign:before {
  content: "";
}

.glyphicon-info-sign:before {
  content: "";
}

.glyphicon-screenshot:before {
  content: "";
}

.glyphicon-remove-circle:before {
  content: "";
}

.glyphicon-ok-circle:before {
  content: "";
}

.glyphicon-ban-circle:before {
  content: "";
}

.glyphicon-arrow-left:before {
  content: "";
}

.glyphicon-arrow-right:before {
  content: "";
}

.glyphicon-arrow-up:before {
  content: "";
}

.glyphicon-arrow-down:before {
  content: "";
}

.glyphicon-share-alt:before {
  content: "";
}

.glyphicon-resize-full:before {
  content: "";
}

.glyphicon-resize-small:before {
  content: "";
}

.glyphicon-exclamation-sign:before {
  content: "";
}

.glyphicon-gift:before {
  content: "";
}

.glyphicon-leaf:before {
  content: "";
}

.glyphicon-fire:before {
  content: "";
}

.glyphicon-eye-open:before {
  content: "";
}

.glyphicon-eye-close:before {
  content: "";
}

.glyphicon-warning-sign:before {
  content: "";
}

.glyphicon-plane:before {
  content: "";
}

.glyphicon-calendar:before {
  content: "";
}

.glyphicon-random:before {
  content: "";
}

.glyphicon-comment:before {
  content: "";
}

.glyphicon-magnet:before {
  content: "";
}

.glyphicon-chevron-up:before {
  content: "";
}

.glyphicon-chevron-down:before {
  content: "";
}

.glyphicon-retweet:before {
  content: "";
}

.glyphicon-shopping-cart:before {
  content: "";
}

.glyphicon-folder-close:before {
  content: "";
}

.glyphicon-folder-open:before {
  content: "";
}

.glyphicon-resize-vertical:before {
  content: "";
}

.glyphicon-resize-horizontal:before {
  content: "";
}

.glyphicon-hdd:before {
  content: "";
}

.glyphicon-bullhorn:before {
  content: "";
}

.glyphicon-bell:before {
  content: "";
}

.glyphicon-certificate:before {
  content: "";
}

.glyphicon-thumbs-up:before {
  content: "";
}

.glyphicon-thumbs-down:before {
  content: "";
}

.glyphicon-hand-right:before {
  content: "";
}

.glyphicon-hand-left:before {
  content: "";
}

.glyphicon-hand-up:before {
  content: "";
}

.glyphicon-hand-down:before {
  content: "";
}

.glyphicon-circle-arrow-right:before {
  content: "";
}

.glyphicon-circle-arrow-left:before {
  content: "";
}

.glyphicon-circle-arrow-up:before {
  content: "";
}

.glyphicon-circle-arrow-down:before {
  content: "";
}

.glyphicon-globe:before {
  content: "";
}

.glyphicon-wrench:before {
  content: "";
}

.glyphicon-tasks:before {
  content: "";
}

.glyphicon-filter:before {
  content: "";
}

.glyphicon-briefcase:before {
  content: "";
}

.glyphicon-fullscreen:before, .content-gallery a.gallery-enlarge:before {
  content: "";
}

.glyphicon-dashboard:before {
  content: "";
}

.glyphicon-paperclip:before {
  content: "";
}

.glyphicon-heart-empty:before {
  content: "";
}

.glyphicon-link:before {
  content: "";
}

.glyphicon-phone:before {
  content: "";
}

.glyphicon-pushpin:before {
  content: "";
}

.glyphicon-usd:before {
  content: "";
}

.glyphicon-gbp:before {
  content: "";
}

.glyphicon-sort:before {
  content: "";
}

.glyphicon-sort-by-alphabet:before {
  content: "";
}

.glyphicon-sort-by-alphabet-alt:before {
  content: "";
}

.glyphicon-sort-by-order:before {
  content: "";
}

.glyphicon-sort-by-order-alt:before {
  content: "";
}

.glyphicon-sort-by-attributes:before {
  content: "";
}

.glyphicon-sort-by-attributes-alt:before {
  content: "";
}

.glyphicon-unchecked:before {
  content: "";
}

.glyphicon-expand:before {
  content: "";
}

.glyphicon-collapse-down:before {
  content: "";
}

.glyphicon-collapse-up:before {
  content: "";
}

.glyphicon-log-in:before {
  content: "";
}

.glyphicon-flash:before {
  content: "";
}

.glyphicon-log-out:before {
  content: "";
}

.glyphicon-new-window:before {
  content: "";
}

.glyphicon-record:before {
  content: "";
}

.glyphicon-save:before {
  content: "";
}

.glyphicon-open:before {
  content: "";
}

.glyphicon-saved:before {
  content: "";
}

.glyphicon-import:before {
  content: "";
}

.glyphicon-export:before {
  content: "";
}

.glyphicon-send:before {
  content: "";
}

.glyphicon-floppy-disk:before {
  content: "";
}

.glyphicon-floppy-saved:before {
  content: "";
}

.glyphicon-floppy-remove:before {
  content: "";
}

.glyphicon-floppy-save:before {
  content: "";
}

.glyphicon-floppy-open:before {
  content: "";
}

.glyphicon-credit-card:before {
  content: "";
}

.glyphicon-transfer:before {
  content: "";
}

.glyphicon-cutlery:before {
  content: "";
}

.glyphicon-header:before {
  content: "";
}

.glyphicon-compressed:before {
  content: "";
}

.glyphicon-earphone:before {
  content: "";
}

.glyphicon-phone-alt:before {
  content: "";
}

.glyphicon-tower:before {
  content: "";
}

.glyphicon-stats:before {
  content: "";
}

.glyphicon-sd-video:before {
  content: "";
}

.glyphicon-hd-video:before {
  content: "";
}

.glyphicon-subtitles:before {
  content: "";
}

.glyphicon-sound-stereo:before {
  content: "";
}

.glyphicon-sound-dolby:before {
  content: "";
}

.glyphicon-sound-5-1:before {
  content: "";
}

.glyphicon-sound-6-1:before {
  content: "";
}

.glyphicon-sound-7-1:before {
  content: "";
}

.glyphicon-copyright-mark:before {
  content: "";
}

.glyphicon-registration-mark:before {
  content: "";
}

.glyphicon-cloud-download:before {
  content: "";
}

.glyphicon-cloud-upload:before {
  content: "";
}

.glyphicon-tree-conifer:before {
  content: "";
}

.glyphicon-tree-deciduous:before {
  content: "";
}

.glyphicon-cd:before {
  content: "";
}

.glyphicon-save-file:before {
  content: "";
}

.glyphicon-open-file:before {
  content: "";
}

.glyphicon-level-up:before {
  content: "";
}

.glyphicon-copy:before {
  content: "";
}

.glyphicon-paste:before {
  content: "";
}

.glyphicon-alert:before {
  content: "";
}

.glyphicon-equalizer:before {
  content: "";
}

.glyphicon-king:before {
  content: "";
}

.glyphicon-queen:before {
  content: "";
}

.glyphicon-pawn:before {
  content: "";
}

.glyphicon-bishop:before {
  content: "";
}

.glyphicon-knight:before {
  content: "";
}

.glyphicon-baby-formula:before {
  content: "";
}

.glyphicon-tent:before {
  content: "⛺";
}

.glyphicon-blackboard:before {
  content: "";
}

.glyphicon-bed:before {
  content: "";
}

.glyphicon-apple:before {
  content: "";
}

.glyphicon-erase:before {
  content: "";
}

.glyphicon-hourglass:before {
  content: "⌛";
}

.glyphicon-lamp:before {
  content: "";
}

.glyphicon-duplicate:before {
  content: "";
}

.glyphicon-piggy-bank:before {
  content: "";
}

.glyphicon-scissors:before {
  content: "";
}

.glyphicon-bitcoin:before, .glyphicon-btc:before, .glyphicon-xbt:before {
  content: "";
}

.glyphicon-yen:before, .glyphicon-jpy:before {
  content: "¥";
}

.glyphicon-ruble:before, .glyphicon-rub:before {
  content: "₽";
}

.glyphicon-scale:before {
  content: "";
}

.glyphicon-ice-lolly:before {
  content: "";
}

.glyphicon-ice-lolly-tasted:before {
  content: "";
}

.glyphicon-education:before {
  content: "";
}

.glyphicon-option-horizontal:before {
  content: "";
}

.glyphicon-option-vertical:before {
  content: "";
}

.glyphicon-menu-hamburger:before {
  content: "";
}

.glyphicon-modal-window:before {
  content: "";
}

.glyphicon-oil:before {
  content: "";
}

.glyphicon-grain:before {
  content: "";
}

.glyphicon-sunglasses:before {
  content: "";
}

.glyphicon-text-size:before {
  content: "";
}

.glyphicon-text-color:before {
  content: "";
}

.glyphicon-text-background:before {
  content: "";
}

.glyphicon-object-align-top:before {
  content: "";
}

.glyphicon-object-align-bottom:before {
  content: "";
}

.glyphicon-object-align-horizontal:before {
  content: "";
}

.glyphicon-object-align-left:before {
  content: "";
}

.glyphicon-object-align-vertical:before {
  content: "";
}

.glyphicon-object-align-right:before {
  content: "";
}

.glyphicon-triangle-right:before {
  content: "";
}

.glyphicon-triangle-left:before {
  content: "";
}

.glyphicon-triangle-bottom:before {
  content: "";
}

.glyphicon-triangle-top:before {
  content: "";
}

.glyphicon-console:before {
  content: "";
}

.glyphicon-superscript:before {
  content: "";
}

.glyphicon-subscript:before {
  content: "";
}

.glyphicon-menu-left:before {
  content: "";
}

.glyphicon-menu-right:before {
  content: "";
}

.glyphicon-menu-down:before {
  content: "";
}

.glyphicon-menu-up:before {
  content: "";
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 10px;
}

body {
  color: #1d1d1b;
  background-color: #fff;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857;
}

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: #b4151d;
  text-decoration: none;
}

a:hover, a:focus {
  color: #6f0d12;
  text-decoration: underline;
}

a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

figure {
  margin: 0;
}

img {
  vertical-align: middle;
}

.img-responsive {
  max-width: 100%;
  height: auto;
  display: block;
}

.img-rounded {
  border-radius: 0;
}

.img-thumbnail {
  -o-transition: all .2s ease-in-out;
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0;
  padding: 4px;
  line-height: 1.42857;
  transition: all .2s ease-in-out;
  display: inline-block;
}

.img-circle {
  border-radius: 50%;
}

hr {
  border: 0;
  border-top: 1px solid #a4a4a4;
  margin-top: 20px;
  margin-bottom: 20px;
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  width: auto;
  height: auto;
  clip: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

[role="button"] {
  cursor: pointer;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: #898989;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.1;
}

h1 small, h1 .small, h2 small, h2 .small, h3 small, h3 .small, h4 small, h4 .small, h5 small, h5 .small, h6 small, h6 .small, .h1 small, .h1 .small, .h2 small, .h2 .small, .h3 small, .h3 .small, .h4 small, .h4 .small, .h5 small, .h5 .small, .h6 small, .h6 .small {
  color: #898989;
  font-weight: normal;
  line-height: 1;
}

h1, .h1, h2, .h2, h3, .h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h1 small, h1 .small, .h1 small, .h1 .small, h2 small, h2 .small, .h2 small, .h2 .small, h3 small, h3 .small, .h3 small, .h3 .small {
  font-size: 65%;
}

h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

h4 small, h4 .small, .h4 small, .h4 .small, h5 small, h5 .small, .h5 small, .h5 .small, h6 small, h6 .small, .h6 small, .h6 .small {
  font-size: 75%;
}

h1, .h1 {
  font-size: 44px;
}

h2, .h2 {
  font-size: 37px;
}

h3, .h3 {
  font-size: 30px;
}

h4, .h4 {
  font-size: 26px;
}

h5, .h5 {
  font-size: 21px;
}

h6, .h6 {
  font-size: 16px;
}

p {
  margin: 0 0 10px;
}

.lead {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4;
}

@media (min-width: 768px) {
  .lead {
    font-size: 21px;
  }
}

small, .small {
  font-size: 85%;
}

mark, .mark {
  background-color: #fcf8e3;
  padding: .2em;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase, .initialism {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-muted {
  color: #898989;
}

.text-primary {
  color: #b4151d;
}

a.text-primary:hover, a.text-primary:focus {
  color: #861016;
}

.text-success {
  color: #3c763d;
}

a.text-success:hover, a.text-success:focus {
  color: #2b542c;
}

.text-info {
  color: #31708f;
}

a.text-info:hover, a.text-info:focus {
  color: #245269;
}

.text-warning {
  color: #8a6d3b;
}

a.text-warning:hover, a.text-warning:focus {
  color: #66512c;
}

.text-danger {
  color: #a94442;
}

a.text-danger:hover, a.text-danger:focus {
  color: #843534;
}

.bg-primary {
  color: #fff;
  background-color: #b4151d;
}

a.bg-primary:hover, a.bg-primary:focus {
  background-color: #861016;
}

.bg-success {
  background-color: #dff0d8;
}

a.bg-success:hover, a.bg-success:focus {
  background-color: #c1e2b3;
}

.bg-info {
  background-color: #d9edf7;
}

a.bg-info:hover, a.bg-info:focus {
  background-color: #afd9ee;
}

.bg-warning {
  background-color: #fcf8e3;
}

a.bg-warning:hover, a.bg-warning:focus {
  background-color: #f7ecb5;
}

.bg-danger {
  background-color: #f2dede;
}

a.bg-danger:hover, a.bg-danger:focus {
  background-color: #e4b9b9;
}

.page-header {
  border-bottom: 1px solid #a4a4a4;
  margin: 40px 0 20px;
  padding-bottom: 9px;
}

ul, ol {
  margin-top: 0;
  margin-bottom: 10px;
}

ul ul, ul ol, ol ul, ol ol {
  margin-bottom: 0;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  margin-left: -5px;
  padding-left: 0;
  list-style: none;
}

.list-inline > li {
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
}

dl {
  margin-top: 0;
  margin-bottom: 20px;
}

dt, dd {
  line-height: 1.42857;
}

dt {
  font-weight: bold;
}

dd {
  margin-left: 0;
}

.dl-horizontal dd:before, .dl-horizontal dd:after {
  content: " ";
  display: table;
}

.dl-horizontal dd:after {
  clear: both;
}

@media (min-width: 992px) {
  .dl-horizontal dt {
    float: left;
    width: 160px;
    clear: left;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .dl-horizontal dd {
    margin-left: 180px;
  }
}

abbr[title], abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted #898989;
}

.initialism {
  font-size: 90%;
}

blockquote {
  border-left: 5px solid #a4a4a4;
  margin: 0 0 20px;
  padding: 10px 20px;
  font-size: 17.5px;
}

blockquote p:last-child, blockquote ul:last-child, blockquote ol:last-child {
  margin-bottom: 0;
}

blockquote footer, blockquote small, blockquote .small {
  color: #898989;
  font-size: 80%;
  line-height: 1.42857;
  display: block;
}

blockquote footer:before, blockquote small:before, blockquote .small:before {
  content: "— ";
}

.blockquote-reverse, blockquote.pull-right {
  text-align: right;
  border-left: 0;
  border-right: 5px solid #a4a4a4;
  padding-left: 0;
  padding-right: 15px;
}

.blockquote-reverse footer:before, .blockquote-reverse small:before, .blockquote-reverse .small:before, blockquote.pull-right footer:before, blockquote.pull-right small:before, blockquote.pull-right .small:before {
  content: "";
}

.blockquote-reverse footer:after, .blockquote-reverse small:after, .blockquote-reverse .small:after, blockquote.pull-right footer:after, blockquote.pull-right small:after, blockquote.pull-right .small:after {
  content: " —";
}

address {
  margin-bottom: 20px;
  font-style: normal;
  line-height: 1.42857;
}

code, kbd, pre, samp {
  font-family: Menlo, Monaco, Consolas, Courier New, monospace;
}

code {
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 0;
  padding: 2px 4px;
  font-size: 90%;
}

kbd {
  color: #fff;
  background-color: #333;
  border-radius: 0;
  padding: 2px 4px;
  font-size: 90%;
  box-shadow: inset 0 -1px rgba(0, 0, 0, .25);
}

kbd kbd {
  box-shadow: none;
  padding: 0;
  font-size: 100%;
  font-weight: bold;
}

pre {
  word-break: break-all;
  word-wrap: break-word;
  color: #1d1d1b;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 0;
  margin: 0 0 10px;
  padding: 9.5px;
  font-size: 13px;
  line-height: 1.42857;
  display: block;
}

pre code {
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0;
  padding: 0;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 25px;
  padding-right: 25px;
}

.container:before, .container:after {
  content: " ";
  display: table;
}

.container:after {
  clear: both;
}

@media (min-width: 768px) {
  .container {
    width: 770px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 990px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1190px;
  }
}

.container-fluid {
  margin-left: auto;
  margin-right: auto;
  padding-left: 25px;
  padding-right: 25px;
}

.container-fluid:before, .container-fluid:after {
  content: " ";
  display: table;
}

.container-fluid:after {
  clear: both;
}

.row {
  margin-left: -25px;
  margin-right: -25px;
}

.row:before, .row:after {
  content: " ";
  display: table;
}

.row:after {
  clear: both;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  min-height: 1px;
  padding-left: 25px;
  padding-right: 25px;
  position: relative;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}

.col-xs-1 {
  width: 8.33333%;
}

.col-xs-2 {
  width: 16.6667%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-4 {
  width: 33.3333%;
}

.col-xs-5 {
  width: 41.6667%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-7 {
  width: 58.3333%;
}

.col-xs-8 {
  width: 66.6667%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-10 {
  width: 83.3333%;
}

.col-xs-11 {
  width: 91.6667%;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-pull-1 {
  right: 8.33333%;
}

.col-xs-pull-2 {
  right: 16.6667%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-4 {
  right: 33.3333%;
}

.col-xs-pull-5 {
  right: 41.6667%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-7 {
  right: 58.3333%;
}

.col-xs-pull-8 {
  right: 66.6667%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-10 {
  right: 83.3333%;
}

.col-xs-pull-11 {
  right: 91.6667%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-push-1 {
  left: 8.33333%;
}

.col-xs-push-2 {
  left: 16.6667%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-4 {
  left: 33.3333%;
}

.col-xs-push-5 {
  left: 41.6667%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-7 {
  left: 58.3333%;
}

.col-xs-push-8 {
  left: 66.6667%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-10 {
  left: 83.3333%;
}

.col-xs-push-11 {
  left: 91.6667%;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-offset-0 {
  margin-left: 0%;
}

.col-xs-offset-1 {
  margin-left: 8.33333%;
}

.col-xs-offset-2 {
  margin-left: 16.6667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.3333%;
}

.col-xs-offset-5 {
  margin-left: 41.6667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.3333%;
}

.col-xs-offset-8 {
  margin-left: 66.6667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.3333%;
}

.col-xs-offset-11 {
  margin-left: 91.6667%;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }

  .col-sm-1 {
    width: 8.33333%;
  }

  .col-sm-2 {
    width: 16.6667%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-4 {
    width: 33.3333%;
  }

  .col-sm-5 {
    width: 41.6667%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-7 {
    width: 58.3333%;
  }

  .col-sm-8 {
    width: 66.6667%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-10 {
    width: 83.3333%;
  }

  .col-sm-11 {
    width: 91.6667%;
  }

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-pull-0 {
    right: auto;
  }

  .col-sm-pull-1 {
    right: 8.33333%;
  }

  .col-sm-pull-2 {
    right: 16.6667%;
  }

  .col-sm-pull-3 {
    right: 25%;
  }

  .col-sm-pull-4 {
    right: 33.3333%;
  }

  .col-sm-pull-5 {
    right: 41.6667%;
  }

  .col-sm-pull-6 {
    right: 50%;
  }

  .col-sm-pull-7 {
    right: 58.3333%;
  }

  .col-sm-pull-8 {
    right: 66.6667%;
  }

  .col-sm-pull-9 {
    right: 75%;
  }

  .col-sm-pull-10 {
    right: 83.3333%;
  }

  .col-sm-pull-11 {
    right: 91.6667%;
  }

  .col-sm-pull-12 {
    right: 100%;
  }

  .col-sm-push-0 {
    left: auto;
  }

  .col-sm-push-1 {
    left: 8.33333%;
  }

  .col-sm-push-2 {
    left: 16.6667%;
  }

  .col-sm-push-3 {
    left: 25%;
  }

  .col-sm-push-4 {
    left: 33.3333%;
  }

  .col-sm-push-5 {
    left: 41.6667%;
  }

  .col-sm-push-6 {
    left: 50%;
  }

  .col-sm-push-7 {
    left: 58.3333%;
  }

  .col-sm-push-8 {
    left: 66.6667%;
  }

  .col-sm-push-9 {
    left: 75%;
  }

  .col-sm-push-10 {
    left: 83.3333%;
  }

  .col-sm-push-11 {
    left: 91.6667%;
  }

  .col-sm-push-12 {
    left: 100%;
  }

  .col-sm-offset-0 {
    margin-left: 0%;
  }

  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }

  .col-sm-offset-2 {
    margin-left: 16.6667%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-4 {
    margin-left: 33.3333%;
  }

  .col-sm-offset-5 {
    margin-left: 41.6667%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-7 {
    margin-left: 58.3333%;
  }

  .col-sm-offset-8 {
    margin-left: 66.6667%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-10 {
    margin-left: 83.3333%;
  }

  .col-sm-offset-11 {
    margin-left: 91.6667%;
  }

  .col-sm-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }

  .col-md-1 {
    width: 8.33333%;
  }

  .col-md-2 {
    width: 16.6667%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-4 {
    width: 33.3333%;
  }

  .col-md-5 {
    width: 41.6667%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-7 {
    width: 58.3333%;
  }

  .col-md-8 {
    width: 66.6667%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-10 {
    width: 83.3333%;
  }

  .col-md-11 {
    width: 91.6667%;
  }

  .col-md-12 {
    width: 100%;
  }

  .col-md-pull-0 {
    right: auto;
  }

  .col-md-pull-1 {
    right: 8.33333%;
  }

  .col-md-pull-2 {
    right: 16.6667%;
  }

  .col-md-pull-3 {
    right: 25%;
  }

  .col-md-pull-4 {
    right: 33.3333%;
  }

  .col-md-pull-5 {
    right: 41.6667%;
  }

  .col-md-pull-6 {
    right: 50%;
  }

  .col-md-pull-7 {
    right: 58.3333%;
  }

  .col-md-pull-8 {
    right: 66.6667%;
  }

  .col-md-pull-9 {
    right: 75%;
  }

  .col-md-pull-10 {
    right: 83.3333%;
  }

  .col-md-pull-11 {
    right: 91.6667%;
  }

  .col-md-pull-12 {
    right: 100%;
  }

  .col-md-push-0 {
    left: auto;
  }

  .col-md-push-1 {
    left: 8.33333%;
  }

  .col-md-push-2 {
    left: 16.6667%;
  }

  .col-md-push-3 {
    left: 25%;
  }

  .col-md-push-4 {
    left: 33.3333%;
  }

  .col-md-push-5 {
    left: 41.6667%;
  }

  .col-md-push-6 {
    left: 50%;
  }

  .col-md-push-7 {
    left: 58.3333%;
  }

  .col-md-push-8 {
    left: 66.6667%;
  }

  .col-md-push-9 {
    left: 75%;
  }

  .col-md-push-10 {
    left: 83.3333%;
  }

  .col-md-push-11 {
    left: 91.6667%;
  }

  .col-md-push-12 {
    left: 100%;
  }

  .col-md-offset-0 {
    margin-left: 0%;
  }

  .col-md-offset-1 {
    margin-left: 8.33333%;
  }

  .col-md-offset-2 {
    margin-left: 16.6667%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-4 {
    margin-left: 33.3333%;
  }

  .col-md-offset-5 {
    margin-left: 41.6667%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-7 {
    margin-left: 58.3333%;
  }

  .col-md-offset-8 {
    margin-left: 66.6667%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-10 {
    margin-left: 83.3333%;
  }

  .col-md-offset-11 {
    margin-left: 91.6667%;
  }

  .col-md-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }

  .col-lg-1 {
    width: 8.33333%;
  }

  .col-lg-2 {
    width: 16.6667%;
  }

  .col-lg-3 {
    width: 25%;
  }

  .col-lg-4 {
    width: 33.3333%;
  }

  .col-lg-5 {
    width: 41.6667%;
  }

  .col-lg-6 {
    width: 50%;
  }

  .col-lg-7 {
    width: 58.3333%;
  }

  .col-lg-8 {
    width: 66.6667%;
  }

  .col-lg-9 {
    width: 75%;
  }

  .col-lg-10 {
    width: 83.3333%;
  }

  .col-lg-11 {
    width: 91.6667%;
  }

  .col-lg-12 {
    width: 100%;
  }

  .col-lg-pull-0 {
    right: auto;
  }

  .col-lg-pull-1 {
    right: 8.33333%;
  }

  .col-lg-pull-2 {
    right: 16.6667%;
  }

  .col-lg-pull-3 {
    right: 25%;
  }

  .col-lg-pull-4 {
    right: 33.3333%;
  }

  .col-lg-pull-5 {
    right: 41.6667%;
  }

  .col-lg-pull-6 {
    right: 50%;
  }

  .col-lg-pull-7 {
    right: 58.3333%;
  }

  .col-lg-pull-8 {
    right: 66.6667%;
  }

  .col-lg-pull-9 {
    right: 75%;
  }

  .col-lg-pull-10 {
    right: 83.3333%;
  }

  .col-lg-pull-11 {
    right: 91.6667%;
  }

  .col-lg-pull-12 {
    right: 100%;
  }

  .col-lg-push-0 {
    left: auto;
  }

  .col-lg-push-1 {
    left: 8.33333%;
  }

  .col-lg-push-2 {
    left: 16.6667%;
  }

  .col-lg-push-3 {
    left: 25%;
  }

  .col-lg-push-4 {
    left: 33.3333%;
  }

  .col-lg-push-5 {
    left: 41.6667%;
  }

  .col-lg-push-6 {
    left: 50%;
  }

  .col-lg-push-7 {
    left: 58.3333%;
  }

  .col-lg-push-8 {
    left: 66.6667%;
  }

  .col-lg-push-9 {
    left: 75%;
  }

  .col-lg-push-10 {
    left: 83.3333%;
  }

  .col-lg-push-11 {
    left: 91.6667%;
  }

  .col-lg-push-12 {
    left: 100%;
  }

  .col-lg-offset-0 {
    margin-left: 0%;
  }

  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }

  .col-lg-offset-2 {
    margin-left: 16.6667%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-4 {
    margin-left: 33.3333%;
  }

  .col-lg-offset-5 {
    margin-left: 41.6667%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-7 {
    margin-left: 58.3333%;
  }

  .col-lg-offset-8 {
    margin-left: 66.6667%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-10 {
    margin-left: 83.3333%;
  }

  .col-lg-offset-11 {
    margin-left: 91.6667%;
  }

  .col-lg-offset-12 {
    margin-left: 100%;
  }
}

table {
  background-color: rgba(0, 0, 0, 0);
}

caption {
  color: #898989;
  text-align: left;
  padding-top: 8px;
  padding-bottom: 8px;
}

th {
  text-align: left;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.table > thead > tr > th, .table > thead > tr > td, .table > tbody > tr > th, .table > tbody > tr > td, .table > tfoot > tr > th, .table > tfoot > tr > td {
  vertical-align: top;
  border-top: 1px solid #ddd;
  padding: 8px;
  line-height: 1.42857;
}

.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}

.table > caption + thead > tr:first-child > th, .table > caption + thead > tr:first-child > td, .table > colgroup + thead > tr:first-child > th, .table > colgroup + thead > tr:first-child > td, .table > thead:first-child > tr:first-child > th, .table > thead:first-child > tr:first-child > td {
  border-top: 0;
}

.table > tbody + tbody {
  border-top: 2px solid #ddd;
}

.table .table {
  background-color: #fff;
}

.table-condensed > thead > tr > th, .table-condensed > thead > tr > td, .table-condensed > tbody > tr > th, .table-condensed > tbody > tr > td, .table-condensed > tfoot > tr > th, .table-condensed > tfoot > tr > td {
  padding: 5px;
}

.table-bordered, .table-bordered > thead > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > th, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > th, .table-bordered > tfoot > tr > td {
  border: 1px solid #ddd;
}

.table-bordered > thead > tr > th, .table-bordered > thead > tr > td {
  border-bottom-width: 2px;
}

.table-striped > tbody > tr:nth-of-type(2n+1) {
  background-color: #f9f9f9;
}

.table-hover > tbody > tr:hover {
  background-color: #f5f5f5;
}

table col[class*="col-"] {
  float: none;
  display: table-column;
  position: static;
}

table td[class*="col-"], table th[class*="col-"] {
  float: none;
  display: table-cell;
  position: static;
}

.table > thead > tr > td.active, .table > thead > tr > th.active, .table > thead > tr.active > td, .table > thead > tr.active > th, .table > tbody > tr > td.active, .table > tbody > tr > th.active, .table > tbody > tr.active > td, .table > tbody > tr.active > th, .table > tfoot > tr > td.active, .table > tfoot > tr > th.active, .table > tfoot > tr.active > td, .table > tfoot > tr.active > th {
  background-color: #f5f5f5;
}

.table-hover > tbody > tr > td.active:hover, .table-hover > tbody > tr > th.active:hover, .table-hover > tbody > tr.active:hover > td, .table-hover > tbody > tr:hover > .active, .table-hover > tbody > tr.active:hover > th {
  background-color: #e8e8e8;
}

.table > thead > tr > td.success, .table > thead > tr > th.success, .table > thead > tr.success > td, .table > thead > tr.success > th, .table > tbody > tr > td.success, .table > tbody > tr > th.success, .table > tbody > tr.success > td, .table > tbody > tr.success > th, .table > tfoot > tr > td.success, .table > tfoot > tr > th.success, .table > tfoot > tr.success > td, .table > tfoot > tr.success > th {
  background-color: #dff0d8;
}

.table-hover > tbody > tr > td.success:hover, .table-hover > tbody > tr > th.success:hover, .table-hover > tbody > tr.success:hover > td, .table-hover > tbody > tr:hover > .success, .table-hover > tbody > tr.success:hover > th {
  background-color: #d0e9c6;
}

.table > thead > tr > td.info, .table > thead > tr > th.info, .table > thead > tr.info > td, .table > thead > tr.info > th, .table > tbody > tr > td.info, .table > tbody > tr > th.info, .table > tbody > tr.info > td, .table > tbody > tr.info > th, .table > tfoot > tr > td.info, .table > tfoot > tr > th.info, .table > tfoot > tr.info > td, .table > tfoot > tr.info > th {
  background-color: #d9edf7;
}

.table-hover > tbody > tr > td.info:hover, .table-hover > tbody > tr > th.info:hover, .table-hover > tbody > tr.info:hover > td, .table-hover > tbody > tr:hover > .info, .table-hover > tbody > tr.info:hover > th {
  background-color: #c4e3f3;
}

.table > thead > tr > td.warning, .table > thead > tr > th.warning, .table > thead > tr.warning > td, .table > thead > tr.warning > th, .table > tbody > tr > td.warning, .table > tbody > tr > th.warning, .table > tbody > tr.warning > td, .table > tbody > tr.warning > th, .table > tfoot > tr > td.warning, .table > tfoot > tr > th.warning, .table > tfoot > tr.warning > td, .table > tfoot > tr.warning > th {
  background-color: #fcf8e3;
}

.table-hover > tbody > tr > td.warning:hover, .table-hover > tbody > tr > th.warning:hover, .table-hover > tbody > tr.warning:hover > td, .table-hover > tbody > tr:hover > .warning, .table-hover > tbody > tr.warning:hover > th {
  background-color: #faf2cc;
}

.table > thead > tr > td.danger, .table > thead > tr > th.danger, .table > thead > tr.danger > td, .table > thead > tr.danger > th, .table > tbody > tr > td.danger, .table > tbody > tr > th.danger, .table > tbody > tr.danger > td, .table > tbody > tr.danger > th, .table > tfoot > tr > td.danger, .table > tfoot > tr > th.danger, .table > tfoot > tr.danger > td, .table > tfoot > tr.danger > th {
  background-color: #f2dede;
}

.table-hover > tbody > tr > td.danger:hover, .table-hover > tbody > tr > th.danger:hover, .table-hover > tbody > tr.danger:hover > td, .table-hover > tbody > tr:hover > .danger, .table-hover > tbody > tr.danger:hover > th {
  background-color: #ebcccc;
}

.table-responsive {
  min-height: .01%;
  overflow-x: auto;
}

@media screen and (max-width: 767px) {
  .table-responsive {
    width: 100%;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
    margin-bottom: 15px;
    overflow-y: hidden;
  }

  .table-responsive > .table {
    margin-bottom: 0;
  }

  .table-responsive > .table > thead > tr > th, .table-responsive > .table > thead > tr > td, .table-responsive > .table > tbody > tr > th, .table-responsive > .table > tbody > tr > td, .table-responsive > .table > tfoot > tr > th, .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap;
  }

  .table-responsive > .table-bordered {
    border: 0;
  }

  .table-responsive > .table-bordered > thead > tr > th:first-child, .table-responsive > .table-bordered > thead > tr > td:first-child, .table-responsive > .table-bordered > tbody > tr > th:first-child, .table-responsive > .table-bordered > tbody > tr > td:first-child, .table-responsive > .table-bordered > tfoot > tr > th:first-child, .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }

  .table-responsive > .table-bordered > thead > tr > th:last-child, .table-responsive > .table-bordered > thead > tr > td:last-child, .table-responsive > .table-bordered > tbody > tr > th:last-child, .table-responsive > .table-bordered > tbody > tr > td:last-child, .table-responsive > .table-bordered > tfoot > tr > th:last-child, .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }

  .table-responsive > .table-bordered > tbody > tr:last-child > th, .table-responsive > .table-bordered > tbody > tr:last-child > td, .table-responsive > .table-bordered > tfoot > tr:last-child > th, .table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0;
  }
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  width: 100%;
  font-size: 21px;
  line-height: inherit;
  color: #1d1d1b;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 20px;
  padding: 0;
  display: block;
}

label {
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
  display: inline-block;
}

input[type="search"] {
  box-sizing: border-box;
}

input[type="radio"], input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9 ;
  line-height: normal;
}

input[type="file"] {
  display: block;
}

input[type="range"] {
  width: 100%;
  display: block;
}

select[multiple], select[size] {
  height: auto;
}

input[type="file"]:focus, input[type="radio"]:focus, input[type="checkbox"]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

output {
  color: #444;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.42857;
  display: block;
}

.form-control {
  width: 100%;
  height: 34px;
  color: #444;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  background-color: #fff;
  background-image: none;
  border: 1px solid #898989;
  border-radius: 0;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.form-control:focus {
  border-color: #f18a8f;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(241, 138, 143, .6);
}

.form-control::-moz-placeholder {
  color: #a4a4a4;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #a4a4a4;
}

.form-control::-webkit-input-placeholder {
  color: #a4a4a4;
}

.form-control::-ms-expand {
  background-color: rgba(0, 0, 0, 0);
  border: 0;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  opacity: 1;
  background-color: #a4a4a4;
}

.form-control[disabled], fieldset[disabled] .form-control {
  cursor: not-allowed;
}

textarea.form-control {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"].form-control, input[type="time"].form-control, input[type="datetime-local"].form-control, input[type="month"].form-control {
    line-height: 34px;
  }

  input[type="date"].input-sm, .input-group-sm > .input-group-btn > input[type="date"].btn, .input-group-sm input[type="date"], input[type="time"].input-sm, .input-group-sm > .input-group-btn > input[type="time"].btn, .input-group-sm input[type="time"], input[type="datetime-local"].input-sm, .input-group-sm > .input-group-btn > input[type="datetime-local"].btn, .input-group-sm input[type="datetime-local"], input[type="month"].input-sm, .input-group-sm > .input-group-btn > input[type="month"].btn, .input-group-sm input[type="month"] {
    line-height: 30px;
  }

  input[type="date"].input-lg, .input-group-lg > .input-group-btn > input[type="date"].btn, .input-group-lg input[type="date"], input[type="time"].input-lg, .input-group-lg > .input-group-btn > input[type="time"].btn, .input-group-lg input[type="time"], input[type="datetime-local"].input-lg, .input-group-lg > .input-group-btn > input[type="datetime-local"].btn, .input-group-lg input[type="datetime-local"], input[type="month"].input-lg, .input-group-lg > .input-group-btn > input[type="month"].btn, .input-group-lg input[type="month"] {
    line-height: 46px;
  }
}

.form-group {
  margin-bottom: 15px;
}

.radio, .checkbox {
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
  position: relative;
}

.radio label, .checkbox label {
  min-height: 20px;
  cursor: pointer;
  margin-bottom: 0;
  padding-left: 20px;
  font-weight: normal;
}

.radio input[type="radio"], .radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"] {
  margin-left: -20px;
  margin-top: 4px \9 ;
  position: absolute;
}

.radio + .radio, .checkbox + .checkbox {
  margin-top: -5px;
}

.radio-inline, .checkbox-inline {
  vertical-align: middle;
  cursor: pointer;
  margin-bottom: 0;
  padding-left: 20px;
  font-weight: normal;
  display: inline-block;
  position: relative;
}

.radio-inline + .radio-inline, .checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px;
}

input[type="radio"][disabled], input[type="radio"].disabled, fieldset[disabled] input[type="radio"], input[type="checkbox"][disabled], input[type="checkbox"].disabled, fieldset[disabled] input[type="checkbox"] {
  cursor: not-allowed;
}

.radio-inline.disabled, fieldset[disabled] .radio-inline, .checkbox-inline.disabled, fieldset[disabled] .checkbox-inline, .radio.disabled label, fieldset[disabled] .radio label, .checkbox.disabled label, fieldset[disabled] .checkbox label {
  cursor: not-allowed;
}

.form-control-static {
  min-height: 34px;
  margin-bottom: 0;
  padding-top: 7px;
  padding-bottom: 7px;
}

.form-control-static.input-lg, .input-group-lg > .form-control-static.form-control, .input-group-lg > .form-control-static.input-group-addon, .input-group-lg > .input-group-btn > .form-control-static.btn, .form-control-static.input-sm, .input-group-sm > .form-control-static.form-control, .input-group-sm > .form-control-static.input-group-addon, .input-group-sm > .input-group-btn > .form-control-static.btn {
  padding-left: 0;
  padding-right: 0;
}

.input-sm, .input-group-sm > .form-control, .input-group-sm > .input-group-addon, .input-group-sm > .input-group-btn > .btn {
  height: 30px;
  border-radius: 0;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

select.input-sm, .input-group-sm > select.form-control, .input-group-sm > select.input-group-addon, .input-group-sm > .input-group-btn > select.btn {
  height: 30px;
  line-height: 30px;
}

textarea.input-sm, .input-group-sm > textarea.form-control, .input-group-sm > textarea.input-group-addon, .input-group-sm > .input-group-btn > textarea.btn, select[multiple].input-sm, .input-group-sm > select[multiple].form-control, .input-group-sm > select[multiple].input-group-addon, .input-group-sm > .input-group-btn > select[multiple].btn {
  height: auto;
}

.form-group-sm .form-control {
  height: 30px;
  border-radius: 0;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.form-group-sm select.form-control {
  height: 30px;
  line-height: 30px;
}

.form-group-sm textarea.form-control, .form-group-sm select[multiple].form-control {
  height: auto;
}

.form-group-sm .form-control-static {
  height: 30px;
  min-height: 32px;
  padding: 6px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.input-lg, .input-group-lg > .form-control, .input-group-lg > .input-group-addon, .input-group-lg > .input-group-btn > .btn {
  height: 46px;
  border-radius: 0;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
}

select.input-lg, .input-group-lg > select.form-control, .input-group-lg > select.input-group-addon, .input-group-lg > .input-group-btn > select.btn {
  height: 46px;
  line-height: 46px;
}

textarea.input-lg, .input-group-lg > textarea.form-control, .input-group-lg > textarea.input-group-addon, .input-group-lg > .input-group-btn > textarea.btn, select[multiple].input-lg, .input-group-lg > select[multiple].form-control, .input-group-lg > select[multiple].input-group-addon, .input-group-lg > .input-group-btn > select[multiple].btn {
  height: auto;
}

.form-group-lg .form-control {
  height: 46px;
  border-radius: 0;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
}

.form-group-lg select.form-control {
  height: 46px;
  line-height: 46px;
}

.form-group-lg textarea.form-control, .form-group-lg select[multiple].form-control {
  height: auto;
}

.form-group-lg .form-control-static {
  height: 46px;
  min-height: 38px;
  padding: 11px 16px;
  font-size: 18px;
  line-height: 1.33333;
}

.has-feedback {
  position: relative;
}

.has-feedback .form-control {
  padding-right: 42.5px;
}

.form-control-feedback {
  z-index: 2;
  width: 34px;
  height: 34px;
  text-align: center;
  pointer-events: none;
  line-height: 34px;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.input-lg + .form-control-feedback, .input-group-lg > .form-control + .form-control-feedback, .input-group-lg > .input-group-addon + .form-control-feedback, .input-group-lg > .input-group-btn > .btn + .form-control-feedback, .input-group-lg + .form-control-feedback, .form-group-lg .form-control + .form-control-feedback {
  width: 46px;
  height: 46px;
  line-height: 46px;
}

.input-sm + .form-control-feedback, .input-group-sm > .form-control + .form-control-feedback, .input-group-sm > .input-group-addon + .form-control-feedback, .input-group-sm > .input-group-btn > .btn + .form-control-feedback, .input-group-sm + .form-control-feedback, .form-group-sm .form-control + .form-control-feedback {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.has-success .help-block, .has-success .control-label, .has-success .radio, .has-success .checkbox, .has-success .radio-inline, .has-success .checkbox-inline, .has-success.radio label, .has-success.checkbox label, .has-success.radio-inline label, .has-success.checkbox-inline label {
  color: #3c763d;
}

.has-success .form-control {
  border-color: #3c763d;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.has-success .form-control:focus {
  border-color: #2b542c;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #67b168;
}

.has-success .input-group-addon {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #3c763d;
}

.has-success .form-control-feedback {
  color: #3c763d;
}

.has-warning .help-block, .has-warning .control-label, .has-warning .radio, .has-warning .checkbox, .has-warning .radio-inline, .has-warning .checkbox-inline, .has-warning.radio label, .has-warning.checkbox label, .has-warning.radio-inline label, .has-warning.checkbox-inline label {
  color: #8a6d3b;
}

.has-warning .form-control {
  border-color: #8a6d3b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.has-warning .form-control:focus {
  border-color: #66512c;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #c0a16b;
}

.has-warning .input-group-addon {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #8a6d3b;
}

.has-warning .form-control-feedback {
  color: #8a6d3b;
}

.has-error .help-block, .has-error .control-label, .has-error .radio, .has-error .checkbox, .has-error .radio-inline, .has-error .checkbox-inline, .has-error.radio label, .has-error.checkbox label, .has-error.radio-inline label, .has-error.checkbox-inline label {
  color: #a94442;
}

.has-error .form-control {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.has-error .form-control:focus {
  border-color: #843534;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
}

.has-error .input-group-addon {
  color: #a94442;
  background-color: #f2dede;
  border-color: #a94442;
}

.has-error .form-control-feedback {
  color: #a94442;
}

.has-feedback label ~ .form-control-feedback {
  top: 25px;
}

.has-feedback label.sr-only ~ .form-control-feedback {
  top: 0;
}

.help-block {
  color: #5f5f58;
  margin-top: 5px;
  margin-bottom: 10px;
  display: block;
}

@media (min-width: 768px) {
  .form-inline .form-group {
    vertical-align: middle;
    margin-bottom: 0;
    display: inline-block;
  }

  .form-inline .form-control {
    width: auto;
    vertical-align: middle;
    display: inline-block;
  }

  .form-inline .form-control-static {
    display: inline-block;
  }

  .form-inline .input-group {
    vertical-align: middle;
    display: inline-table;
  }

  .form-inline .input-group .input-group-addon, .form-inline .input-group .input-group-btn, .form-inline .input-group .form-control {
    width: auto;
  }

  .form-inline .input-group > .form-control {
    width: 100%;
  }

  .form-inline .control-label {
    vertical-align: middle;
    margin-bottom: 0;
  }

  .form-inline .radio, .form-inline .checkbox {
    vertical-align: middle;
    margin-top: 0;
    margin-bottom: 0;
    display: inline-block;
  }

  .form-inline .radio label, .form-inline .checkbox label {
    padding-left: 0;
  }

  .form-inline .radio input[type="radio"], .form-inline .checkbox input[type="checkbox"] {
    margin-left: 0;
    position: relative;
  }

  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}

.form-horizontal .radio, .form-horizontal .checkbox, .form-horizontal .radio-inline, .form-horizontal .checkbox-inline {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 7px;
}

.form-horizontal .radio, .form-horizontal .checkbox {
  min-height: 27px;
}

.form-horizontal .form-group {
  margin-left: -25px;
  margin-right: -25px;
}

.form-horizontal .form-group:before, .form-horizontal .form-group:after {
  content: " ";
  display: table;
}

.form-horizontal .form-group:after {
  clear: both;
}

@media (min-width: 768px) {
  .form-horizontal .control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: 7px;
  }
}

.form-horizontal .has-feedback .form-control-feedback {
  right: 25px;
}

@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 11px;
    font-size: 18px;
  }

  .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 12px;
  }
}

.btn {
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  white-space: nowrap;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 0;
  margin-bottom: 0;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857;
  display: inline-block;
}

.btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.btn:hover, .btn:focus, .btn.focus {
  color: #333;
  text-decoration: none;
}

.btn:active, .btn.active {
  background-image: none;
  outline: 0;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}

.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
  cursor: not-allowed;
  opacity: .65;
  filter: alpha(opacity= 65);
  -webkit-box-shadow: none;
  box-shadow: none;
}

a.btn.disabled, fieldset[disabled] a.btn {
  pointer-events: none;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.btn-default:focus, .btn-default.focus {
  color: #333;
  background-color: #e6e6e6;
  border-color: #8c8c8c;
}

.btn-default:hover, .btn-default:active, .btn-default.active, .open > .btn-default.dropdown-toggle {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.btn-default:active:hover, .btn-default:active:focus, .btn-default:active.focus, .btn-default.active:hover, .btn-default.active:focus, .btn-default.active.focus, .open > .btn-default.dropdown-toggle:hover, .open > .btn-default.dropdown-toggle:focus, .open > .btn-default.dropdown-toggle.focus {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}

.btn-default:active, .btn-default.active, .open > .btn-default.dropdown-toggle {
  background-image: none;
}

.btn-default.disabled:hover, .btn-default.disabled:focus, .btn-default.disabled.focus, .btn-default[disabled]:hover, .btn-default[disabled]:focus, .btn-default[disabled].focus, fieldset[disabled] .btn-default:hover, fieldset[disabled] .btn-default:focus, fieldset[disabled] .btn-default.focus {
  background-color: #fff;
  border-color: #ccc;
}

.btn-default .badge {
  color: #fff;
  background-color: #333;
}

.btn-primary {
  color: #fff;
  background-color: #b4151d;
  border-color: #9d1219;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #861016;
  border-color: #2b0507;
}

.btn-primary:hover, .btn-primary:active, .btn-primary.active, .open > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #861016;
  border-color: #660c10;
}

.btn-primary:active:hover, .btn-primary:active:focus, .btn-primary:active.focus, .btn-primary.active:hover, .btn-primary.active:focus, .btn-primary.active.focus, .open > .btn-primary.dropdown-toggle:hover, .open > .btn-primary.dropdown-toggle:focus, .open > .btn-primary.dropdown-toggle.focus {
  color: #fff;
  background-color: #660c10;
  border-color: #2b0507;
}

.btn-primary:active, .btn-primary.active, .open > .btn-primary.dropdown-toggle {
  background-image: none;
}

.btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled.focus, .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled].focus, fieldset[disabled] .btn-primary:hover, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary.focus {
  background-color: #b4151d;
  border-color: #9d1219;
}

.btn-primary .badge {
  color: #b4151d;
  background-color: #fff;
}

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #449d44;
  border-color: #255625;
}

.btn-success:hover, .btn-success:active, .btn-success.active, .open > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}

.btn-success:active:hover, .btn-success:active:focus, .btn-success:active.focus, .btn-success.active:hover, .btn-success.active:focus, .btn-success.active.focus, .open > .btn-success.dropdown-toggle:hover, .open > .btn-success.dropdown-toggle:focus, .open > .btn-success.dropdown-toggle.focus {
  color: #fff;
  background-color: #398439;
  border-color: #255625;
}

.btn-success:active, .btn-success.active, .open > .btn-success.dropdown-toggle {
  background-image: none;
}

.btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled.focus, .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled].focus, fieldset[disabled] .btn-success:hover, fieldset[disabled] .btn-success:focus, fieldset[disabled] .btn-success.focus {
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-success .badge {
  color: #5cb85c;
  background-color: #fff;
}

.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #31b0d5;
  border-color: #1b6d85;
}

.btn-info:hover, .btn-info:active, .btn-info.active, .open > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc;
}

.btn-info:active:hover, .btn-info:active:focus, .btn-info:active.focus, .btn-info.active:hover, .btn-info.active:focus, .btn-info.active.focus, .open > .btn-info.dropdown-toggle:hover, .open > .btn-info.dropdown-toggle:focus, .open > .btn-info.dropdown-toggle.focus {
  color: #fff;
  background-color: #269abc;
  border-color: #1b6d85;
}

.btn-info:active, .btn-info.active, .open > .btn-info.dropdown-toggle {
  background-image: none;
}

.btn-info.disabled:hover, .btn-info.disabled:focus, .btn-info.disabled.focus, .btn-info[disabled]:hover, .btn-info[disabled]:focus, .btn-info[disabled].focus, fieldset[disabled] .btn-info:hover, fieldset[disabled] .btn-info:focus, fieldset[disabled] .btn-info.focus {
  background-color: #5bc0de;
  border-color: #46b8da;
}

.btn-info .badge {
  color: #5bc0de;
  background-color: #fff;
}

.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-warning:focus, .btn-warning.focus {
  color: #fff;
  background-color: #ec971f;
  border-color: #985f0d;
}

.btn-warning:hover, .btn-warning:active, .btn-warning.active, .open > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512;
}

.btn-warning:active:hover, .btn-warning:active:focus, .btn-warning:active.focus, .btn-warning.active:hover, .btn-warning.active:focus, .btn-warning.active.focus, .open > .btn-warning.dropdown-toggle:hover, .open > .btn-warning.dropdown-toggle:focus, .open > .btn-warning.dropdown-toggle.focus {
  color: #fff;
  background-color: #d58512;
  border-color: #985f0d;
}

.btn-warning:active, .btn-warning.active, .open > .btn-warning.dropdown-toggle {
  background-image: none;
}

.btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled.focus, .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled].focus, fieldset[disabled] .btn-warning:hover, fieldset[disabled] .btn-warning:focus, fieldset[disabled] .btn-warning.focus {
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-warning .badge {
  color: #f0ad4e;
  background-color: #fff;
}

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c9302c;
  border-color: #761c19;
}

.btn-danger:hover, .btn-danger:active, .btn-danger.active, .open > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}

.btn-danger:active:hover, .btn-danger:active:focus, .btn-danger:active.focus, .btn-danger.active:hover, .btn-danger.active:focus, .btn-danger.active.focus, .open > .btn-danger.dropdown-toggle:hover, .open > .btn-danger.dropdown-toggle:focus, .open > .btn-danger.dropdown-toggle.focus {
  color: #fff;
  background-color: #ac2925;
  border-color: #761c19;
}

.btn-danger:active, .btn-danger.active, .open > .btn-danger.dropdown-toggle {
  background-image: none;
}

.btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled.focus, .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled].focus, fieldset[disabled] .btn-danger:hover, fieldset[disabled] .btn-danger:focus, fieldset[disabled] .btn-danger.focus {
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-danger .badge {
  color: #d9534f;
  background-color: #fff;
}

.btn-link {
  color: #b4151d;
  border-radius: 0;
  font-weight: normal;
}

.btn-link, .btn-link:active, .btn-link.active, .btn-link[disabled], fieldset[disabled] .btn-link {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0);
}

.btn-link, .btn-link:hover, .btn-link:focus, .btn-link:active {
  border-color: rgba(0, 0, 0, 0);
}

.btn-link:hover, .btn-link:focus {
  color: #6f0d12;
  background-color: rgba(0, 0, 0, 0);
  text-decoration: underline;
}

.btn-link[disabled]:hover, .btn-link[disabled]:focus, fieldset[disabled] .btn-link:hover, fieldset[disabled] .btn-link:focus {
  color: #898989;
  text-decoration: none;
}

.btn-lg, .btn-group-lg > .btn {
  border-radius: 0;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
}

.btn-sm, .btn-group-sm > .btn {
  border-radius: 0;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.btn-xs, .btn-group-xs > .btn {
  border-radius: 0;
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
}

.btn-block {
  width: 100%;
  display: block;
}

.btn-block + .btn-block {
  margin-top: 5px;
}

input[type="submit"].btn-block, input[type="reset"].btn-block, input[type="button"].btn-block {
  width: 100%;
}

.fade {
  opacity: 0;
  -o-transition: opacity .15s linear;
  transition: opacity .15s linear;
}

.fade.in {
  opacity: 1;
}

.collapse {
  display: none;
}

.collapse.in {
  display: block;
}

tr.collapse.in {
  display: table-row;
}

tbody.collapse.in {
  display: table-row-group;
}

.collapsing {
  height: 0;
  transition-property: height, visibility;
  transition-duration: .35s;
  transition-timing-function: ease;
  position: relative;
  overflow: hidden;
}

.caret {
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9 ;
  border-left: 4px solid rgba(0, 0, 0, 0);
  border-right: 4px solid rgba(0, 0, 0, 0);
  margin-left: 2px;
  display: inline-block;
}

.dropup, .dropdown {
  position: relative;
}

.dropdown-toggle:focus {
  outline: 0;
}

.dropdown-menu {
  z-index: 1000;
  float: left;
  min-width: 160px;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 0;
  margin: 2px 0 0;
  padding: 5px 0;
  font-size: 14px;
  list-style: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 6px 12px rgba(0, 0, 0, .176);
}

.dropdown-menu.pull-right {
  left: auto;
  right: 0;
}

.dropdown-menu .divider {
  height: 1px;
  background-color: #e5e5e5;
  margin: 9px 0;
  overflow: hidden;
}

.dropdown-menu > li > a {
  clear: both;
  color: #1d1d1b;
  white-space: nowrap;
  padding: 3px 20px;
  font-weight: normal;
  line-height: 1.42857;
  display: block;
}

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
  color: #10100f;
  background-color: #f5f5f5;
  text-decoration: none;
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
  color: #fff;
  background-color: #b4151d;
  outline: 0;
  text-decoration: none;
}

.dropdown-menu > .disabled > a, .dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
  color: #898989;
}

.dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  cursor: not-allowed;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
  text-decoration: none;
}

.open > .dropdown-menu {
  display: block;
}

.open > a {
  outline: 0;
}

.dropdown-menu-right {
  left: auto;
  right: 0;
}

.dropdown-menu-left {
  left: 0;
  right: auto;
}

.dropdown-header {
  color: #898989;
  white-space: nowrap;
  padding: 3px 20px;
  font-size: 12px;
  line-height: 1.42857;
  display: block;
}

.dropdown-backdrop {
  z-index: 990;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.pull-right > .dropdown-menu {
  left: auto;
  right: 0;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9 ;
  content: "";
}

.dropup .dropdown-menu, .navbar-fixed-bottom .dropdown .dropdown-menu {
  margin-bottom: 2px;
  top: auto;
  bottom: 100%;
}

@media (min-width: 992px) {
  .navbar-right .dropdown-menu {
    left: auto;
    right: 0;
  }

  .navbar-right .dropdown-menu-left {
    left: 0;
    right: auto;
  }
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-block;
  position: relative;
}

.btn-group > .btn, .btn-group-vertical > .btn {
  float: left;
  position: relative;
}

.btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn:hover, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active {
  z-index: 2;
}

.btn-group .btn + .btn, .btn-group .btn + .btn-group, .btn-group .btn-group + .btn, .btn-group .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  margin-left: -5px;
}

.btn-toolbar:before, .btn-toolbar:after {
  content: " ";
  display: table;
}

.btn-toolbar:after {
  clear: both;
}

.btn-toolbar .btn, .btn-toolbar .btn-group, .btn-toolbar .input-group {
  float: left;
}

.btn-toolbar > .btn, .btn-toolbar > .btn-group, .btn-toolbar > .input-group {
  margin-left: 5px;
}

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:last-child:not(:first-child), .btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group > .btn-group {
  float: left;
}

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child, .btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group .dropdown-toggle:active, .btn-group.open .dropdown-toggle {
  outline: 0;
}

.btn-group > .btn + .dropdown-toggle {
  padding-left: 8px;
  padding-right: 8px;
}

.btn-group > .btn-lg + .dropdown-toggle, .btn-group-lg.btn-group > .btn + .dropdown-toggle {
  padding-left: 12px;
  padding-right: 12px;
}

.btn-group.open .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}

.btn-group.open .dropdown-toggle.btn-link {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn .caret {
  margin-left: 0;
}

.btn-lg .caret, .btn-group-lg > .btn .caret {
  border-width: 5px 5px 0;
}

.dropup .btn-lg .caret, .dropup .btn-group-lg > .btn .caret {
  border-width: 0 5px 5px;
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group, .btn-group-vertical > .btn-group > .btn {
  float: none;
  width: 100%;
  max-width: 100%;
  display: block;
}

.btn-group-vertical > .btn-group:before, .btn-group-vertical > .btn-group:after {
  content: " ";
  display: table;
}

.btn-group-vertical > .btn-group:after {
  clear: both;
}

.btn-group-vertical > .btn-group > .btn {
  float: none;
}

.btn-group-vertical > .btn + .btn, .btn-group-vertical > .btn + .btn-group, .btn-group-vertical > .btn-group + .btn, .btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn:not(:first-child):not(:last-child), .btn-group-vertical > .btn:first-child:not(:last-child), .btn-group-vertical > .btn:last-child:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child, .btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-justified {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  display: table;
}

.btn-group-justified > .btn, .btn-group-justified > .btn-group {
  float: none;
  width: 1%;
  display: table-cell;
}

.btn-group-justified > .btn-group .btn {
  width: 100%;
}

.btn-group-justified > .btn-group .dropdown-menu {
  left: auto;
}

[data-toggle="buttons"] > .btn input[type="radio"], [data-toggle="buttons"] > .btn input[type="checkbox"], [data-toggle="buttons"] > .btn-group > .btn input[type="radio"], [data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.input-group {
  border-collapse: separate;
  display: table;
  position: relative;
}

.input-group[class*="col-"] {
  float: none;
  padding-left: 0;
  padding-right: 0;
}

.input-group .form-control {
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
  position: relative;
}

.input-group .form-control:focus {
  z-index: 3;
}

.input-group-addon, .input-group-btn, .input-group .form-control {
  display: table-cell;
}

.input-group-addon:not(:first-child):not(:last-child), .input-group-btn:not(:first-child):not(:last-child), .input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.input-group-addon, .input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}

.input-group-addon {
  color: #444;
  text-align: center;
  background-color: #a4a4a4;
  border: 1px solid #898989;
  border-radius: 0;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
}

.input-group-addon.input-sm, .input-group-sm > .input-group-addon, .input-group-sm > .input-group-btn > .input-group-addon.btn {
  border-radius: 0;
  padding: 5px 10px;
  font-size: 12px;
}

.input-group-addon.input-lg, .input-group-lg > .input-group-addon, .input-group-lg > .input-group-btn > .input-group-addon.btn {
  border-radius: 0;
  padding: 10px 16px;
  font-size: 18px;
}

.input-group-addon input[type="radio"], .input-group-addon input[type="checkbox"] {
  margin-top: 0;
}

.input-group .form-control:first-child, .input-group-addon:first-child, .input-group-btn:first-child > .btn, .input-group-btn:first-child > .btn-group > .btn, .input-group-btn:first-child > .dropdown-toggle, .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-addon:first-child {
  border-right: 0;
}

.input-group .form-control:last-child, .input-group-addon:last-child, .input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group > .btn, .input-group-btn:last-child > .dropdown-toggle, .input-group-btn:first-child > .btn:not(:first-child), .input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-addon:last-child {
  border-left: 0;
}

.input-group-btn {
  white-space: nowrap;
  font-size: 0;
  position: relative;
}

.input-group-btn > .btn {
  position: relative;
}

.input-group-btn > .btn + .btn {
  margin-left: -1px;
}

.input-group-btn > .btn:hover, .input-group-btn > .btn:focus, .input-group-btn > .btn:active {
  z-index: 2;
}

.input-group-btn:first-child > .btn, .input-group-btn:first-child > .btn-group {
  margin-right: -1px;
}

.input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group {
  z-index: 2;
  margin-left: -1px;
}

.nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.nav:before, .nav:after {
  content: " ";
  display: table;
}

.nav:after {
  clear: both;
}

.nav > li {
  display: block;
  position: relative;
}

.nav > li > a {
  padding: 10px 15px;
  display: block;
  position: relative;
}

.nav > li > a:hover, .nav > li > a:focus {
  background-color: #a4a4a4;
  text-decoration: none;
}

.nav > li.disabled > a {
  color: #898989;
}

.nav > li.disabled > a:hover, .nav > li.disabled > a:focus {
  color: #898989;
  cursor: not-allowed;
  background-color: rgba(0, 0, 0, 0);
  text-decoration: none;
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
  background-color: #a4a4a4;
  border-color: #b4151d;
}

.nav .nav-divider {
  height: 1px;
  background-color: #e5e5e5;
  margin: 9px 0;
  overflow: hidden;
}

.nav > li > a > img {
  max-width: none;
}

.nav-tabs {
  border-bottom: 1px solid #ddd;
}

.nav-tabs > li {
  float: left;
  margin-bottom: -1px;
}

.nav-tabs > li > a {
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 0;
  margin-right: 2px;
  line-height: 1.42857;
}

.nav-tabs > li > a:hover {
  border-color: #a4a4a4 #a4a4a4 #ddd;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  color: #444;
  cursor: default;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: rgba(0, 0, 0, 0);
}

.nav-pills > li {
  float: left;
}

.nav-pills > li > a {
  border-radius: 0;
}

.nav-pills > li + li {
  margin-left: 2px;
}

.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
  color: #fff;
  background-color: #b4151d;
}

.nav-stacked > li {
  float: none;
}

.nav-stacked > li + li {
  margin-top: 2px;
  margin-left: 0;
}

.nav-justified, .nav-tabs.nav-justified {
  width: 100%;
}

.nav-justified > li, .nav-tabs.nav-justified > li {
  float: none;
}

.nav-justified > li > a, .nav-tabs.nav-justified > li > a {
  text-align: center;
  margin-bottom: 5px;
}

.nav-justified > .dropdown .dropdown-menu {
  top: auto;
  left: auto;
}

@media (min-width: 768px) {
  .nav-justified > li, .nav-tabs.nav-justified > li {
    width: 1%;
    display: table-cell;
  }

  .nav-justified > li > a, .nav-tabs.nav-justified > li > a {
    margin-bottom: 0;
  }
}

.nav-tabs-justified, .nav-tabs.nav-justified {
  border-bottom: 0;
}

.nav-tabs-justified > li > a, .nav-tabs.nav-justified > li > a {
  border-radius: 0;
  margin-right: 0;
}

.nav-tabs-justified > .active > a, .nav-tabs.nav-justified > .active > a, .nav-tabs-justified > .active > a:hover, .nav-tabs-justified > .active > a:focus {
  border: 1px solid #ddd;
}

@media (min-width: 768px) {
  .nav-tabs-justified > li > a, .nav-tabs.nav-justified > li > a {
    border-bottom: 1px solid #ddd;
    border-radius: 0;
  }

  .nav-tabs-justified > .active > a, .nav-tabs.nav-justified > .active > a, .nav-tabs-justified > .active > a:hover, .nav-tabs-justified > .active > a:focus {
    border-bottom-color: #fff;
  }
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}

.navbar {
  min-height: 50px;
  border: 1px solid rgba(0, 0, 0, 0);
  margin-bottom: 20px;
  position: relative;
}

.navbar:before, .navbar:after {
  content: " ";
  display: table;
}

.navbar:after {
  clear: both;
}

@media (min-width: 992px) {
  .navbar {
    border-radius: 0;
  }
}

.navbar-header:before, .navbar-header:after {
  content: " ";
  display: table;
}

.navbar-header:after {
  clear: both;
}

@media (min-width: 992px) {
  .navbar-header {
    float: left;
  }
}

.navbar-collapse {
  -webkit-overflow-scrolling: touch;
  border-top: 1px solid rgba(0, 0, 0, 0);
  padding-left: 25px;
  padding-right: 25px;
  overflow-x: visible;
  box-shadow: inset 0 1px rgba(255, 255, 255, .1);
}

.navbar-collapse:before, .navbar-collapse:after {
  content: " ";
  display: table;
}

.navbar-collapse:after {
  clear: both;
}

.navbar-collapse.in {
  overflow-y: auto;
}

@media (min-width: 992px) {
  .navbar-collapse {
    width: auto;
    box-shadow: none;
    border-top: 0;
  }

  .navbar-collapse.collapse {
    padding-bottom: 0;
    height: auto !important;
    display: block !important;
    overflow: visible !important;
  }

  .navbar-collapse.in {
    overflow-y: visible;
  }

  .navbar-fixed-top .navbar-collapse, .navbar-static-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
    padding-left: 0;
    padding-right: 0;
  }
}

.navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
  max-height: 340px;
}

@media (max-device-width: 480px) and (orientation: landscape) {
  .navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
    max-height: 200px;
  }
}

.container > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-header, .container-fluid > .navbar-collapse {
  margin-left: -25px;
  margin-right: -25px;
}

@media (min-width: 992px) {
  .container > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-header, .container-fluid > .navbar-collapse {
    margin-left: 0;
    margin-right: 0;
  }
}

.navbar-static-top {
  z-index: 1000;
  border-width: 0 0 1px;
}

@media (min-width: 992px) {
  .navbar-static-top {
    border-radius: 0;
  }
}

.navbar-fixed-top, .navbar-fixed-bottom {
  z-index: 1030;
  position: fixed;
  left: 0;
  right: 0;
}

@media (min-width: 992px) {
  .navbar-fixed-top, .navbar-fixed-bottom {
    border-radius: 0;
  }
}

.navbar-fixed-top {
  border-width: 0 0 1px;
  top: 0;
}

.navbar-fixed-bottom {
  border-width: 1px 0 0;
  margin-bottom: 0;
  bottom: 0;
}

.navbar-brand {
  float: left;
  height: 50px;
  padding: 15px 25px;
  font-size: 18px;
  line-height: 20px;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-brand > img {
  display: block;
}

@media (min-width: 992px) {
  .navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
    margin-left: -25px;
  }
}

.navbar-toggle {
  float: right;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 25px;
  padding: 9px 10px;
  position: relative;
}

.navbar-toggle:focus {
  outline: 0;
}

.navbar-toggle .icon-bar {
  width: 22px;
  height: 2px;
  border-radius: 1px;
  display: block;
}

.navbar-toggle .icon-bar + .icon-bar {
  margin-top: 4px;
}

@media (min-width: 992px) {
  .navbar-toggle {
    display: none;
  }
}

.navbar-nav {
  margin: 7.5px -25px;
}

.navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px;
}

@media (max-width: 767px) {
  .navbar-nav .open .dropdown-menu {
    float: none;
    width: auto;
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    margin-top: 0;
    position: static;
  }

  .navbar-nav .open .dropdown-menu > li > a, .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 5px 15px 5px 25px;
  }

  .navbar-nav .open .dropdown-menu > li > a {
    line-height: 20px;
  }

  .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-nav .open .dropdown-menu > li > a:focus {
    background-image: none;
  }
}

@media (min-width: 992px) {
  .navbar-nav {
    float: left;
    margin: 0;
  }

  .navbar-nav > li {
    float: left;
  }

  .navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.navbar-form {
  border-top: 1px solid rgba(0, 0, 0, 0);
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  margin: 8px -25px;
  padding: 10px 25px;
  box-shadow: inset 0 1px rgba(255, 255, 255, .1), 0 1px rgba(255, 255, 255, .1);
}

@media (min-width: 768px) {
  .navbar-form .form-group {
    vertical-align: middle;
    margin-bottom: 0;
    display: inline-block;
  }

  .navbar-form .form-control {
    width: auto;
    vertical-align: middle;
    display: inline-block;
  }

  .navbar-form .form-control-static {
    display: inline-block;
  }

  .navbar-form .input-group {
    vertical-align: middle;
    display: inline-table;
  }

  .navbar-form .input-group .input-group-addon, .navbar-form .input-group .input-group-btn, .navbar-form .input-group .form-control {
    width: auto;
  }

  .navbar-form .input-group > .form-control {
    width: 100%;
  }

  .navbar-form .control-label {
    vertical-align: middle;
    margin-bottom: 0;
  }

  .navbar-form .radio, .navbar-form .checkbox {
    vertical-align: middle;
    margin-top: 0;
    margin-bottom: 0;
    display: inline-block;
  }

  .navbar-form .radio label, .navbar-form .checkbox label {
    padding-left: 0;
  }

  .navbar-form .radio input[type="radio"], .navbar-form .checkbox input[type="checkbox"] {
    margin-left: 0;
    position: relative;
  }

  .navbar-form .has-feedback .form-control-feedback {
    top: 0;
  }
}

@media (max-width: 767px) {
  .navbar-form .form-group {
    margin-bottom: 5px;
  }

  .navbar-form .form-group:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .navbar-form {
    width: auto;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0;
    margin-left: 0;
    margin-right: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.navbar-nav > li > .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: 0;
}

.navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
  border-radius: 0;
  margin-bottom: 0;
}

.navbar-btn {
  margin-top: 8px;
  margin-bottom: 8px;
}

.navbar-btn.btn-sm, .btn-group-sm > .navbar-btn.btn {
  margin-top: 10px;
  margin-bottom: 10px;
}

.navbar-btn.btn-xs, .btn-group-xs > .navbar-btn.btn {
  margin-top: 14px;
  margin-bottom: 14px;
}

.navbar-text {
  margin-top: 15px;
  margin-bottom: 15px;
}

@media (min-width: 992px) {
  .navbar-text {
    float: left;
    margin-left: 25px;
    margin-right: 25px;
  }

  .navbar-left {
    float: left !important;
  }

  .navbar-right {
    margin-right: -25px;
    float: right !important;
  }

  .navbar-right ~ .navbar-right {
    margin-right: 0;
  }
}

.navbar-default {
  background-color: #a4a4a4;
  border-color: #939393;
}

.navbar-default .navbar-brand {
  color: #777;
}

.navbar-default .navbar-brand:hover, .navbar-default .navbar-brand:focus {
  color: #5e5e5e;
  background-color: rgba(0, 0, 0, 0);
}

.navbar-default .navbar-text {
  color: #fff;
}

.navbar-default .navbar-nav > li > a {
  color: #777;
}

.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
  color: #333;
  background-color: rgba(0, 0, 0, 0);
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
  color: #555;
  background-color: #939393;
}

.navbar-default .navbar-nav > .disabled > a, .navbar-default .navbar-nav > .disabled > a:hover, .navbar-default .navbar-nav > .disabled > a:focus {
  color: #ccc;
  background-color: rgba(0, 0, 0, 0);
}

.navbar-default .navbar-toggle {
  border-color: #ddd;
}

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
  background-color: #ddd;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #888;
}

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
  border-color: #939393;
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
  color: #555;
  background-color: #939393;
}

@media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #777;
  }

  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #333;
    background-color: rgba(0, 0, 0, 0);
  }

  .navbar-default .navbar-nav .open .dropdown-menu > .active > a, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #555;
    background-color: #939393;
  }

  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a, .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #ccc;
    background-color: rgba(0, 0, 0, 0);
  }
}

.navbar-default .navbar-link {
  color: #777;
}

.navbar-default .navbar-link:hover {
  color: #333;
}

.navbar-default .btn-link {
  color: #777;
}

.navbar-default .btn-link:hover, .navbar-default .btn-link:focus {
  color: #333;
}

.navbar-default .btn-link[disabled]:hover, .navbar-default .btn-link[disabled]:focus, fieldset[disabled] .navbar-default .btn-link:hover, fieldset[disabled] .navbar-default .btn-link:focus {
  color: #ccc;
}

.navbar-inverse {
  background-color: #222;
  border-color: #090909;
}

.navbar-inverse .navbar-brand {
  color: #afafaf;
}

.navbar-inverse .navbar-brand:hover, .navbar-inverse .navbar-brand:focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
}

.navbar-inverse .navbar-text, .navbar-inverse .navbar-nav > li > a {
  color: #afafaf;
}

.navbar-inverse .navbar-nav > li > a:hover, .navbar-inverse .navbar-nav > li > a:focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
}

.navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:hover, .navbar-inverse .navbar-nav > .active > a:focus {
  color: #fff;
  background-color: #090909;
}

.navbar-inverse .navbar-nav > .disabled > a, .navbar-inverse .navbar-nav > .disabled > a:hover, .navbar-inverse .navbar-nav > .disabled > a:focus {
  color: #444;
  background-color: rgba(0, 0, 0, 0);
}

.navbar-inverse .navbar-toggle {
  border-color: #333;
}

.navbar-inverse .navbar-toggle:hover, .navbar-inverse .navbar-toggle:focus {
  background-color: #333;
}

.navbar-inverse .navbar-toggle .icon-bar {
  background-color: #fff;
}

.navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
  border-color: #101010;
}

.navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus {
  color: #fff;
  background-color: #090909;
}

@media (max-width: 767px) {
  .navbar-inverse .navbar-nav .open .dropdown-menu > .dropdown-header {
    border-color: #090909;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
    background-color: #090909;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
    color: #afafaf;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a, .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #fff;
    background-color: #090909;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a, .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #444;
    background-color: rgba(0, 0, 0, 0);
  }
}

.navbar-inverse .navbar-link {
  color: #afafaf;
}

.navbar-inverse .navbar-link:hover {
  color: #fff;
}

.navbar-inverse .btn-link {
  color: #afafaf;
}

.navbar-inverse .btn-link:hover, .navbar-inverse .btn-link:focus {
  color: #fff;
}

.navbar-inverse .btn-link[disabled]:hover, .navbar-inverse .btn-link[disabled]:focus, fieldset[disabled] .navbar-inverse .btn-link:hover, fieldset[disabled] .navbar-inverse .btn-link:focus {
  color: #444;
}

.breadcrumb {
  background-color: #fff;
  border-radius: 0;
  margin-bottom: 20px;
  padding: 8px 15px;
  list-style: none;
}

.breadcrumb > li {
  display: inline-block;
}

.breadcrumb > li + li:before {
  content: "// ";
  color: #ccc;
  padding: 0 5px;
}

.breadcrumb > .active {
  color: #898989;
}

.pagination {
  border-radius: 0;
  margin: 20px 0;
  padding-left: 0;
  display: inline-block;
}

.pagination > li {
  display: inline;
}

.pagination > li > a, .pagination > li > span {
  float: left;
  color: #b4151d;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
  padding: 6px 12px;
  line-height: 1.42857;
  text-decoration: none;
  position: relative;
}

.pagination > li:first-child > a, .pagination > li:first-child > span {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0;
}

.pagination > li:last-child > a, .pagination > li:last-child > span {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination > li > a:hover, .pagination > li > a:focus, .pagination > li > span:hover, .pagination > li > span:focus {
  z-index: 2;
  color: #6f0d12;
  background-color: #a4a4a4;
  border-color: #ddd;
}

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #b4151d;
  border-color: #b4151d;
}

.pagination > .disabled > span, .pagination > .disabled > span:hover, .pagination > .disabled > span:focus, .pagination > .disabled > a, .pagination > .disabled > a:hover, .pagination > .disabled > a:focus {
  color: #898989;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}

.pagination-lg > li > a, .pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
}

.pagination-lg > li:first-child > a, .pagination-lg > li:first-child > span {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pagination-lg > li:last-child > a, .pagination-lg > li:last-child > span {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination-sm > li > a, .pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.pagination-sm > li:first-child > a, .pagination-sm > li:first-child > span {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pagination-sm > li:last-child > a, .pagination-sm > li:last-child > span {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pager {
  text-align: center;
  margin: 20px 0;
  padding-left: 0;
  list-style: none;
}

.pager:before, .pager:after {
  content: " ";
  display: table;
}

.pager:after {
  clear: both;
}

.pager li {
  display: inline;
}

.pager li > a, .pager li > span {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 5px 14px;
  display: inline-block;
}

.pager li > a:hover, .pager li > a:focus {
  background-color: #a4a4a4;
  text-decoration: none;
}

.pager .next > a, .pager .next > span {
  float: right;
}

.pager .previous > a, .pager .previous > span {
  float: left;
}

.pager .disabled > a, .pager .disabled > a:hover, .pager .disabled > a:focus, .pager .disabled > span {
  color: #898989;
  cursor: not-allowed;
  background-color: #fff;
}

.label {
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  display: inline;
}

.label:empty {
  display: none;
}

.btn .label {
  position: relative;
  top: -1px;
}

a.label:hover, a.label:focus {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}

.label-default {
  background-color: #898989;
}

.label-default[href]:hover, .label-default[href]:focus {
  background-color: #707070;
}

.label-primary {
  background-color: #b4151d;
}

.label-primary[href]:hover, .label-primary[href]:focus {
  background-color: #861016;
}

.label-success {
  background-color: #5cb85c;
}

.label-success[href]:hover, .label-success[href]:focus {
  background-color: #449d44;
}

.label-info {
  background-color: #5bc0de;
}

.label-info[href]:hover, .label-info[href]:focus {
  background-color: #31b0d5;
}

.label-warning {
  background-color: #f0ad4e;
}

.label-warning[href]:hover, .label-warning[href]:focus {
  background-color: #ec971f;
}

.label-danger {
  background-color: #d9534f;
}

.label-danger[href]:hover, .label-danger[href]:focus {
  background-color: #c9302c;
}

.badge {
  min-width: 10px;
  color: #fff;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  background-color: #898989;
  border-radius: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  display: inline-block;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.btn-xs .badge, .btn-group-xs > .btn .badge {
  padding: 1px 5px;
  top: 0;
}

.list-group-item.active > .badge, .nav-pills > .active > a > .badge {
  color: #b4151d;
  background-color: #fff;
}

.list-group-item > .badge {
  float: right;
}

.list-group-item > .badge + .badge {
  margin-right: 5px;
}

.nav-pills > li > a > .badge {
  margin-left: 3px;
}

a.badge:hover, a.badge:focus {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}

.jumbotron {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.jumbotron h1, .jumbotron .h1 {
  color: inherit;
}

.jumbotron p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 200;
}

.jumbotron > hr {
  border-top-color: rgba(0, 0, 0, 0);
}

.container .jumbotron, .container-fluid .jumbotron {
  border-radius: 0;
  padding-left: 25px;
  padding-right: 25px;
}

.jumbotron .container {
  max-width: 100%;
}

@media screen and (min-width: 768px) {
  .jumbotron {
    padding-top: 0;
    padding-bottom: 0;
  }

  .container .jumbotron, .container-fluid .jumbotron {
    padding-left: 0;
    padding-right: 0;
  }

  .jumbotron h1, .jumbotron .h1 {
    font-size: 63px;
  }
}

.thumbnail {
  -o-transition: border .2s ease-in-out;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0;
  margin-bottom: 20px;
  padding: 4px;
  line-height: 1.42857;
  transition: border .2s ease-in-out;
  display: block;
}

.thumbnail > img, .thumbnail a > img {
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.thumbnail .caption {
  color: #1d1d1b;
  padding: 9px;
}

a.thumbnail:hover, a.thumbnail:focus, a.thumbnail.active {
  border-color: #b4151d;
}

.alert {
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 0;
  margin-bottom: 20px;
  padding: 15px;
}

.alert h4 {
  color: inherit;
  margin-top: 0;
}

.alert .alert-link {
  font-weight: bold;
}

.alert > p, .alert > ul {
  margin-bottom: 0;
}

.alert > p + p {
  margin-top: 5px;
}

.alert-dismissable, .alert-dismissible {
  padding-right: 35px;
}

.alert-dismissable .close, .alert-dismissible .close {
  color: inherit;
  position: relative;
  top: -2px;
  right: -21px;
}

.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.alert-success hr {
  border-top-color: #c9e2b3;
}

.alert-success .alert-link {
  color: #2b542c;
}

.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.alert-info hr {
  border-top-color: #a6e1ec;
}

.alert-info .alert-link {
  color: #245269;
}

.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.alert-warning hr {
  border-top-color: #f7e1b5;
}

.alert-warning .alert-link {
  color: #66512c;
}

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.alert-danger hr {
  border-top-color: #e4b9c0;
}

.alert-danger .alert-link {
  color: #843534;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  height: 20px;
  background-color: #f5f5f5;
  border-radius: 0;
  margin-bottom: 20px;
  overflow: hidden;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}

.progress-bar {
  float: left;
  width: 0%;
  height: 100%;
  color: #fff;
  text-align: center;
  -o-transition: width .6s ease;
  background-color: #b4151d;
  font-size: 12px;
  line-height: 20px;
  transition: width .6s;
  box-shadow: inset 0 -1px rgba(0, 0, 0, .15);
}

.progress-striped .progress-bar, .progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
  background-size: 40px 40px;
}

.progress.active .progress-bar, .progress-bar.active {
  animation: 2s linear infinite progress-bar-stripes;
}

.progress-bar-success {
  background-color: #5cb85c;
}

.progress-striped .progress-bar-success {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
}

.progress-bar-info {
  background-color: #5bc0de;
}

.progress-striped .progress-bar-info {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
}

.progress-bar-warning {
  background-color: #f0ad4e;
}

.progress-striped .progress-bar-warning {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
}

.progress-bar-danger {
  background-color: #d9534f;
}

.progress-striped .progress-bar-danger {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
}

.media {
  margin-top: 15px;
}

.media:first-child {
  margin-top: 0;
}

.media, .media-body {
  zoom: 1;
  overflow: hidden;
}

.media-body {
  width: 10000px;
}

.media-object {
  display: block;
}

.media-object.img-thumbnail {
  max-width: none;
}

.media-right, .media > .pull-right {
  padding-left: 10px;
}

.media-left, .media > .pull-left {
  padding-right: 10px;
}

.media-left, .media-right, .media-body {
  vertical-align: top;
  display: table-cell;
}

.media-middle {
  vertical-align: middle;
}

.media-bottom {
  vertical-align: bottom;
}

.media-heading {
  margin-top: 0;
  margin-bottom: 5px;
}

.media-list {
  padding-left: 0;
  list-style: none;
}

.list-group {
  margin-bottom: 20px;
  padding-left: 0;
}

.list-group-item {
  background-color: #fff;
  border: 1px solid #ddd;
  margin-bottom: -1px;
  padding: 10px 15px;
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  margin-bottom: 0;
}

a.list-group-item, button.list-group-item {
  color: #555;
}

a.list-group-item .list-group-item-heading, button.list-group-item .list-group-item-heading {
  color: #333;
}

a.list-group-item:hover, a.list-group-item:focus, button.list-group-item:hover, button.list-group-item:focus {
  color: #555;
  background-color: #f5f5f5;
  text-decoration: none;
}

button.list-group-item {
  width: 100%;
  text-align: left;
}

.list-group-item.disabled, .list-group-item.disabled:hover, .list-group-item.disabled:focus {
  color: #898989;
  cursor: not-allowed;
  background-color: #a4a4a4;
}

.list-group-item.disabled .list-group-item-heading, .list-group-item.disabled:hover .list-group-item-heading, .list-group-item.disabled:focus .list-group-item-heading {
  color: inherit;
}

.list-group-item.disabled .list-group-item-text, .list-group-item.disabled:hover .list-group-item-text, .list-group-item.disabled:focus .list-group-item-text {
  color: #898989;
}

.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
  z-index: 2;
  color: #fff;
  background-color: #b4151d;
  border-color: #b4151d;
}

.list-group-item.active .list-group-item-heading, .list-group-item.active .list-group-item-heading > small, .list-group-item.active .list-group-item-heading > .small, .list-group-item.active:hover .list-group-item-heading, .list-group-item.active:hover .list-group-item-heading > small, .list-group-item.active:hover .list-group-item-heading > .small, .list-group-item.active:focus .list-group-item-heading, .list-group-item.active:focus .list-group-item-heading > small, .list-group-item.active:focus .list-group-item-heading > .small {
  color: inherit;
}

.list-group-item.active .list-group-item-text, .list-group-item.active:hover .list-group-item-text, .list-group-item.active:focus .list-group-item-text {
  color: #f4a1a5;
}

.list-group-item-success {
  color: #3c763d;
  background-color: #dff0d8;
}

a.list-group-item-success, button.list-group-item-success {
  color: #3c763d;
}

a.list-group-item-success .list-group-item-heading, button.list-group-item-success .list-group-item-heading {
  color: inherit;
}

a.list-group-item-success:hover, a.list-group-item-success:focus, button.list-group-item-success:hover, button.list-group-item-success:focus {
  color: #3c763d;
  background-color: #d0e9c6;
}

a.list-group-item-success.active, a.list-group-item-success.active:hover, a.list-group-item-success.active:focus, button.list-group-item-success.active, button.list-group-item-success.active:hover, button.list-group-item-success.active:focus {
  color: #fff;
  background-color: #3c763d;
  border-color: #3c763d;
}

.list-group-item-info {
  color: #31708f;
  background-color: #d9edf7;
}

a.list-group-item-info, button.list-group-item-info {
  color: #31708f;
}

a.list-group-item-info .list-group-item-heading, button.list-group-item-info .list-group-item-heading {
  color: inherit;
}

a.list-group-item-info:hover, a.list-group-item-info:focus, button.list-group-item-info:hover, button.list-group-item-info:focus {
  color: #31708f;
  background-color: #c4e3f3;
}

a.list-group-item-info.active, a.list-group-item-info.active:hover, a.list-group-item-info.active:focus, button.list-group-item-info.active, button.list-group-item-info.active:hover, button.list-group-item-info.active:focus {
  color: #fff;
  background-color: #31708f;
  border-color: #31708f;
}

.list-group-item-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
}

a.list-group-item-warning, button.list-group-item-warning {
  color: #8a6d3b;
}

a.list-group-item-warning .list-group-item-heading, button.list-group-item-warning .list-group-item-heading {
  color: inherit;
}

a.list-group-item-warning:hover, a.list-group-item-warning:focus, button.list-group-item-warning:hover, button.list-group-item-warning:focus {
  color: #8a6d3b;
  background-color: #faf2cc;
}

a.list-group-item-warning.active, a.list-group-item-warning.active:hover, a.list-group-item-warning.active:focus, button.list-group-item-warning.active, button.list-group-item-warning.active:hover, button.list-group-item-warning.active:focus {
  color: #fff;
  background-color: #8a6d3b;
  border-color: #8a6d3b;
}

.list-group-item-danger {
  color: #a94442;
  background-color: #f2dede;
}

a.list-group-item-danger, button.list-group-item-danger {
  color: #a94442;
}

a.list-group-item-danger .list-group-item-heading, button.list-group-item-danger .list-group-item-heading {
  color: inherit;
}

a.list-group-item-danger:hover, a.list-group-item-danger:focus, button.list-group-item-danger:hover, button.list-group-item-danger:focus {
  color: #a94442;
  background-color: #ebcccc;
}

a.list-group-item-danger.active, a.list-group-item-danger.active:hover, a.list-group-item-danger.active:focus, button.list-group-item-danger.active, button.list-group-item-danger.active:hover, button.list-group-item-danger.active:focus {
  color: #fff;
  background-color: #a94442;
  border-color: #a94442;
}

.list-group-item-heading {
  margin-top: 0;
  margin-bottom: 5px;
}

.list-group-item-text {
  margin-bottom: 0;
  line-height: 1.3;
}

.panel {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 0;
  margin-bottom: 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
}

.panel-body {
  padding: 15px;
}

.panel-body:before, .panel-body:after {
  content: " ";
  display: table;
}

.panel-body:after {
  clear: both;
}

.panel-heading {
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  border-top-left-radius: -1px;
  border-top-right-radius: -1px;
  padding: 10px 15px;
}

.panel-heading > .dropdown .dropdown-toggle {
  color: inherit;
}

.panel-title {
  color: inherit;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
}

.panel-title > a, .panel-title > small, .panel-title > .small, .panel-title > small > a, .panel-title > .small > a {
  color: inherit;
}

.panel-footer {
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: -1px;
  border-bottom-left-radius: -1px;
  padding: 10px 15px;
}

.panel > .list-group, .panel > .panel-collapse > .list-group {
  margin-bottom: 0;
}

.panel > .list-group .list-group-item, .panel > .panel-collapse > .list-group .list-group-item {
  border-width: 1px 0;
  border-radius: 0;
}

.panel > .list-group:first-child .list-group-item:first-child, .panel > .panel-collapse > .list-group:first-child .list-group-item:first-child {
  border-top: 0;
  border-top-left-radius: -1px;
  border-top-right-radius: -1px;
}

.panel > .list-group:last-child .list-group-item:last-child, .panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
  border-bottom: 0;
  border-bottom-right-radius: -1px;
  border-bottom-left-radius: -1px;
}

.panel > .panel-heading + .panel-collapse > .list-group .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.panel-heading + .list-group .list-group-item:first-child, .list-group + .panel-footer {
  border-top-width: 0;
}

.panel > .table, .panel > .table-responsive > .table, .panel > .panel-collapse > .table {
  margin-bottom: 0;
}

.panel > .table caption, .panel > .table-responsive > .table caption, .panel > .panel-collapse > .table caption {
  padding-left: 15px;
  padding-right: 15px;
}

.panel > .table:first-child, .panel > .table-responsive:first-child > .table:first-child, .panel > .table:first-child > thead:first-child > tr:first-child, .panel > .table:first-child > tbody:first-child > tr:first-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child {
  border-top-left-radius: -1px;
  border-top-right-radius: -1px;
}

.panel > .table:first-child > thead:first-child > tr:first-child td:first-child, .panel > .table:first-child > thead:first-child > tr:first-child th:first-child, .panel > .table:first-child > tbody:first-child > tr:first-child td:first-child, .panel > .table:first-child > tbody:first-child > tr:first-child th:first-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child {
  border-top-left-radius: -1px;
}

.panel > .table:first-child > thead:first-child > tr:first-child td:last-child, .panel > .table:first-child > thead:first-child > tr:first-child th:last-child, .panel > .table:first-child > tbody:first-child > tr:first-child td:last-child, .panel > .table:first-child > tbody:first-child > tr:first-child th:last-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child {
  border-top-right-radius: -1px;
}

.panel > .table:last-child, .panel > .table-responsive:last-child > .table:last-child, .panel > .table:last-child > tbody:last-child > tr:last-child, .panel > .table:last-child > tfoot:last-child > tr:last-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child {
  border-bottom-right-radius: -1px;
  border-bottom-left-radius: -1px;
}

.panel > .table:last-child > tbody:last-child > tr:last-child td:first-child, .panel > .table:last-child > tbody:last-child > tr:last-child th:first-child, .panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child, .panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
  border-bottom-left-radius: -1px;
}

.panel > .table:last-child > tbody:last-child > tr:last-child td:last-child, .panel > .table:last-child > tbody:last-child > tr:last-child th:last-child, .panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child, .panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
  border-bottom-right-radius: -1px;
}

.panel > .panel-body + .table, .panel > .panel-body + .table-responsive, .panel > .table + .panel-body, .panel > .table-responsive + .panel-body {
  border-top: 1px solid #ddd;
}

.panel > .table > tbody:first-child > tr:first-child th, .panel > .table > tbody:first-child > tr:first-child td {
  border-top: 0;
}

.panel > .table-bordered, .panel > .table-responsive > .table-bordered {
  border: 0;
}

.panel > .table-bordered > thead > tr > th:first-child, .panel > .table-bordered > thead > tr > td:first-child, .panel > .table-bordered > tbody > tr > th:first-child, .panel > .table-bordered > tbody > tr > td:first-child, .panel > .table-bordered > tfoot > tr > th:first-child, .panel > .table-bordered > tfoot > tr > td:first-child, .panel > .table-responsive > .table-bordered > thead > tr > th:first-child, .panel > .table-responsive > .table-bordered > thead > tr > td:first-child, .panel > .table-responsive > .table-bordered > tbody > tr > th:first-child, .panel > .table-responsive > .table-bordered > tbody > tr > td:first-child, .panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child, .panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child {
  border-left: 0;
}

.panel > .table-bordered > thead > tr > th:last-child, .panel > .table-bordered > thead > tr > td:last-child, .panel > .table-bordered > tbody > tr > th:last-child, .panel > .table-bordered > tbody > tr > td:last-child, .panel > .table-bordered > tfoot > tr > th:last-child, .panel > .table-bordered > tfoot > tr > td:last-child, .panel > .table-responsive > .table-bordered > thead > tr > th:last-child, .panel > .table-responsive > .table-bordered > thead > tr > td:last-child, .panel > .table-responsive > .table-bordered > tbody > tr > th:last-child, .panel > .table-responsive > .table-bordered > tbody > tr > td:last-child, .panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child, .panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child {
  border-right: 0;
}

.panel > .table-bordered > thead > tr:first-child > td, .panel > .table-bordered > thead > tr:first-child > th, .panel > .table-bordered > tbody > tr:first-child > td, .panel > .table-bordered > tbody > tr:first-child > th, .panel > .table-responsive > .table-bordered > thead > tr:first-child > td, .panel > .table-responsive > .table-bordered > thead > tr:first-child > th, .panel > .table-responsive > .table-bordered > tbody > tr:first-child > td, .panel > .table-responsive > .table-bordered > tbody > tr:first-child > th, .panel > .table-bordered > tbody > tr:last-child > td, .panel > .table-bordered > tbody > tr:last-child > th, .panel > .table-bordered > tfoot > tr:last-child > td, .panel > .table-bordered > tfoot > tr:last-child > th, .panel > .table-responsive > .table-bordered > tbody > tr:last-child > td, .panel > .table-responsive > .table-bordered > tbody > tr:last-child > th, .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td, .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th {
  border-bottom: 0;
}

.panel > .table-responsive {
  border: 0;
  margin-bottom: 0;
}

.panel-group {
  margin-bottom: 20px;
}

.panel-group .panel {
  border-radius: 0;
  margin-bottom: 0;
}

.panel-group .panel + .panel {
  margin-top: 5px;
}

.panel-group .panel-heading {
  border-bottom: 0;
}

.panel-group .panel-heading + .panel-collapse > .panel-body, .panel-group .panel-heading + .panel-collapse > .list-group {
  border-top: 1px solid #ddd;
}

.panel-group .panel-footer {
  border-top: 0;
}

.panel-group .panel-footer + .panel-collapse .panel-body {
  border-bottom: 1px solid #ddd;
}

.panel-default {
  border-color: #ddd;
}

.panel-default > .panel-heading {
  color: #1d1d1b;
  background-color: #f5f5f5;
  border-color: #ddd;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ddd;
}

.panel-default > .panel-heading .badge {
  color: #f5f5f5;
  background-color: #1d1d1b;
}

.panel-default > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ddd;
}

.panel-primary {
  border-color: #b4151d;
}

.panel-primary > .panel-heading {
  color: #fff;
  background-color: #b4151d;
  border-color: #b4151d;
}

.panel-primary > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #b4151d;
}

.panel-primary > .panel-heading .badge {
  color: #b4151d;
  background-color: #fff;
}

.panel-primary > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #b4151d;
}

.panel-success {
  border-color: #d6e9c6;
}

.panel-success > .panel-heading {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.panel-success > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #d6e9c6;
}

.panel-success > .panel-heading .badge {
  color: #dff0d8;
  background-color: #3c763d;
}

.panel-success > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #d6e9c6;
}

.panel-info {
  border-color: #bce8f1;
}

.panel-info > .panel-heading {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.panel-info > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #bce8f1;
}

.panel-info > .panel-heading .badge {
  color: #d9edf7;
  background-color: #31708f;
}

.panel-info > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #bce8f1;
}

.panel-warning {
  border-color: #faebcc;
}

.panel-warning > .panel-heading {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.panel-warning > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #faebcc;
}

.panel-warning > .panel-heading .badge {
  color: #fcf8e3;
  background-color: #8a6d3b;
}

.panel-warning > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #faebcc;
}

.panel-danger {
  border-color: #ebccd1;
}

.panel-danger > .panel-heading {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.panel-danger > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ebccd1;
}

.panel-danger > .panel-heading .badge {
  color: #f2dede;
  background-color: #a94442;
}

.panel-danger > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ebccd1;
}

.embed-responsive {
  height: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video {
  height: 100%;
  width: 100%;
  border: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}

.embed-responsive-4by3 {
  padding-bottom: 75%;
}

.well {
  min-height: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 0;
  margin-bottom: 20px;
  padding: 19px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
}

.well blockquote {
  border-color: rgba(0, 0, 0, .15);
}

.well-lg {
  border-radius: 0;
  padding: 24px;
}

.well-sm {
  border-radius: 0;
  padding: 9px;
}

.close {
  float: right;
  color: #000;
  text-shadow: 0 1px #fff;
  opacity: .2;
  filter: alpha(opacity= 20);
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
}

.close:hover, .close:focus {
  color: #000;
  cursor: pointer;
  opacity: .5;
  filter: alpha(opacity= 50);
  text-decoration: none;
}

button.close {
  cursor: pointer;
  -webkit-appearance: none;
  background: none;
  border: 0;
  padding: 0;
}

.modal-open {
  overflow: hidden;
}

.modal {
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform .3s ease-out;
  -o-transition: -o-transform .3s ease-out;
  -moz-transition: -moz-transform .3s ease-out;
  transition: transform .3s ease-out;
  transform: translate(0, -25%);
}

.modal.in .modal-dialog {
  transform: translate(0);
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  width: auto;
  margin: 10px;
  position: relative;
}

.modal-content {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 0;
  outline: 0;
  position: relative;
  box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
}

.modal-backdrop {
  z-index: 1040;
  background-color: #000;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal-backdrop.fade {
  opacity: 0;
  filter: alpha(opacity= 0);
}

.modal-backdrop.in {
  opacity: .5;
  filter: alpha(opacity= 50);
}

.modal-header {
  border-bottom: 1px solid #e5e5e5;
  padding: 15px;
}

.modal-header:before, .modal-header:after {
  content: " ";
  display: table;
}

.modal-header:after {
  clear: both;
}

.modal-header .close {
  margin-top: -2px;
}

.modal-title {
  margin: 0;
  line-height: 1.42857;
}

.modal-body {
  padding: 15px;
  position: relative;
}

.modal-footer {
  text-align: right;
  border-top: 1px solid #e5e5e5;
  padding: 15px;
}

.modal-footer:before, .modal-footer:after {
  content: " ";
  display: table;
}

.modal-footer:after {
  clear: both;
}

.modal-footer .btn + .btn {
  margin-bottom: 0;
  margin-left: 5px;
}

.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}

.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}

.modal-scrollbar-measure {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -9999px;
  overflow: scroll;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }

  .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
  }

  .modal-sm {
    width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}

.tooltip {
  z-index: 1070;
  letter-spacing: normal;
  line-break: auto;
  text-align: left;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  opacity: 0;
  filter: alpha(opacity= 0);
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.42857;
  text-decoration: none;
  display: block;
  position: absolute;
}

.tooltip:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  text-align: left;
}

.tooltip:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.tooltip.in {
  opacity: .9;
  filter: alpha(opacity= 90);
}

.tooltip.top {
  margin-top: -3px;
  padding: 5px 0;
}

.tooltip.right {
  margin-left: 3px;
  padding: 0 5px;
}

.tooltip.bottom {
  margin-top: 3px;
  padding: 5px 0;
}

.tooltip.left {
  margin-left: -3px;
  padding: 0 5px;
}

.tooltip-inner {
  max-width: 200px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0;
  padding: 3px 8px;
}

.tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  position: absolute;
}

.tooltip.top .tooltip-arrow {
  border-width: 5px 5px 0;
  border-top-color: #000;
  margin-left: -5px;
  bottom: 0;
  left: 50%;
}

.tooltip.top-left .tooltip-arrow {
  border-width: 5px 5px 0;
  border-top-color: #000;
  margin-bottom: -5px;
  bottom: 0;
  right: 5px;
}

.tooltip.top-right .tooltip-arrow {
  border-width: 5px 5px 0;
  border-top-color: #000;
  margin-bottom: -5px;
  bottom: 0;
  left: 5px;
}

.tooltip.right .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
  margin-top: -5px;
  top: 50%;
  left: 0;
}

.tooltip.left .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
  margin-top: -5px;
  top: 50%;
  right: 0;
}

.tooltip.bottom .tooltip-arrow {
  border-width: 0 5px 5px;
  border-bottom-color: #000;
  margin-left: -5px;
  top: 0;
  left: 50%;
}

.tooltip.bottom-left .tooltip-arrow {
  border-width: 0 5px 5px;
  border-bottom-color: #000;
  margin-top: -5px;
  top: 0;
  right: 5px;
}

.tooltip.bottom-right .tooltip-arrow {
  border-width: 0 5px 5px;
  border-bottom-color: #000;
  margin-top: -5px;
  top: 0;
  left: 5px;
}

.popover {
  z-index: 1060;
  max-width: 276px;
  letter-spacing: normal;
  line-break: auto;
  text-align: left;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 0;
  padding: 1px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.42857;
  text-decoration: none;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
}

.popover:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  text-align: left;
}

.popover:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.popover.top {
  margin-top: -10px;
}

.popover.right {
  margin-left: 10px;
}

.popover.bottom {
  margin-top: 10px;
}

.popover.left {
  margin-left: -10px;
}

.popover-title {
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: -1px -1px 0 0;
  margin: 0;
  padding: 8px 14px;
  font-size: 14px;
}

.popover-content {
  padding: 9px 14px;
}

.popover > .arrow, .popover > .arrow:after {
  width: 0;
  height: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  display: block;
  position: absolute;
}

.popover > .arrow {
  border-width: 11px;
}

.popover > .arrow:after {
  content: "";
  border-width: 10px;
}

.popover.top > .arrow {
  border-top-color: rgba(0, 0, 0, .25);
  border-bottom-width: 0;
  margin-left: -11px;
  bottom: -11px;
  left: 50%;
}

.popover.top > .arrow:after {
  content: " ";
  border-top-color: #fff;
  border-bottom-width: 0;
  margin-left: -10px;
  bottom: 1px;
}

.popover.right > .arrow {
  border-left-width: 0;
  border-right-color: rgba(0, 0, 0, .25);
  margin-top: -11px;
  top: 50%;
  left: -11px;
}

.popover.right > .arrow:after {
  content: " ";
  border-left-width: 0;
  border-right-color: #fff;
  bottom: -10px;
  left: 1px;
}

.popover.bottom > .arrow {
  border-top-width: 0;
  border-bottom-color: rgba(0, 0, 0, .25);
  margin-left: -11px;
  top: -11px;
  left: 50%;
}

.popover.bottom > .arrow:after {
  content: " ";
  border-top-width: 0;
  border-bottom-color: #fff;
  margin-left: -10px;
  top: 1px;
}

.popover.left > .arrow {
  border-left-color: rgba(0, 0, 0, .25);
  border-right-width: 0;
  margin-top: -11px;
  top: 50%;
  right: -11px;
}

.popover.left > .arrow:after {
  content: " ";
  border-left-color: #fff;
  border-right-width: 0;
  bottom: -10px;
  right: 1px;
}

.carousel {
  position: relative;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner > .item {
  -o-transition: .6s ease-in-out left;
  transition: left .6s ease-in-out;
  display: none;
  position: relative;
}

.carousel-inner > .item > img, .carousel-inner > .item > a > img {
  max-width: 100%;
  height: auto;
  line-height: 1;
  display: block;
}

@media (transform-3d), (-webkit-transform-3d) {
  .carousel-inner > .item {
    -webkit-transition: -webkit-transform .6s ease-in-out;
    -o-transition: -o-transform .6s ease-in-out;
    -moz-transition: -moz-transform .6s ease-in-out;
    backface-visibility: hidden;
    perspective: 1000px;
    transition: transform .6s ease-in-out;
  }

  .carousel-inner > .item.next, .carousel-inner > .item.active.right {
    left: 0;
    transform: translate3d(100%, 0, 0);
  }

  .carousel-inner > .item.prev, .carousel-inner > .item.active.left {
    left: 0;
    transform: translate3d(-100%, 0, 0);
  }

  .carousel-inner > .item.next.left, .carousel-inner > .item.prev.right, .carousel-inner > .item.active {
    left: 0;
    transform: translate3d(0, 0, 0);
  }
}

.carousel-inner > .active, .carousel-inner > .next, .carousel-inner > .prev {
  display: block;
}

.carousel-inner > .active {
  left: 0;
}

.carousel-inner > .next, .carousel-inner > .prev {
  width: 100%;
  position: absolute;
  top: 0;
}

.carousel-inner > .next {
  left: 100%;
}

.carousel-inner > .prev {
  left: -100%;
}

.carousel-inner > .next.left, .carousel-inner > .prev.right {
  left: 0;
}

.carousel-inner > .active.left {
  left: -100%;
}

.carousel-inner > .active.right {
  left: 100%;
}

.carousel-control {
  width: 15%;
  opacity: .5;
  filter: alpha(opacity= 50);
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
  background-color: rgba(0, 0, 0, 0);
  font-size: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.carousel-control.left {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#80000000", endColorstr= "#00000000", GradientType= 1);
  background-image: linear-gradient(to right, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, 0) 100%);
  background-repeat: repeat-x;
}

.carousel-control.right {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#00000000", endColorstr= "#80000000", GradientType= 1);
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .5) 100%);
  background-repeat: repeat-x;
  left: auto;
  right: 0;
}

.carousel-control:hover, .carousel-control:focus {
  color: #fff;
  opacity: .9;
  filter: alpha(opacity= 90);
  outline: 0;
  text-decoration: none;
}

.carousel-control .icon-prev, .carousel-control .icon-next, .carousel-control .glyphicon-chevron-left, .carousel-control .glyphicon-chevron-right {
  z-index: 5;
  margin-top: -10px;
  display: inline-block;
  position: absolute;
  top: 50%;
}

.carousel-control .icon-prev, .carousel-control .glyphicon-chevron-left {
  margin-left: -10px;
  left: 50%;
}

.carousel-control .icon-next, .carousel-control .glyphicon-chevron-right {
  margin-right: -10px;
  right: 50%;
}

.carousel-control .icon-prev, .carousel-control .icon-next {
  width: 20px;
  height: 20px;
  font-family: serif;
  line-height: 1;
}

.carousel-control .icon-prev:before {
  content: "‹";
}

.carousel-control .icon-next:before {
  content: "›";
}

.carousel-indicators {
  z-index: 15;
  width: 60%;
  text-align: center;
  margin-left: -30%;
  padding-left: 0;
  list-style: none;
  position: absolute;
  bottom: 10px;
  left: 50%;
}

.carousel-indicators li {
  width: 10px;
  height: 10px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #000 \9 ;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #fff;
  border-radius: 10px;
  margin: 1px;
  display: inline-block;
}

.carousel-indicators .active {
  width: 12px;
  height: 12px;
  background-color: #fff;
  margin: 0;
}

.carousel-caption {
  z-index: 10;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  bottom: 20px;
  left: 15%;
  right: 15%;
}

.carousel-caption .btn {
  text-shadow: none;
}

@media screen and (min-width: 768px) {
  .carousel-control .glyphicon-chevron-left, .carousel-control .glyphicon-chevron-right, .carousel-control .icon-prev, .carousel-control .icon-next {
    width: 30px;
    height: 30px;
    margin-top: -10px;
    font-size: 30px;
  }

  .carousel-control .glyphicon-chevron-left, .carousel-control .icon-prev {
    margin-left: -10px;
  }

  .carousel-control .glyphicon-chevron-right, .carousel-control .icon-next {
    margin-right: -10px;
  }

  .carousel-caption {
    padding-bottom: 30px;
    left: 20%;
    right: 20%;
  }

  .carousel-indicators {
    bottom: 20px;
  }
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.center-block {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.text-hide {
  font: 0 / 0 a;
  color: rgba(0, 0, 0, 0);
  text-shadow: none;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
}

.hidden {
  display: none !important;
}

.affix {
  position: fixed;
}

@-ms-viewport {
  width: device-width;
}

.visible-xs, .visible-sm, .visible-md, .visible-lg, .visible-xs-block, .visible-xs-inline, .visible-xs-inline-block, .visible-sm-block, .visible-sm-inline, .visible-sm-inline-block, .visible-md-block, .visible-md-inline, .visible-md-inline-block, .visible-lg-block, .visible-lg-inline, .visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }

  table.visible-xs {
    display: table !important;
  }

  tr.visible-xs {
    display: table-row !important;
  }

  th.visible-xs, td.visible-xs {
    display: table-cell !important;
  }

  .visible-xs-block {
    display: block !important;
  }

  .visible-xs-inline {
    display: inline !important;
  }

  .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }

  table.visible-sm {
    display: table !important;
  }

  tr.visible-sm {
    display: table-row !important;
  }

  th.visible-sm, td.visible-sm {
    display: table-cell !important;
  }

  .visible-sm-block {
    display: block !important;
  }

  .visible-sm-inline {
    display: inline !important;
  }

  .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }

  table.visible-md {
    display: table !important;
  }

  tr.visible-md {
    display: table-row !important;
  }

  th.visible-md, td.visible-md {
    display: table-cell !important;
  }

  .visible-md-block {
    display: block !important;
  }

  .visible-md-inline {
    display: inline !important;
  }

  .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }

  table.visible-lg {
    display: table !important;
  }

  tr.visible-lg {
    display: table-row !important;
  }

  th.visible-lg, td.visible-lg {
    display: table-cell !important;
  }

  .visible-lg-block {
    display: block !important;
  }

  .visible-lg-inline {
    display: inline !important;
  }

  .visible-lg-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

.visible-print {
  display: none !important;
}

@media print {
  .visible-print {
    display: block !important;
  }

  table.visible-print {
    display: table !important;
  }

  tr.visible-print {
    display: table-row !important;
  }

  th.visible-print, td.visible-print {
    display: table-cell !important;
  }
}

.visible-print-block {
  display: none !important;
}

@media print {
  .visible-print-block {
    display: block !important;
  }
}

.visible-print-inline {
  display: none !important;
}

@media print {
  .visible-print-inline {
    display: inline !important;
  }
}

.visible-print-inline-block {
  display: none !important;
}

@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }

  .hidden-print {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .container {
    width: 770px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 12px;
    padding-right: 13px;
  }

  .container:before, .container:after {
    content: " ";
    display: table;
  }

  .container:after {
    clear: both;
  }
}

@media (min-width: 992px) {
  .container {
    width: 990px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 25px;
    padding-right: 25px;
  }

  .container:before, .container:after {
    content: " ";
    display: table;
  }

  .container:after {
    clear: both;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1190px;
  }
}

@media (min-width: 1600px) {
  .container {
    width: 1550px;
  }
}

.container-fluid {
  margin-left: auto;
  margin-right: auto;
  padding-left: 12px;
  padding-right: 13px;
}

.container-fluid:before, .container-fluid:after {
  content: " ";
  display: table;
}

.container-fluid:after {
  clear: both;
}

@media (min-width: 992px) {
  .container-fluid {
    margin-left: auto;
    margin-right: auto;
    padding-left: 25px;
    padding-right: 25px;
  }

  .container-fluid:before, .container-fluid:after {
    content: " ";
    display: table;
  }

  .container-fluid:after {
    clear: both;
  }
}

.row {
  margin-left: -12px;
  margin-right: -13px;
}

.row:before, .row:after {
  content: " ";
  display: table;
}

.row:after {
  clear: both;
}

@media (min-width: 992px) {
  .row {
    margin-left: -25px;
    margin-right: -25px;
  }

  .row:before, .row:after {
    content: " ";
    display: table;
  }

  .row:after {
    clear: both;
  }
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xl-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xl-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xl-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xl-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xl-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xl-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xl-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xl-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xl-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xl-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12, .col-xl-12 {
  min-height: 1px;
  padding-left: 13px;
  padding-right: 12px;
  position: relative;
}

@media (min-width: 992px) {
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xl-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xl-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xl-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xl-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xl-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xl-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xl-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xl-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xl-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xl-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12, .col-xl-12 {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}

.col-xs-1 {
  width: 8.33333%;
}

.col-xs-2 {
  width: 16.6667%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-4 {
  width: 33.3333%;
}

.col-xs-5 {
  width: 41.6667%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-7 {
  width: 58.3333%;
}

.col-xs-8 {
  width: 66.6667%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-10 {
  width: 83.3333%;
}

.col-xs-11 {
  width: 91.6667%;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-pull-1 {
  right: 8.33333%;
}

.col-xs-pull-2 {
  right: 16.6667%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-4 {
  right: 33.3333%;
}

.col-xs-pull-5 {
  right: 41.6667%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-7 {
  right: 58.3333%;
}

.col-xs-pull-8 {
  right: 66.6667%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-10 {
  right: 83.3333%;
}

.col-xs-pull-11 {
  right: 91.6667%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-push-1 {
  left: 8.33333%;
}

.col-xs-push-2 {
  left: 16.6667%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-4 {
  left: 33.3333%;
}

.col-xs-push-5 {
  left: 41.6667%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-7 {
  left: 58.3333%;
}

.col-xs-push-8 {
  left: 66.6667%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-10 {
  left: 83.3333%;
}

.col-xs-push-11 {
  left: 91.6667%;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-offset-0 {
  margin-left: 0%;
}

.col-xs-offset-1 {
  margin-left: 8.33333%;
}

.col-xs-offset-2 {
  margin-left: 16.6667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.3333%;
}

.col-xs-offset-5 {
  margin-left: 41.6667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.3333%;
}

.col-xs-offset-8 {
  margin-left: 66.6667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.3333%;
}

.col-xs-offset-11 {
  margin-left: 91.6667%;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }

  .col-sm-1 {
    width: 8.33333%;
  }

  .col-sm-2 {
    width: 16.6667%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-4 {
    width: 33.3333%;
  }

  .col-sm-5 {
    width: 41.6667%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-7 {
    width: 58.3333%;
  }

  .col-sm-8 {
    width: 66.6667%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-10 {
    width: 83.3333%;
  }

  .col-sm-11 {
    width: 91.6667%;
  }

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-pull-0 {
    right: auto;
  }

  .col-sm-pull-1 {
    right: 8.33333%;
  }

  .col-sm-pull-2 {
    right: 16.6667%;
  }

  .col-sm-pull-3 {
    right: 25%;
  }

  .col-sm-pull-4 {
    right: 33.3333%;
  }

  .col-sm-pull-5 {
    right: 41.6667%;
  }

  .col-sm-pull-6 {
    right: 50%;
  }

  .col-sm-pull-7 {
    right: 58.3333%;
  }

  .col-sm-pull-8 {
    right: 66.6667%;
  }

  .col-sm-pull-9 {
    right: 75%;
  }

  .col-sm-pull-10 {
    right: 83.3333%;
  }

  .col-sm-pull-11 {
    right: 91.6667%;
  }

  .col-sm-pull-12 {
    right: 100%;
  }

  .col-sm-push-0 {
    left: auto;
  }

  .col-sm-push-1 {
    left: 8.33333%;
  }

  .col-sm-push-2 {
    left: 16.6667%;
  }

  .col-sm-push-3 {
    left: 25%;
  }

  .col-sm-push-4 {
    left: 33.3333%;
  }

  .col-sm-push-5 {
    left: 41.6667%;
  }

  .col-sm-push-6 {
    left: 50%;
  }

  .col-sm-push-7 {
    left: 58.3333%;
  }

  .col-sm-push-8 {
    left: 66.6667%;
  }

  .col-sm-push-9 {
    left: 75%;
  }

  .col-sm-push-10 {
    left: 83.3333%;
  }

  .col-sm-push-11 {
    left: 91.6667%;
  }

  .col-sm-push-12 {
    left: 100%;
  }

  .col-sm-offset-0 {
    margin-left: 0%;
  }

  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }

  .col-sm-offset-2 {
    margin-left: 16.6667%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-4 {
    margin-left: 33.3333%;
  }

  .col-sm-offset-5 {
    margin-left: 41.6667%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-7 {
    margin-left: 58.3333%;
  }

  .col-sm-offset-8 {
    margin-left: 66.6667%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-10 {
    margin-left: 83.3333%;
  }

  .col-sm-offset-11 {
    margin-left: 91.6667%;
  }

  .col-sm-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }

  .col-md-1 {
    width: 8.33333%;
  }

  .col-md-2 {
    width: 16.6667%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-4 {
    width: 33.3333%;
  }

  .col-md-5 {
    width: 41.6667%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-7 {
    width: 58.3333%;
  }

  .col-md-8 {
    width: 66.6667%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-10 {
    width: 83.3333%;
  }

  .col-md-11 {
    width: 91.6667%;
  }

  .col-md-12 {
    width: 100%;
  }

  .col-md-pull-0 {
    right: auto;
  }

  .col-md-pull-1 {
    right: 8.33333%;
  }

  .col-md-pull-2 {
    right: 16.6667%;
  }

  .col-md-pull-3 {
    right: 25%;
  }

  .col-md-pull-4 {
    right: 33.3333%;
  }

  .col-md-pull-5 {
    right: 41.6667%;
  }

  .col-md-pull-6 {
    right: 50%;
  }

  .col-md-pull-7 {
    right: 58.3333%;
  }

  .col-md-pull-8 {
    right: 66.6667%;
  }

  .col-md-pull-9 {
    right: 75%;
  }

  .col-md-pull-10 {
    right: 83.3333%;
  }

  .col-md-pull-11 {
    right: 91.6667%;
  }

  .col-md-pull-12 {
    right: 100%;
  }

  .col-md-push-0 {
    left: auto;
  }

  .col-md-push-1 {
    left: 8.33333%;
  }

  .col-md-push-2 {
    left: 16.6667%;
  }

  .col-md-push-3 {
    left: 25%;
  }

  .col-md-push-4 {
    left: 33.3333%;
  }

  .col-md-push-5 {
    left: 41.6667%;
  }

  .col-md-push-6 {
    left: 50%;
  }

  .col-md-push-7 {
    left: 58.3333%;
  }

  .col-md-push-8 {
    left: 66.6667%;
  }

  .col-md-push-9 {
    left: 75%;
  }

  .col-md-push-10 {
    left: 83.3333%;
  }

  .col-md-push-11 {
    left: 91.6667%;
  }

  .col-md-push-12 {
    left: 100%;
  }

  .col-md-offset-0 {
    margin-left: 0%;
  }

  .col-md-offset-1 {
    margin-left: 8.33333%;
  }

  .col-md-offset-2 {
    margin-left: 16.6667%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-4 {
    margin-left: 33.3333%;
  }

  .col-md-offset-5 {
    margin-left: 41.6667%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-7 {
    margin-left: 58.3333%;
  }

  .col-md-offset-8 {
    margin-left: 66.6667%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-10 {
    margin-left: 83.3333%;
  }

  .col-md-offset-11 {
    margin-left: 91.6667%;
  }

  .col-md-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }

  .col-lg-1 {
    width: 8.33333%;
  }

  .col-lg-2 {
    width: 16.6667%;
  }

  .col-lg-3 {
    width: 25%;
  }

  .col-lg-4 {
    width: 33.3333%;
  }

  .col-lg-5 {
    width: 41.6667%;
  }

  .col-lg-6 {
    width: 50%;
  }

  .col-lg-7 {
    width: 58.3333%;
  }

  .col-lg-8 {
    width: 66.6667%;
  }

  .col-lg-9 {
    width: 75%;
  }

  .col-lg-10 {
    width: 83.3333%;
  }

  .col-lg-11 {
    width: 91.6667%;
  }

  .col-lg-12 {
    width: 100%;
  }

  .col-lg-pull-0 {
    right: auto;
  }

  .col-lg-pull-1 {
    right: 8.33333%;
  }

  .col-lg-pull-2 {
    right: 16.6667%;
  }

  .col-lg-pull-3 {
    right: 25%;
  }

  .col-lg-pull-4 {
    right: 33.3333%;
  }

  .col-lg-pull-5 {
    right: 41.6667%;
  }

  .col-lg-pull-6 {
    right: 50%;
  }

  .col-lg-pull-7 {
    right: 58.3333%;
  }

  .col-lg-pull-8 {
    right: 66.6667%;
  }

  .col-lg-pull-9 {
    right: 75%;
  }

  .col-lg-pull-10 {
    right: 83.3333%;
  }

  .col-lg-pull-11 {
    right: 91.6667%;
  }

  .col-lg-pull-12 {
    right: 100%;
  }

  .col-lg-push-0 {
    left: auto;
  }

  .col-lg-push-1 {
    left: 8.33333%;
  }

  .col-lg-push-2 {
    left: 16.6667%;
  }

  .col-lg-push-3 {
    left: 25%;
  }

  .col-lg-push-4 {
    left: 33.3333%;
  }

  .col-lg-push-5 {
    left: 41.6667%;
  }

  .col-lg-push-6 {
    left: 50%;
  }

  .col-lg-push-7 {
    left: 58.3333%;
  }

  .col-lg-push-8 {
    left: 66.6667%;
  }

  .col-lg-push-9 {
    left: 75%;
  }

  .col-lg-push-10 {
    left: 83.3333%;
  }

  .col-lg-push-11 {
    left: 91.6667%;
  }

  .col-lg-push-12 {
    left: 100%;
  }

  .col-lg-offset-0 {
    margin-left: 0%;
  }

  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }

  .col-lg-offset-2 {
    margin-left: 16.6667%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-4 {
    margin-left: 33.3333%;
  }

  .col-lg-offset-5 {
    margin-left: 41.6667%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-7 {
    margin-left: 58.3333%;
  }

  .col-lg-offset-8 {
    margin-left: 66.6667%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-10 {
    margin-left: 83.3333%;
  }

  .col-lg-offset-11 {
    margin-left: 91.6667%;
  }

  .col-lg-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 1600px) {
  .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
    float: left;
  }

  .col-xl-1 {
    width: 8.33333%;
  }

  .col-xl-2 {
    width: 16.6667%;
  }

  .col-xl-3 {
    width: 25%;
  }

  .col-xl-4 {
    width: 33.3333%;
  }

  .col-xl-5 {
    width: 41.6667%;
  }

  .col-xl-6 {
    width: 50%;
  }

  .col-xl-7 {
    width: 58.3333%;
  }

  .col-xl-8 {
    width: 66.6667%;
  }

  .col-xl-9 {
    width: 75%;
  }

  .col-xl-10 {
    width: 83.3333%;
  }

  .col-xl-11 {
    width: 91.6667%;
  }

  .col-xl-12 {
    width: 100%;
  }

  .col-xl-pull-0 {
    right: auto;
  }

  .col-xl-pull-1 {
    right: 8.33333%;
  }

  .col-xl-pull-2 {
    right: 16.6667%;
  }

  .col-xl-pull-3 {
    right: 25%;
  }

  .col-xl-pull-4 {
    right: 33.3333%;
  }

  .col-xl-pull-5 {
    right: 41.6667%;
  }

  .col-xl-pull-6 {
    right: 50%;
  }

  .col-xl-pull-7 {
    right: 58.3333%;
  }

  .col-xl-pull-8 {
    right: 66.6667%;
  }

  .col-xl-pull-9 {
    right: 75%;
  }

  .col-xl-pull-10 {
    right: 83.3333%;
  }

  .col-xl-pull-11 {
    right: 91.6667%;
  }

  .col-xl-pull-12 {
    right: 100%;
  }

  .col-xl-push-0 {
    left: auto;
  }

  .col-xl-push-1 {
    left: 8.33333%;
  }

  .col-xl-push-2 {
    left: 16.6667%;
  }

  .col-xl-push-3 {
    left: 25%;
  }

  .col-xl-push-4 {
    left: 33.3333%;
  }

  .col-xl-push-5 {
    left: 41.6667%;
  }

  .col-xl-push-6 {
    left: 50%;
  }

  .col-xl-push-7 {
    left: 58.3333%;
  }

  .col-xl-push-8 {
    left: 66.6667%;
  }

  .col-xl-push-9 {
    left: 75%;
  }

  .col-xl-push-10 {
    left: 83.3333%;
  }

  .col-xl-push-11 {
    left: 91.6667%;
  }

  .col-xl-push-12 {
    left: 100%;
  }

  .col-xl-offset-0 {
    margin-left: 0%;
  }

  .col-xl-offset-1 {
    margin-left: 8.33333%;
  }

  .col-xl-offset-2 {
    margin-left: 16.6667%;
  }

  .col-xl-offset-3 {
    margin-left: 25%;
  }

  .col-xl-offset-4 {
    margin-left: 33.3333%;
  }

  .col-xl-offset-5 {
    margin-left: 41.6667%;
  }

  .col-xl-offset-6 {
    margin-left: 50%;
  }

  .col-xl-offset-7 {
    margin-left: 58.3333%;
  }

  .col-xl-offset-8 {
    margin-left: 66.6667%;
  }

  .col-xl-offset-9 {
    margin-left: 75%;
  }

  .col-xl-offset-10 {
    margin-left: 83.3333%;
  }

  .col-xl-offset-11 {
    margin-left: 91.6667%;
  }

  .col-xl-offset-12 {
    margin-left: 100%;
  }
}

.dropdown .dropdown-menu .caret {
  float: right;
  border: 4px solid rgba(0, 0, 0, 0);
  border-left-color: #000;
  margin-top: 6px;
}

.dropdown-menu > li.open > a {
  color: #262626;
  background-color: #f5f5f5;
  text-decoration: none;
}

.dropdown-menu .dropdown-menu {
  margin: 0;
  top: -1px;
  left: 100%;
  right: auto;
}

.dropdown-menu-right .dropdown-menu, .navbar-right .dropdown-menu .dropdown-menu, .pull-right .dropdown-menu .dropdown-menu {
  left: auto;
  right: 100%;
}

.dropdown-menu.animated {
  animation-duration: .3s;
}

.dropdown-menu.animated:before {
  content: " ";
  height: 100%;
  width: 100%;
  z-index: 99;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.dropdownhover-top {
  margin-top: 0;
  margin-bottom: 2px;
}

.navbar-fixed-bottom .dropdown-menu .dropdown-menu, .dropdownhover-top .dropdown-menu {
  top: auto;
  bottom: -1px;
}

.navbar-nav > li > .dropdown-menu {
  margin-bottom: 0;
}

.dropdownhover-bottom {
  transform-origin: 50% 0;
}

.dropdownhover-left {
  transform-origin: 100%;
}

.dropdownhover-right {
  transform-origin: 0;
}

.dropdownhover-top {
  transform-origin: 50% 100%;
}

.browserupgrade {
  color: #000;
  background: #ccc;
  margin: .2em 0;
  padding: .2em 0;
}

body {
  max-width: 1920px;
  font-weight: 300;
}

@media (max-width: 991px) {
  body {
    margin-top: 60px;
  }
}

@media (min-width: 1600px) {
  body {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 991px) {
  h1, .h1 {
    font-size: 39.6px;
  }

  h2, .h2 {
    font-size: 33.3px;
  }

  h3, .h3 {
    font-size: 27px;
  }

  h4, .h4 {
    font-size: 23.4px;
  }

  h5, .h5 {
    font-size: 18.9px;
  }

  h6, .h6 {
    font-size: 14.4px;
  }
}

.container-fluid {
  max-width: 1920px;
  margin: 0 auto;
}

@media (max-width: 1199px) {
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }

  .container-fluid .row {
    margin-left: -15px;
    margin-right: -15px;
  }

  .container-fluid [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1200px) {
  .container-fluid {
    margin-right: 25px;
  }
}

@media (min-width: 1600px) {
  .container-fluid {
    margin-right: 25px;
  }
}

@media (min-width: 992px) {
  .page-padding {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1200px) {
  .page-padding {
    padding-left: 50px;
    padding-right: 25px;
  }
}

@media (min-width: 1600px) {
  .page-padding {
    padding-left: 50px;
    padding-right: 25px;
  }
}

.row.row--small-gutters {
  margin-left: -5px;
  margin-right: -5px;
}

.row.row--small-gutters > [class^="col-"], .row.row--small-gutters > [class*=" col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.img-fluid {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.Zcaption {
  color: #fff;
  height: auto;
  text-shadow: 1px 1px rgba(0, 0, 0, .35);
  width: 100%;
  background: rgba(164, 164, 164, .6);
  padding: .5em;
  font-size: .9em;
  transition: visibility .5s linear, opacity .15s linear, background .15s linear;
  position: absolute;
  bottom: 0;
}

@media (min-width: 992px) {
  .Zcaption {
    font-size: .9em;
  }
}

@media (min-width: 1200px) {
  .Zcaption {
    padding: .75em;
    font-size: 1em;
  }
}

@media (max-width: 767px) {
  .Zcaption {
    text-align: left;
  }
}

@media (max-width: 1199px) {
  .Zcaption {
    height: 100%;
    background: rgba(113, 113, 113, .8);
    position: relative;
  }
}

@media (min-width: 992px) {
  .Zcaption:hover {
    background: rgba(113, 113, 113, .8);
  }
}

.Zcaption.caption--darker {
  background: rgba(126, 126, 126, .75);
}

@media (max-width: 1199px) {
  .Zcaption.caption--darker {
    background: rgba(113, 113, 113, .8);
  }
}

@media (min-width: 992px) {
  .Zcaption.caption--darker:hover {
    background: rgba(113, 113, 113, .8);
  }
}

.image-rights, .caption__rights {
  text-align: right;
}

.caption__rights {
  margin-top: .33em;
  margin-bottom: -.33em;
  font-size: .9em;
}

.Zcaption.caption--show-hover {
  display: none;
}

@media (min-width: 992px) {
  .Zcaption.caption--show-hover {
    opacity: 0;
    display: block;
  }

  .content-gallery:hover .Zcaption.caption--show-hover {
    visibility: visible;
    opacity: 1;
  }

  .caption--bottom-spaced {
    padding-bottom: 30px;
  }
}

.caption__title, .caption__text, .caption__link {
  color: #fff;
}

.caption__link:hover, .caption__link:focus {
  color: #fff;
  text-decoration: underline;
}

.caption__title {
  margin-top: 0;
  font-size: 140%;
}

@media (min-width: 992px) {
  .caption__title {
    font-size: 170%;
  }
}

.caption__text {
  margin-bottom: 0;
}

.grid {
  margin-top: 20px;
}

.grid .gallery-enlarge {
  width: 100%;
  height: 0;
  background: #b7b7b7 url("data:image/svg+xml,%3Csvg width='100px' height='100px' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid' class='lds-eclipse' style='background: rgb(183, 183, 183);'%3E%3Cpath ng-attr-d='%7B%7Bconfig.pathCmd%7D%7D' ng-attr-fill='%7B%7Bconfig.color%7D%7D' stroke='none' d='M30 50A20 20 0 0 0 70 50A20 22 0 0 1 30 50' fill='%23ffffff' transform='rotate(246 50 51)'%3E%3CanimateTransform attributeName='transform' type='rotate' calcMode='linear' values='0 50 51;360 50 51' keyTimes='0;1' dur='1s' begin='0s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E") center no-repeat;
  display: block;
  position: relative;
}

.grid .img-fitting {
  width: 100%;
  height: auto;
  position: absolute;
}

.grid .caption, .grid .Zcaption {
  z-index: 10;
  line-height: 1.1em;
}

.grid .gallery-enlarge:before {
  z-index: 10;
}

.grid .content-gallery {
  font-size: 9px;
}

@media (min-width: 1200px) {
  .grid .content-gallery {
    font-size: 10px;
  }
}

@media (min-width: 1600px) {
  .grid .content-gallery {
    font-size: 11px;
  }
}

.grid .content-gallery.content-gallery--larger {
  font-size: 10px;
}

@media (min-width: 1200px) {
  .grid .content-gallery.content-gallery--larger {
    font-size: 11px;
  }
}

@media (min-width: 1600px) {
  .grid .content-gallery.content-gallery--larger {
    font-size: 13px;
  }
}

.grid img:not([src]) {
  visibility: hidden;
}

.grid img {
  opacity: 0;
  border: 0;
  display: block;
}

.grid img:not(.initial) {
  transition: opacity 1s;
}

.grid img.initial, .grid img.loaded, .grid img.error {
  opacity: 1;
  pointer-events: none;
}

.grid__item {
  margin-bottom: 10px;
}

.hidden-xl-down {
  display: none !important;
}

@media (min-width: 1600px) {
  .hidden-xl-down {
    display: block !important;
  }
}

.swiper-pagination {
  text-align: center;
  -o-transition: .3s opacity;
  z-index: 10;
  transition: opacity .3s;
  transform: translate3d(0, 0, 0);
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
  width: 100%;
  bottom: 0;
  left: 0;
}

.swiper-pagination-bullets-dynamic {
  font-size: 0;
  overflow: hidden;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  position: relative;
  transform: scale(.33);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active, .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  transform: scale(1);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  transform: scale(.66);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  transform: scale(.33);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  transform: scale(.66);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  transform: scale(.33);
}

.swiper-pagination-bullet {
  width: 1em;
  height: 1em;
  opacity: .5;
  z-index: 10;
  background: #fff;
  border-radius: 100%;
  display: inline-block;
}

button.swiper-pagination-bullet {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  margin: 0;
  padding: 0;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #fff;
}

.swiper-container-vertical > .swiper-pagination-bullets {
  top: 50%;
  right: 10px;
  transform: translate3d(0, -50%, 0);
}

.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 6px 0;
  display: block;
}

.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  width: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -o-transition: .2s transform, .2s top;
  transition: transform .2s, top .2s, -webkit-transform .2s;
  display: inline-block;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px;
}

.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
}

.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -o-transition: .2s transform, .2s left;
  transition: transform .2s, left .2s, -webkit-transform .2s;
}

.swiper-container-horizontal.swiper-container-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -o-transition: .2s transform, .2s right;
  transition: transform .2s, right .2s, -webkit-transform .2s;
}

.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, .25);
  position: absolute;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  width: 100%;
  height: 100%;
  transform-origin: 0 0;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(0);
}

.swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  transform-origin: 100% 0;
}

.swiper-container-horizontal > .swiper-pagination-progressbar, .swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 100%;
  height: 4px;
  top: 0;
  left: 0;
}

.swiper-container-vertical > .swiper-pagination-progressbar, .swiper-container-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 4px;
  height: 100%;
  top: 0;
  left: 0;
}

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff;
}

.swiper-pagination-progressbar.swiper-pagination-white {
  background: rgba(255, 255, 255, .25);
}

.swiper-pagination-progressbar.swiper-pagination-white .swiper-pagination-progressbar-fill {
  background: #fff;
}

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000;
}

.swiper-pagination-progressbar.swiper-pagination-black {
  background: rgba(0, 0, 0, .25);
}

.swiper-pagination-progressbar.swiper-pagination-black .swiper-pagination-progressbar-fill {
  background: #000;
}

.swiper-pagination-lock {
  display: none;
}

@font-face {
  font-family: stiftungen-icons;
  src: url("stiftungen-icons.e50010ee.eot");
  src: url("stiftungen-icons.e50010ee.eot#iefix") format("embedded-opentype"), url("stiftungen-icons.3e5779be.woff") format("woff"), url("stiftungen-icons.1fbdf502.ttf") format("truetype"), url("stiftungen-icons.ac4781a9.svg#stiftungen-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  content: attr(data-icon);
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  font-variant: normal !important;
  text-transform: none !important;
  font-family: stiftungen-icons !important;
  font-style: normal !important;
  font-weight: normal !important;
}

[class^="stiftungen-icon-"]:before, [class*=" stiftungen-icon-"]:before {
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  font-variant: normal !important;
  text-transform: none !important;
  font-family: stiftungen-icons !important;
  font-style: normal !important;
  font-weight: normal !important;
}

.stiftungen-icon-event:before {
  content: "d";
}

.stiftungen-icon-search:before {
  content: "e";
}

.stiftungen-icon-home:before {
  content: "f";
}

.stiftungen-icon-project:before {
  content: "i";
}

.stiftungen-icon-laureate:before {
  content: "a";
}

.stiftungen-icon-concert:before {
  content: "b";
}

.stiftungen-icon-press-release:before {
  content: "c";
}

.stiftungen-icon-teaching:before {
  content: "h";
}

.stiftungen-icon-personalia:before {
  content: "g";
}

#cookies-eu-banner {
  color: #fff;
  text-align: center;
  background: #444;
  padding: 6px;
  font-size: 13px;
}

#cookies-eu-more, #cookies-eu-accept, #cookies-eu-reject {
  color: #fff;
  cursor: pointer;
  background: #222;
  border: 1px solid #000;
  margin: 2px 0;
  padding: 4px 7px;
  font-size: 13px;
  font-weight: bold;
  text-decoration: none;
  transition: background 70ms, color 70ms, border-color 70ms;
}

#cookies-eu-more:hover, #cookies-eu-more:focus, #cookies-eu-accept:hover, #cookies-eu-accept:focus {
  color: #222;
  background: #fff;
}

#cookies-eu-more {
  margin-left: 7px;
}

#cookies-eu-reject {
  color: #ccc;
  cursor: pointer;
  background: none;
  border: 1px solid #666;
  margin: 2px 0 2px 10px;
  padding: 4px 7px;
  font-weight: normal;
}

#cookies-eu-reject:hover, #cookies-eu-reject:focus {
  color: #fff;
  background: #222;
  border-color: #fff;
}

.herounit-container.herounit-laureat[class*="col-"], .herounit-container.herounit-laureat[class*="col-"] .herounit-image {
  padding: 0;
}

.herounit-container {
  overflow: hidden;
}

.herounit {
  height: 100%;
}

@media (min-width: 768px) {
  .herounit {
    margin-top: 10px;
    margin-bottom: 10px;
    display: -ms-flexbox;
    display: flex;
  }
}

@media (min-width: 1200px) {
  .herounit {
    margin-top: 20px;
  }
}

.herounit-content {
  -ms-flex-direction: row;
  flex-direction: row;
  padding-top: 12.5px;
  padding-bottom: 12.5px;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

@media (max-width: 767px) {
  .herounit-content {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media (max-width: 1199px) {
  .herounit-content {
    padding-left: 6.25px;
    padding-right: 6.25px;
  }
}

.herounit-content.herounit-news {
  padding-left: 15px;
}

.herounit-content.herounit-teaser {
  padding-right: 15px;
}

.herounit-content .content-gallery:before {
  content: "";
}

.herounit-content .caption.hero-caption {
  visibility: visible;
  opacity: 1;
  background: rgba(126, 126, 126, .75);
  padding-bottom: 35px;
}

@media (max-width: 767px) {
  .herounit-content .caption.hero-caption {
    text-align: left;
  }
}

@media (max-width: 1199px) {
  .herounit-content .caption.hero-caption {
    height: 100%;
    background: rgba(113, 113, 113, .75);
    position: relative;
  }
}

@media (min-width: 992px) {
  .herounit-content:hover a:before, .herounit-content:hover .caption.hero-caption {
    visibility: visible;
    opacity: 1;
    background: rgba(113, 113, 113, .85);
  }
}

.herounit-quote {
  color: #f6ddd8;
  z-index: 0;
  border: none;
  padding: 0;
  font-size: 15pt;
  font-style: italic;
  font-weight: 300;
  line-height: .9;
}

@media (min-width: 992px) {
  .herounit-quote {
    font-size: 25pt;
    position: absolute;
  }
}

@media (min-width: 1200px) {
  .herounit-quote {
    font-size: 35pt;
  }
}

@media (min-width: 1600px) {
  .herounit-quote {
    font-size: 50pt;
  }
}

.herounit-item {
  color: #fff;
  width: 100%;
  -ms-flex-item-align: end;
  align-self: flex-end;
  overflow: hidden;
}

.herounit-item h2, .herounit-item h3, .herounit-item a {
  color: #fff;
}

.herounit-item h2, .herounit-item h3 {
  margin-top: 0;
  font-size: 140%;
}

@media (min-width: 992px) {
  .herounit-item h2, .herounit-item h3 {
    font-size: 170%;
  }
}

.herounit-item p {
  margin-bottom: 0;
}

.herounit-teaser .herounit-item {
  text-align: left;
  height: 100%;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.herounit-teaser .herounit-item .slider-home-teaser {
  height: 100%;
}

.herounit-image {
  background: #fff;
}

@media (max-width: 991px) {
  .herounit-image {
    padding: 0;
  }
}

.herounit-slim .herounit-image {
  padding-right: 0;
}

.no-flexbox .herounit-content {
  height: 300px;
}

.no-flexbox .herounit-item {
  margin-top: 300px;
}

.balance-text {
  text-wrap: balanced;
}

.herounit-content-below h2 {
  text-transform: uppercase;
  font-size: 22px;
}

.herounit-content-below h2 a {
  color: #898989;
}

.slider-hero-detail .slick-slide {
  height: initial;
}

.slider-hero-detail a.gallery-enlarge {
  display: block;
}

.navbar-stiftungen {
  background: #fff;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  margin: 0;
}

.navbar-stiftungen .navbar-container {
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 991px) {
  .navbar-stiftungen .navbar-container {
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media (min-width: 992px) {
  .navbar-stiftungen.navbar-fixed-top {
    position: relative;
    top: auto;
  }

  .navbar-stiftungen {
    margin-top: 10px;
  }
}

.navbar-stiftungen .navbar-header {
  margin: 0;
}

.navbar-stiftungen .navbar-header .navbar-brand {
  height: auto;
  padding: 0;
}

.navbar-stiftungen .navbar-header .navbar-brand h2 {
  text-transform: uppercase;
}

@media (max-width: 991px) {
  .navbar-stiftungen .navbar-header .navbar-brand h2 {
    text-align: right;
    margin-top: 15px;
    font-size: 16px;
  }

  .navbar-stiftungen .navbar-header .navbar-brand {
    width: 100%;
  }

  .navbar-stiftungen .navbar-header .navbar-brand h2 {
    padding-left: 50px;
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .navbar-stiftungen .navbar-header .navbar-brand h2 {
    margin-top: 15px;
    margin-bottom: 5px;
    padding-left: 15px;
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  .navbar-stiftungen .navbar-header .navbar-brand h2 {
    white-space: nowrap;
    margin-top: 26px;
    margin-bottom: 10px;
    padding-left: 50px;
    font-size: 22px;
  }
}

@media (min-width: 1600px) {
  .navbar-stiftungen .navbar-header .navbar-brand h2 {
    margin-top: 26px;
    margin-bottom: 10px;
    font-size: 24px;
  }
}

.navbar-stiftungen .navbar-header .navbar-toggle {
  float: left;
  border: none;
  margin-right: 0;
  padding: 6px 0;
}

.navbar-stiftungen .navbar-header .navbar-toggle span {
  height: 6px;
  width: 36px;
  background: #fff;
  border: 1px solid #a4a4a4;
  border-radius: 0;
  margin-top: 3px;
}

.navbar-stiftungen .nav-mobile-foundation-select h4 {
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 16px;
}

.navbar-stiftungen .navbar-listnav > li > a {
  color: #444;
  text-transform: uppercase;
  vertical-align: middle;
  padding: 7px 0 4px;
  font-size: 12px;
  line-height: 19px;
}

.navbar-stiftungen .navbar-listnav > li > a:hover, .navbar-stiftungen .navbar-listnav > li > a:focus {
  background: none;
}

.navbar-stiftungen .navbar-listnav > li {
  border-bottom: 1px solid #b4151d;
}

@media (max-width: 991px) {
  .navbar-stiftungen .navbar-listnav > li {
    padding-left: 10px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .navbar-stiftungen .navbar-listnav > li {
    white-space: nowrap;
    padding-left: 30px;
    padding-right: 10px;
  }

  .navbar-stiftungen .navbar-listnav > li > a {
    font-size: 13px;
  }
}

@media (min-width: 1200px) {
  .navbar-stiftungen .navbar-listnav > li {
    padding-left: 50px;
  }

  .navbar-stiftungen .navbar-listnav > li > a {
    font-size: 15px;
  }
}

@media (min-width: 1600px) {
  .navbar-stiftungen .navbar-listnav > li {
    padding-left: 50px;
  }

  .navbar-stiftungen .navbar-listnav > li > a {
    font-size: 16px;
  }
}

.navbar-stiftungen .navbar-listnav > li:hover, .navbar-stiftungen .navbar-listnav > li:focus {
  background: #a4a4a4;
}

.navbar-stiftungen .navbar-listnav > li:hover a, .navbar-stiftungen .navbar-listnav > li:focus a {
  color: #b4151d;
}

.navbar-stiftungen .navbar-listnav > li.active {
  background-color: #b4151d;
}

.navbar-stiftungen .navbar-listnav > li.active a {
  color: #fff;
}

.navbar-stiftungen .navbar-main-collapse {
  -ms-flex-positive: 1;
  flex-grow: 1;
}

@media (min-width: 992px) {
  .navbar-stiftungen .navbar-main-collapse {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.navbar-stiftungen .navbar-collapse {
  max-height: 100%;
  height: 100%;
}

.navbar-stiftungen .navbar-lines {
  -ms-flex-direction: column;
  flex-direction: column;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 768px) {
  .navbar-stiftungen .navbar-lines {
    height: 100%;
  }
}

.navbar-stiftungen .navbar-searchline {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 768px) {
  .navbar-stiftungen .navbar-searchline {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
}

.navbar-stiftungen .btn-borderless {
  color: #898989;
  background: #fff;
  border: none;
  padding: 0;
  line-height: 1;
}

.navbar-stiftungen .btn-borderless:focus {
  outline: none;
}

.navbar-stiftungen .navbar-form .form-control.nav-search-typeahead {
  padding-bottom: 1px;
}

@media (min-width: 768px) {
  .navbar-stiftungen .navbar-form {
    padding-right: 15px;
  }
}

@media (min-width: 1200px) {
  .navbar-stiftungen .navbar-form {
    padding-right: 25px;
  }
}

@media (max-width: 1199px) {
  .navbar-stiftungen .navbar-right {
    margin-right: -15px;
  }
}

.navbar-stiftungen .input-group .btn-borderless {
  margin-left: 2px;
}

.navbar-stiftungen .search-icon {
  margin-left: 10px;
  font-size: 28px;
  line-height: 1;
}

.navbar-stiftungen .search-icon:hover {
  color: #b4151d;
}

.navbar-stiftungen .language-selector > li > a {
  padding: 8px 12px 5px;
  font-size: 12px;
}

.navbar-stiftungen .navbar-navline .navbar-nav {
  margin: 0;
}

@media (min-width: 768px) {
  .navbar-stiftungen .navbar-navline .navbar-nav {
    width: 100%;
    -ms-flex-flow: wrap;
    flex-flow: wrap;
    -ms-flex-pack: end;
    justify-content: flex-end;
    display: -ms-flexbox;
    display: flex;
  }
}

.navbar-stiftungen .navbar-navline .nav > li {
  float: none;
  background: #a4a4a4;
  margin-top: 2px;
  font-size: 12px;
}

@media (min-width: 768px) {
  .navbar-stiftungen .navbar-navline .nav > li {
    margin-left: 2px;
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .navbar-stiftungen .navbar-navline .nav > li {
    font-size: 12px;
  }
}

@media (min-width: 1200px) {
  .navbar-stiftungen .navbar-navline .nav > li {
    font-size: 14px;
  }
}

@media (min-width: 1600px) {
  .navbar-stiftungen .navbar-navline .nav > li {
    font-size: 16px;
  }
}

.navbar-stiftungen .navbar-navline .nav > li > a {
  color: #fff;
  text-transform: uppercase;
  -ms-flex-align: center;
  align-items: center;
  padding: 11px 14px 6px;
  line-height: 20px;
  display: inline-block;
}

.navbar-stiftungen .navbar-navline .nav > li > a:hover {
  color: #cb1821;
  background: none;
}

.navbar-stiftungen .navbar-navline .nav > li.active > a {
  background: #b4151d;
}

@media (max-width: 767px) {
  .navbar-stiftungen .navbar-navline .nav > li.active > a {
    width: 100%;
  }
}

.navbar-stiftungen .navbar-navline .nav > li.active > a:hover, .navbar-stiftungen .navbar-navline .nav > li.active > a:focus {
  color: #1d1d1b;
}

.navbar-stiftungen .nav li.nav-homepage a {
  padding: 4px 22px;
  font-size: 23px;
  line-height: 22px;
}

.navbar-stiftungen .nav li.nav-homepage a .glyphicon {
  top: 3px;
}

@media (max-width: 767px) {
  .navbar-stiftungen .nav li.nav-homepage {
    padding: 0;
  }
}

@media (min-width: 768px) {
  .navbar-stiftungen .nav li.nav-homepage {
    text-align: right;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-left: 10px;
  }
}

.navbar-stiftungen .dropdown-menu {
  box-shadow: none;
  background-color: #ccc;
  border-top: 2px solid #fff;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 0;
}

.navbar-stiftungen .dropdown-menu > li > a {
  text-transform: uppercase;
}

.navbar-stiftungen .navbar-form .dropdown-menu {
  background-color: #eee;
}

.navbar-stiftungen .navbar-form .dropdown-menu > li > a {
  text-transform: none;
}

.no-flexbox .navbar-stiftungen .nav > li {
  float: left;
}

.content-text {
  color: #898989;
  line-height: 1.75;
}

.content-text h2 {
  text-transform: uppercase;
  text-align: left;
  font-size: 24px;
}

@media (min-width: 992px) {
  .content-text h2 {
    font-size: 30px;
  }
}

.content-text h3 {
  color: #000;
  text-transform: uppercase;
  text-align: left;
  font-size: 20px;
}

@media (min-width: 992px) {
  .content-text h3 {
    font-size: 24px;
  }
}

.content .text-intro {
  color: #b4151d;
}

.content-header {
  margin-top: 8px;
}

@media (min-width: 768px) {
  .content-header {
    margin-top: 25px;
  }
}

.content-full {
  margin-bottom: 2em;
}

@media (max-width: 991px) {
  .content-full {
    margin-top: 10px;
  }
}

.content-full .content-full-title {
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: normal;
}

@media (min-width: 768px) {
  .content-full .content-full-title {
    font-size: 23px;
  }
}

@media (min-width: 992px) {
  .content-full .content-full-title {
    font-size: 26px;
  }
}

@media (min-width: 1600px) {
  .content-full .content-full-title {
    font-size: 30px;
  }
}

.content-full .content-full-title.has-condolence-mode {
  color: #000;
}

.content-full .content-body strong, .content-full .content-body b {
  color: #b4151d;
}

.content-full .content-body.has-condolence-mode .content-intro, .content-full .content-body.has-condolence-mode .text-intro, .content-full .content-body.has-condolence-mode strong, .content-full .content-body.has-condolence-mode b {
  color: #000;
}

.content-full .content-intro, .content-full .content-author {
  margin-bottom: 20px;
  font-size: 15px;
}

.content-full .content-intro {
  color: #b4151d;
}

.content-full .content-body {
  text-align: justify;
}

.herounit-gallery {
  padding-bottom: 20px;
}

@media (max-width: 767px) {
  .herounit-gallery {
    margin-top: 15px;
  }
}

.content-thumbnail-container {
  margin-left: 0;
  padding-top: 10px;
  padding-right: 0;
}

.content-thumbnails {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

.content-thumbnails .slick-slider {
  width: 100%;
  overflow: hidden;
}

.content-thumbnails .slick-list {
  width: calc(100% + 20px);
  overflow: hidden;
}

.content-thumbnails .slick-track {
  margin-left: -10px;
}

.content-thumbnails .slick-slide > div {
  margin: 0 10px;
}

.slider-hero-detail .content-gallery img.img-responsive {
  min-width: auto;
  margin-left: auto;
  margin-right: auto;
}

.slide-info {
  z-index: 1;
  background: #fff;
  padding: 5px 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.content-gallery {
  cursor: pointer;
  position: relative;
}

.content-gallery img.img-responsive {
  min-width: 100%;
}

.content-gallery a.gallery-enlarge:before {
  color: #fff;
  text-align: center;
  opacity: 0;
  background: rgba(164, 164, 164, .6);
  padding: 0 .4em;
  font-family: Glyphicons Halflings;
  font-size: 1.6em;
  line-height: 1.8em;
  transition: all .2s ease-in-out;
  position: absolute;
  top: .75em;
  right: 1em;
}

.content-gallery .caption {
  color: #fff;
  cursor: auto;
  height: auto;
  width: 100%;
  text-shadow: 1px 1px rgba(0, 0, 0, .35);
  background: rgba(164, 164, 164, .6);
  padding: 10px;
  font-size: 12px;
  bottom: 0;
}

@media (min-width: 992px) {
  .content-gallery .caption {
    font-size: 13px;
  }
}

@media (min-width: 1200px) {
  .content-gallery .caption {
    font-size: 14px;
  }
}

.content-gallery .caption .image-rights {
  text-align: right;
}

.content-gallery a.gallery-enlarge:before, .content-gallery .caption {
  opacity: 0;
  visibility: hidden;
  background: none;
  transition: visibility .5s linear, opacity .15s linear, background .15s linear;
  position: absolute;
}

@media (min-width: 992px) {
  .content-gallery:hover a.gallery-enlarge:before, .content-gallery:hover .caption {
    visibility: visible;
    opacity: 1;
    background: rgba(164, 164, 164, .6);
  }
}

.uppercase {
  text-transform: uppercase;
}

.content-project-inline {
  margin-bottom: 15px;
}

.funding-inline {
  background: #f6ddd8;
  padding: 10px;
}

.content-inline-title {
  text-transform: uppercase;
  margin-bottom: 4px;
  font-size: 105%;
}

.content-inline-news {
  background: #e0e0e0;
  padding: 10px;
}

.content-inline-news .list-unstyled {
  margin-bottom: 0;
}

.nav-tabs > li.active.parent > a {
  cursor: pointer;
  background: #ddd;
}

.embed--inline {
  margin-bottom: 2em;
}

.modal-reel {
  visibility: hidden;
  width: 100%;
  height: 100%;
  z-index: 1111;
  background-color: rgba(0, 0, 0, .75);
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.modal-reel #image2-reel {
  height: 90vh;
  width: 100%;
  position: absolute;
  top: 25vh;
  overflow: hidden;
}

@media (max-width: 991px) and (orientation: landscape) {
  .modal-reel #image2-reel {
    top: 5vh;
  }
}

@media (min-width: 992px) {
  .modal-reel #image2-reel {
    height: 600px;
    width: 1024px;
    top: calc(50% - 300px);
    left: calc(50% - 512px);
  }
}

.modal-reel.show {
  visibility: visible;
}

.modal-reel .modal-close {
  color: #fff;
  opacity: 1;
  text-shadow: 0 1px #000;
  z-index: 11000;
  margin: 0 14px;
  font-size: 28px;
  position: absolute;
  top: 3%;
  right: 3%;
}

@media (min-width: 992px) {
  .content-person-stream {
    margin-top: 28px;
    margin-bottom: 10px;
  }
}

.content-person {
  margin-top: 15px;
  margin-bottom: 10px;
}

.content-person .content-full-title {
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: normal;
}

@media (min-width: 768px) {
  .content-person .content-full-title {
    font-size: 23px;
  }
}

@media (min-width: 992px) {
  .content-person .content-full-title {
    font-size: 26px;
  }
}

@media (min-width: 1600px) {
  .content-person .content-full-title {
    font-size: 30px;
  }
}

.content-person .content-body strong, .content-person .content-body b {
  color: #b4151d;
}

.content-person .content-intro, .content-person .content-author {
  margin-bottom: 20px;
  font-size: 15px;
}

.content-person .content-intro {
  color: #b4151d;
}

.content-person .content-body {
  text-align: justify;
}

.content-full .content-body .media-body {
  text-align: left;
}

@media (max-width: 767px) {
  .personalia-row div:nth-of-type(n+1) {
    clear: left;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .personalia-row div:nth-of-type(2n+1) {
    clear: left;
  }
}

@media (min-width: 1200px) {
  .personalia-row div:nth-of-type(3n+1) {
    clear: left;
  }
}

@media (max-width: 767px) {
  .personalia-large-row div:nth-of-type(n+1) {
    clear: left;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .personalia-large-row div:nth-of-type(2n+1) {
    clear: left;
  }
}

@media (min-width: 1200px) {
  .personalia-large-row div:nth-of-type(2n+1) {
    clear: left;
  }
}

.content-person {
  margin-bottom: 10px;
}

.content-person img.media-object {
  width: 150px;
  height: 210px;
}

@media (min-width: 768px) {
  .content-person .media-heading {
    font-size: 23px;
  }
}

@media (min-width: 992px) {
  .content-person .media-heading {
    font-size: 26px;
  }
}

@media (min-width: 1200px) {
  .content-person .media-heading {
    font-size: 22px;
  }
}

@media (min-width: 1600px) {
  .content-person .media-heading {
    font-size: 30px;
  }
}

.content-person.content-person-large img.media-object {
  width: 195px;
  height: 273px;
}

@media (min-width: 992px) {
  .content-search-header {
    margin-top: 3em;
  }
}

.search-result {
  min-height: 6em;
  margin-bottom: 1em;
}

.search-result:nth-child(2n+1) {
  clear: both;
}

.content-search .media-left a {
  width: 260px;
  height: 180px;
  background: #e4e4e4;
  display: inline-block;
}

.content-search .media-left a img {
  width: 260px;
  height: 180px;
  margin: auto;
}

@media (min-width: 992px) {
  .content-search .media-left a, .content-search .media-left a img {
    width: 195px;
    height: 135px;
  }
}

@media (min-width: 1200px) {
  .content-search .media-left a, .content-search .media-left a img {
    width: 260px;
    height: 180px;
  }
}

.content-search .media-heading a {
  color: #898989;
}

.content-search .media-body .label {
  font-size: .9em;
}

.homepage-logo {
  text-align: center;
  margin-bottom: 10px;
}

.homepage-logo .img-responsive {
  display: inline-block;
}

.homepage-content-text.content-text h1, .homepage-content-text.content-text h2, .homepage-content-text.content-text h3 {
  margin-bottom: 30px;
}

.homepage-content-text.content-text h1 {
  text-transform: uppercase;
  text-align: left;
  font-size: 24px;
}

@media (min-width: 992px) {
  .homepage-content-text.content-text h1 {
    font-size: 30px;
  }
}

.homepage-content-text.content-text p {
  margin-bottom: 20px;
  font-size: 110%;
}

.Zherounit {
  overflow: hidden;
}

@media (min-width: 768px) {
  .Zherounit {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (min-width: 1200px) {
  .Zherounit {
    margin-top: 20px;
  }
}

.Zherounit .herounit__teaser {
  padding-top: 12.5px;
  padding-bottom: 12.5px;
  overflow: hidden;
}

@media (max-width: 767px) {
  .Zherounit .herounit__teaser {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media (max-width: 1199px) {
  .Zherounit .herounit__teaser {
    padding-left: 6.25px;
    padding-right: 6.25px;
  }
}

@media (min-width: 768px) {
  .Zherounit .herounit__teaser.herounit__teaser--right-padding {
    padding-left: 31.25px;
    padding-right: 25px;
  }
}

@media (min-width: 1200px) {
  .Zherounit .herounit__teaser.herounit__teaser--right-padding {
    padding-left: 50px;
    padding-right: 40px;
  }
}

@media (min-width: 768px) {
  .Zherounit .herounit__teaser.herounit__teaser--left-padding {
    padding-left: 25px;
    padding-right: 31.25px;
  }
}

@media (min-width: 1200px) {
  .Zherounit .herounit__teaser.herounit__teaser--left-padding {
    padding-left: 40px;
    padding-right: 50px;
  }
}

.Zteaser {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  overflow: hidden;
}

.Zteaser .swiper-wrapper {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
}

.teaser__entry {
  max-width: 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

.teaser__content {
  height: 100%;
  font-size: 12px;
  position: relative;
  overflow: hidden;
}

@media (min-width: 992px) {
  .teaser__content {
    font-size: 13px;
  }
}

@media (min-width: 1200px) {
  .teaser__content {
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .teaser__pagination {
    position: absolute;
  }
}

@media (max-width: 1199px) {
  .teaser__pagination {
    background: rgba(113, 113, 113, .8);
  }
}

.tabbed-media {
  background: #f0d8d9;
  padding: .5em 1em;
}

@media (max-width: 991px) {
  .tabbed-media .well {
    padding: 10px;
  }
}

.tabbed-media__title {
  text-align: left;
}

.tabbed-media__description {
  font-size: .9em;
}

.tabbed-media__document-preview {
  display: none;
}

@media (min-width: 992px) {
  .tabbed-media__document-preview {
    font-size: 3em;
    display: block;
  }
}

.tabbed-media--small-margin {
  margin: .3em;
}

@media (max-width: 991px) {
  .tabbed-media__audio_tab .well {
    padding: 5px;
  }

  .tabbed-media__audio_tab .media {
    margin-top: 5px;
  }

  .tabbed-media__audio_tab .aplayer {
    max-width: 100%;
  }

  .tabbed-media__audio_tab .aplayer-music {
    max-width: 60vw;
  }

  .tabbed-media__audio_tab .aplayer .aplayer-info .aplayer-music .aplayer-title {
    white-space: normal;
    font-size: .8em;
  }

  .tabbed-media__audio_tab .aplayer .aplayer-pic {
    width: 40px;
  }

  .tabbed-media__audio_tab .aplayer .aplayer-info {
    margin-left: 35px;
  }

  .tabbed-media__image_tab .well, .tabbed-media__document_tab .well {
    padding: 8px;
    font-size: .85em;
  }

  .tabbed-media__image_tab .media-left, .tabbed-media__document_tab .media-left {
    display: block;
  }

  .tabbed-media__image_tab .media-left img, .tabbed-media__document_tab .media-left img {
    margin: auto;
    padding-bottom: 1em;
  }

  .tabbed-media__image_tab .media-body, .tabbed-media__document_tab .media-body {
    max-width: 100%;
    display: block;
    overflow: scroll;
  }

  .tabbed-media__text_tab .well {
    padding: 8px;
    font-size: .85em;
  }
}

.tabbed-media__control {
  cursor: pointer;
}

@media (min-width: 992px) {
  .tabbed-media__container {
    margin-top: .5em;
    margin-bottom: 1.5em;
  }
}

@media (max-width: 991px) {
  .tabbed-media__block {
    margin-top: .5em;
    margin-bottom: 1.5em;
  }
}

.breadcrumb {
  float: left;
  background-color: #fff;
  border-radius: 0;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

.breadcrumb > li {
  display: inline-block;
}

.breadcrumb > .active {
  color: #898989;
}

.breadcrumb > li:after {
  content: " |";
  color: #b4151d;
  padding: 0 5px;
}

.breadcrumb > li:before {
  content: "";
}

.breadcrumb > .active:after, .breadcrumb > li + li:before {
  content: "";
  padding: 0;
}

.metadata {
  overflow: hidden;
}

@media (min-width: 992px) {
  .metadata {
    margin-top: 30px;
  }
}

.metadata hr {
  clear: both;
  margin-top: 14px;
}

.map-googlemap {
  height: 100%;
  width: 100%;
  min-height: 400px;
}

.map-googlemap.map-slim {
  height: 240px;
  min-height: 240px;
  margin-bottom: 10px;
}

.content-funding-map {
  margin-top: 20px;
}

.content-recipient {
  margin-top: 0;
  font-size: 22px;
}

@media (min-width: 768px) {
  .content-recipient {
    font-size: 23px;
  }
}

@media (min-width: 992px) {
  .content-recipient {
    font-size: 26px;
  }
}

@media (min-width: 1600px) {
  .content-recipient {
    font-size: 30px;
  }
}

.content-funding {
  font-size: 15px;
}

.content-funding span {
  white-space: nowrap;
}

@media (min-width: 768px) {
  .content-funding {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .content-funding {
    font-size: 17px;
  }
}

@media (min-width: 1600px) {
  .content-funding {
    font-size: 20px;
  }
}

.marker-box {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 1.2;
}

.marker-box .marker-year {
  color: #444;
  padding-bottom: 4px;
  font-size: 20px;
}

.marker-box .marker-recipient {
  padding-bottom: 4px;
}

.funding-name {
  padding-bottom: 4px;
  font-size: 18px;
  line-height: 1.2;
}

.funding-amout {
  padding-bottom: 4px;
  font-size: 15px;
  line-height: 1.2;
}

.funding-recipient {
  color: #444;
  font-size: 16px;
  line-height: 1.2;
}

.funding-media {
  margin-top: 0;
  margin-bottom: 10px;
}

.funding-media .media-body {
  min-height: 1.4em;
}

@media (max-width: 767px) {
  .funding-media.funding-media-first .media-body, .funding-media.funding-media-first .media-left {
    border-bottom: 1px solid #898989;
  }

  .funding-media .media-body {
    padding-right: 10px;
  }

  .funding-media .media-body.text-right {
    padding-left: 10px;
    padding-right: 0;
  }
}

@media (max-width: 991px) {
  .content-filter-form {
    padding-bottom: 5px;
  }
}

.content-filter-box.content-filter-buttons a {
  min-width: 200px;
}

@media (max-width: 991px) {
  .content-filter-box {
    width: 100%;
    text-align: right;
    padding-top: 5px;
    display: block;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .content-filter-box.content-filter-box-labeled {
    width: 48%;
    display: inline-block;
  }
}

@media (max-width: 991px) {
  .content-filter-box.content-filter-buttons a {
    min-width: 0;
    width: 48%;
  }

  .content-filter-box.content-filter-buttons a:first-child {
    float: left;
  }
}

@media (min-width: 992px) {
  .content-filter-box {
    padding-left: 10px;
  }
}

.content-filter-box label {
  font-size: 80%;
}

@media (min-width: 768px) {
  .content-filter-header {
    padding-top: 8px;
    display: block;
  }
}

@media (min-width: 992px) {
  .herounit-container.herounit-funding {
    padding-top: 10px;
  }
}

@media (max-width: 991px) {
  .herounit-container.herounit-funding {
    margin-top: 0;
  }
}

.newsslider-teaser .item:nth-child(n+2), .newsslider-teaser-image .item:nth-child(n+2), .slider-home-teaser .item:nth-child(n+2), .slider .item:nth-child(n+2) {
  display: none;
}

.newsslider-teaser-image .slick-slide > div, .slider-home-teaser .slick-slide > div, .slider-home-news .slick-slide > div {
  height: 100%;
}

.slider-projects .slick-slide {
  height: auto;
}

.slick-slider {
  box-sizing: border-box;
  height: 100%;
  display: block;
  position: relative;
}

.slick-slider.slick-slider-draggable {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: pan-y;
  tap-highlight-color: transparent;
}

.slick-list {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  height: 100%;
  transform: translate3d(0, 0, 0);
}

.slick-track {
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.slick-track:focus {
  outline: none;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide:focus {
  outline: none;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0);
  display: block;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-dots {
  z-index: 15;
  width: 80%;
  text-align: center;
  margin-bottom: 4px;
  margin-left: -30%;
  padding-left: 0;
  list-style: none;
  position: absolute;
  bottom: 0;
  left: 40%;
}

.slick-dots li {
  width: 16px;
  height: 16px;
  text-indent: -999px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, .5);
  border: 0;
  border-radius: 10px;
  margin: 0 5px;
  display: inline-block;
}

.slick-dots .slick-active {
  width: 16px;
  height: 16px;
  background-color: #fff;
}

.slick-dots button {
  display: none;
}

.stream-nav-list {
  margin-left: 30px;
  margin-right: 30px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

@media (min-width: 992px) {
  .stream-nav-list {
    margin-left: -30px;
    margin-right: -30px;
  }

  .stream-nav-list.project-stream-slider {
    margin-left: 30px;
    margin-right: 30px;
  }
}

.stream-nav-list .slick-arrow {
  color: #b4151d;
  cursor: pointer;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
  vertical-align: middle;
  width: 30px;
  z-index: 1;
  font-size: 20px;
  line-height: 50px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -30px;
}

@media (min-width: 992px) {
  .stream-nav-list .slick-arrow {
    left: -25px;
  }
}

.stream-nav-list .slick-arrow.slick-disabled {
  color: #a4a4a4;
  cursor: auto;
}

.stream-nav-list .slick-arrow.arrow-right {
  left: auto;
  right: -30px;
}

@media (min-width: 992px) {
  .stream-nav-list .slick-arrow.arrow-right {
    right: 0;
  }
}

.stream-nav-list .slick-track {
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: -ms-flexbox;
  display: flex;
}

.stream-nav-list .slick-current .item {
  color: #b4151d;
  width: 100px;
  font-size: 21pt;
  font-weight: bold;
}

.stream-nav-list .slick-current:focus {
  outline: none;
}

.stream-nav-list .item {
  float: left;
  color: #898989;
  text-align: center;
  vertical-align: bottom;
  font-size: 16pt;
  line-height: 50px;
}

.stream-nav-list .item.stream-nav-upcoming {
  font-size: 15px;
}

@media (min-width: 768px) {
  .stream-nav-list .item.stream-nav-upcoming {
    font-size: 20px;
  }
}

.stream-nav-list .slick-slide {
  cursor: pointer;
}

.stream-nav-list .slick-slide:hover {
  background-color: #f7f7f7;
}

.content-thumbnails .slick-arrow, .slider-detail .slick-arrow, .slider-hero-detail .slick-arrow {
  color: #ddd;
  cursor: pointer;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
  vertical-align: middle;
  width: 60px;
  z-index: 1;
  opacity: 0;
  font-size: 60px;
  position: absolute;
  top: calc(50% - 25px);
  bottom: 0;
  left: -20px;
}

.content-thumbnails .slick-arrow:hover, .slider-detail .slick-arrow:hover, .slider-hero-detail .slick-arrow:hover {
  color: #b4151d;
}

.content-thumbnails .slick-arrow.slick-disabled, .slider-detail .slick-arrow.slick-disabled, .slider-hero-detail .slick-arrow.slick-disabled {
  display: none;
}

.content-thumbnails .slick-arrow.arrow-right, .slider-detail .slick-arrow.arrow-right, .slider-hero-detail .slick-arrow.arrow-right {
  left: auto;
  right: -20px;
}

.content-thumbnails:hover .slick-arrow, .slider-detail:hover .slick-arrow, .slider-hero-detail:hover .slick-arrow, .slider-hero-detail .slick-arrow {
  opacity: 1;
}

.person-arrow {
  color: #f2f2f2;
  cursor: pointer;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
  vertical-align: middle;
  width: 60px;
  z-index: 1;
  font-size: 40px;
  position: absolute;
  top: 250px;
  bottom: 0;
  left: -10px;
}

@media (min-width: 992px) {
  .person-arrow {
    font-size: 60px;
    top: calc(60vh - 60px);
    left: 50px;
  }
}

.person-arrow.slick-disabled {
  display: none;
}

.person-arrow.arrow-right {
  left: auto;
  right: -10px;
}

@media (min-width: 992px) {
  .person-arrow.arrow-right {
    right: 50px;
  }
}

@media (max-width: 991px) {
  .slider-person-image {
    padding: 0 20px;
  }
}

.slider-person-image .slick-track {
  transform-style: preserve-3d;
}

.slider-person-image .item {
  z-index: 1;
}

.slider-person-image .item img {
  z-index: 1;
  height: 280px;
  width: 200px;
  margin: auto;
  transition: all .4s ease-in;
}

@media (min-width: 992px) {
  .slider-person-image .item img {
    height: 600px;
    width: 400px;
  }
}

@media (min-width: 1200px) {
  .slider-person-image .item img {
    height: 600px;
    width: 400px;
  }
}

.slider-person-image .slick-center .item {
  z-index: 2000;
}

.slider-person-image .slick-center .item img {
  z-index: 2000;
  margin: 0 auto;
  transform: translate3d(0, 0, 0)scale(1);
}

.slider-person-image .slick-center + .slick-slide .item {
  z-index: 1;
}

.slider-person-image .slick-center + .slick-slide .item img {
  z-index: 1;
  transform: translate3d(-155px, 0, -1px)scale(.7);
}

@media (min-width: 992px) {
  .slider-person-image .slick-center + .slick-slide .item img {
    transform: translate3d(-210px, 0, -1px)scale(.7);
  }
}

.person-stream-slider {
  margin-left: 30px;
  margin-right: 30px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.person-stream-slider .slick-arrow {
  color: #b4151d;
  cursor: pointer;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
  vertical-align: middle;
  width: 30px;
  z-index: 1;
  font-size: 30px;
  line-height: 52.5px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -30px;
}

.person-stream-slider .slick-arrow.slick-disabled {
  color: #a4a4a4;
  cursor: auto;
}

.person-stream-slider .slick-arrow.arrow-right {
  left: auto;
  right: -30px;
}

.person-stream-slider .slick-track {
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: -ms-flexbox;
  display: flex;
}

.person-stream-slider .item {
  float: left;
  color: #898989;
  text-align: center;
  vertical-align: bottom;
  font-size: 16pt;
  line-height: 50px;
}

.person-stream-slider .slick-current .item {
  color: #b4151d;
  width: 100px;
  font-size: 21pt;
  font-weight: bold;
}

.person-stream-slider .slick-current .item:focus {
  outline: none;
}

.person-stream-slider .slick-slide {
  cursor: pointer;
}

.person-stream-slider .slick-slide:hover {
  background-color: #f7f7f7;
}

.slider-persons .item {
  overflow-y: overlay;
  margin-bottom: 4em;
}

.wagtail-userbar.wagtail-userbar-absolute {
  position: absolute;
  bottom: -3em;
  right: 2em;
}

.content-stream {
  padding-left: 0;
}

.content-stream p {
  color: #b4151d;
}

.content-stream h4 {
  font-size: 16px;
}

@media (min-width: 992px) {
  .content-stream h4 {
    font-size: 16pt;
  }
}

.content-stream a {
  color: #1d1d1b;
}

.content-stream a:focus, .content-stream a:visited {
  color: #444;
}

.content-stream .stream-item {
  margin-bottom: 8px;
  padding: 2px 10px;
  position: relative;
}

.content-stream .stream-item p {
  margin: 2px 0;
  font-size: 13px;
  line-height: 1.3;
}

.content-stream .stream-item:first-child .stream-date {
  margin-top: 0;
}

.content-stream.homepage-stream {
  padding-left: 15px;
}

.homepage-text {
  padding-right: 15px;
}

.stream-date {
  color: #898989;
  text-align: right;
  font-size: 12px;
}

.stream-back {
  clear: left;
  display: block;
}

.stream-intro-date {
  color: #898989;
}

.content-stream-large {
  margin-top: 19px;
}

.content-stream-large .stream-title h1 {
  text-transform: uppercase;
  margin-top: 10px;
  font-size: 20pt;
}

@media (max-width: 767px) {
  .content-stream-large .stream-title h1 {
    font-size: 18px;
  }
}

.stream-title h3 {
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .stream-title h3 {
    font-size: 18px;
  }
}

.content-stream-header {
  margin-bottom: 15px;
}

.herounit-content-intro {
  color: #b4151d;
}

.content-teaser-container {
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}

.content-teaser-box {
  min-height: 100px;
  background: #f4d4d6;
  margin-bottom: 20px;
  padding: 10px;
}

.news-stream-icon {
  color: #5f5f5e;
  width: 35px;
  font-size: 35px;
  font-weight: normal;
  line-height: 1;
  position: absolute;
  top: 12px;
  right: 12px;
}

.stream-item.stream-item-iconed {
  padding-right: 60px;
}

.stream-item:nth-child(2n+1) {
  background: #f6ddd8;
}

.stream-item:nth-child(2n) {
  background: #d3d3d3;
}

.herounit-stream .caption {
  padding-bottom: 35px;
}

.event-details {
  margin-bottom: 15px;
}

.event-details-label {
  font-weight: bold;
}

.newsslider-teaser .slick-slide {
  height: auto;
}

.content-laureat-inline {
  margin-bottom: .5rem;
}

.content-laureat-inline .laureat-prize {
  color: #707070;
}

.tw-grid {
  display: grid;
}

@media (min-width: 768px) {
  .sm\:tw-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 992px) {
  .md\:tw-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media (min-width: 1600px) {
  .xl\:tw-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.tw-prose {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.tw-prose :where(p):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.tw-prose :where([class~="lead"]):not(:where([class~="tw-not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.tw-prose :where(a):not(:where([class~="tw-not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline;
}

.tw-prose :where(strong):not(:where([class~="tw-not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.tw-prose :where(a strong):not(:where([class~="tw-not-prose"] *)) {
  color: inherit;
}

.tw-prose :where(blockquote strong):not(:where([class~="tw-not-prose"] *)) {
  color: inherit;
}

.tw-prose :where(thead th strong):not(:where([class~="tw-not-prose"] *)) {
  color: inherit;
}

.tw-prose :where(ol):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
  list-style-type: decimal;
}

.tw-prose :where(ol[type="A"]):not(:where([class~="tw-not-prose"] *)) {
  list-style-type: upper-alpha;
}

.tw-prose :where(ol[type="a"]):not(:where([class~="tw-not-prose"] *)) {
  list-style-type: lower-alpha;
}

.tw-prose :where(ol[type="A" s]):not(:where([class~="tw-not-prose"] *)) {
  list-style-type: upper-alpha;
}

.tw-prose :where(ol[type="a" s]):not(:where([class~="tw-not-prose"] *)) {
  list-style-type: lower-alpha;
}

.tw-prose :where(ol[type="I"]):not(:where([class~="tw-not-prose"] *)) {
  list-style-type: upper-roman;
}

.tw-prose :where(ol[type="i"]):not(:where([class~="tw-not-prose"] *)) {
  list-style-type: lower-roman;
}

.tw-prose :where(ol[type="I" s]):not(:where([class~="tw-not-prose"] *)) {
  list-style-type: upper-roman;
}

.tw-prose :where(ol[type="i" s]):not(:where([class~="tw-not-prose"] *)) {
  list-style-type: lower-roman;
}

.tw-prose :where(ol[type="1"]):not(:where([class~="tw-not-prose"] *)) {
  list-style-type: decimal;
}

.tw-prose :where(ul):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
  list-style-type: disc;
}

.tw-prose :where(ol > li):not(:where([class~="tw-not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.tw-prose :where(ul > li):not(:where([class~="tw-not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.tw-prose :where(hr):not(:where([class~="tw-not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.tw-prose :where(blockquote):not(:where([class~="tw-not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-left-width: .25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
  font-style: italic;
  font-weight: 500;
}

.tw-prose :where(blockquote p:first-of-type):not(:where([class~="tw-not-prose"] *)):before {
  content: open-quote;
}

.tw-prose :where(blockquote p:last-of-type):not(:where([class~="tw-not-prose"] *)):after {
  content: close-quote;
}

.tw-prose :where(h1):not(:where([class~="tw-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.tw-prose :where(h1 strong):not(:where([class~="tw-not-prose"] *)) {
  color: inherit;
  font-weight: 900;
}

.tw-prose :where(h2):not(:where([class~="tw-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33333;
}

.tw-prose :where(h2 strong):not(:where([class~="tw-not-prose"] *)) {
  color: inherit;
  font-weight: 800;
}

.tw-prose :where(h3):not(:where([class~="tw-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.6;
}

.tw-prose :where(h3 strong):not(:where([class~="tw-not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.tw-prose :where(h4):not(:where([class~="tw-not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.tw-prose :where(h4 strong):not(:where([class~="tw-not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.tw-prose :where(img):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.tw-prose :where(figure > *):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.tw-prose :where(figcaption):not(:where([class~="tw-not-prose"] *)) {
  color: var(--tw-prose-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.tw-prose :where(code):not(:where([class~="tw-not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.tw-prose :where(code):not(:where([class~="tw-not-prose"] *)):before, .tw-prose :where(code):not(:where([class~="tw-not-prose"] *)):after {
  content: "`";
}

.tw-prose :where(a code):not(:where([class~="tw-not-prose"] *)) {
  color: inherit;
}

.tw-prose :where(h1 code):not(:where([class~="tw-not-prose"] *)) {
  color: inherit;
}

.tw-prose :where(h2 code):not(:where([class~="tw-not-prose"] *)) {
  color: inherit;
  font-size: .875em;
}

.tw-prose :where(h3 code):not(:where([class~="tw-not-prose"] *)) {
  color: inherit;
  font-size: .9em;
}

.tw-prose :where(h4 code):not(:where([class~="tw-not-prose"] *)) {
  color: inherit;
}

.tw-prose :where(blockquote code):not(:where([class~="tw-not-prose"] *)) {
  color: inherit;
}

.tw-prose :where(thead th code):not(:where([class~="tw-not-prose"] *)) {
  color: inherit;
}

.tw-prose :where(pre):not(:where([class~="tw-not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding: .857143em 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.tw-prose :where(pre code):not(:where([class~="tw-not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.tw-prose :where(pre code):not(:where([class~="tw-not-prose"] *)):before, .tw-prose :where(pre code):not(:where([class~="tw-not-prose"] *)):after {
  content: none;
}

.tw-prose :where(table):not(:where([class~="tw-not-prose"] *)) {
  width: 100%;
  table-layout: auto;
  text-align: left;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.tw-prose :where(thead):not(:where([class~="tw-not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.tw-prose :where(thead th):not(:where([class~="tw-not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-bottom: .571429em;
  padding-left: .571429em;
  padding-right: .571429em;
  font-weight: 600;
}

.tw-prose :where(tbody tr):not(:where([class~="tw-not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.tw-prose :where(tbody tr:last-child):not(:where([class~="tw-not-prose"] *)) {
  border-bottom-width: 0;
}

.tw-prose :where(tbody td):not(:where([class~="tw-not-prose"] *)) {
  vertical-align: baseline;
}

.tw-prose :where(tfoot):not(:where([class~="tw-not-prose"] *)) {
  border-top-width: 1px;
  border-top-color: var(--tw-prose-th-borders);
}

.tw-prose :where(tfoot td):not(:where([class~="tw-not-prose"] *)) {
  vertical-align: top;
}

.tw-prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: rgba(0, 0, 0, .5);
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.tw-prose :where(video):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.tw-prose :where(figure):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.tw-prose :where(li):not(:where([class~="tw-not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.tw-prose :where(ol > li):not(:where([class~="tw-not-prose"] *)) {
  padding-left: .375em;
}

.tw-prose :where(ul > li):not(:where([class~="tw-not-prose"] *)) {
  padding-left: .375em;
}

.tw-prose :where(.tw-prose > ul > li p):not(:where([class~="tw-not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.tw-prose :where(.tw-prose > ul > li > :first-child):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 1.25em;
}

.tw-prose :where(.tw-prose > ul > li > :last-child):not(:where([class~="tw-not-prose"] *)) {
  margin-bottom: 1.25em;
}

.tw-prose :where(.tw-prose > ol > li > :first-child):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 1.25em;
}

.tw-prose :where(.tw-prose > ol > li > :last-child):not(:where([class~="tw-not-prose"] *)) {
  margin-bottom: 1.25em;
}

.tw-prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="tw-not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.tw-prose :where(hr + *):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 0;
}

.tw-prose :where(h2 + *):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 0;
}

.tw-prose :where(h3 + *):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 0;
}

.tw-prose :where(h4 + *):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 0;
}

.tw-prose :where(thead th:first-child):not(:where([class~="tw-not-prose"] *)) {
  padding-left: 0;
}

.tw-prose :where(thead th:last-child):not(:where([class~="tw-not-prose"] *)) {
  padding-right: 0;
}

.tw-prose :where(tbody td, tfoot td):not(:where([class~="tw-not-prose"] *)) {
  padding: .571429em;
}

.tw-prose :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="tw-not-prose"] *)) {
  padding-left: 0;
}

.tw-prose :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="tw-not-prose"] *)) {
  padding-right: 0;
}

.tw-prose :where(.tw-prose > :first-child):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 0;
}

.tw-prose :where(.tw-prose > :last-child):not(:where([class~="tw-not-prose"] *)) {
  margin-bottom: 0;
}

.tw-prose-2xl {
  font-size: 1.5rem;
  line-height: 1.66667;
}

.tw-prose-2xl :where(p):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
}

.tw-prose-2xl :where([class~="lead"]):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 1.06667em;
  margin-bottom: 1.06667em;
  font-size: 1.25em;
  line-height: 1.46667;
}

.tw-prose-2xl :where(blockquote):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 1.77778em;
  margin-bottom: 1.77778em;
  padding-left: 1.11111em;
}

.tw-prose-2xl :where(h1):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 0;
  margin-bottom: .875em;
  font-size: 2.66667em;
  line-height: 1;
}

.tw-prose-2xl :where(h2):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 1.5em;
  margin-bottom: .833333em;
  font-size: 2em;
  line-height: 1.08333;
}

.tw-prose-2xl :where(h3):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 1.55556em;
  margin-bottom: .666667em;
  font-size: 1.5em;
  line-height: 1.22222;
}

.tw-prose-2xl :where(h4):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 1.66667em;
  margin-bottom: .666667em;
  line-height: 1.5;
}

.tw-prose-2xl :where(img):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.tw-prose-2xl :where(video):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.tw-prose-2xl :where(figure):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.tw-prose-2xl :where(figure > *):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.tw-prose-2xl :where(figcaption):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 1em;
  font-size: .833333em;
  line-height: 1.6;
}

.tw-prose-2xl :where(code):not(:where([class~="tw-not-prose"] *)) {
  font-size: .833333em;
}

.tw-prose-2xl :where(h2 code):not(:where([class~="tw-not-prose"] *)) {
  font-size: .875em;
}

.tw-prose-2xl :where(h3 code):not(:where([class~="tw-not-prose"] *)) {
  font-size: .888889em;
}

.tw-prose-2xl :where(pre):not(:where([class~="tw-not-prose"] *)) {
  border-radius: .5rem;
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 1.2em 1.6em;
  font-size: .833333em;
  line-height: 1.8;
}

.tw-prose-2xl :where(ol):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
  padding-left: 1.58333em;
}

.tw-prose-2xl :where(ul):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
  padding-left: 1.58333em;
}

.tw-prose-2xl :where(li):not(:where([class~="tw-not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.tw-prose-2xl :where(ol > li):not(:where([class~="tw-not-prose"] *)) {
  padding-left: .416667em;
}

.tw-prose-2xl :where(ul > li):not(:where([class~="tw-not-prose"] *)) {
  padding-left: .416667em;
}

.tw-prose-2xl :where(.tw-prose-2xl > ul > li p):not(:where([class~="tw-not-prose"] *)) {
  margin-top: .833333em;
  margin-bottom: .833333em;
}

.tw-prose-2xl :where(.tw-prose-2xl > ul > li > :first-child):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 1.33333em;
}

.tw-prose-2xl :where(.tw-prose-2xl > ul > li > :last-child):not(:where([class~="tw-not-prose"] *)) {
  margin-bottom: 1.33333em;
}

.tw-prose-2xl :where(.tw-prose-2xl > ol > li > :first-child):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 1.33333em;
}

.tw-prose-2xl :where(.tw-prose-2xl > ol > li > :last-child):not(:where([class~="tw-not-prose"] *)) {
  margin-bottom: 1.33333em;
}

.tw-prose-2xl :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="tw-not-prose"] *)) {
  margin-top: .666667em;
  margin-bottom: .666667em;
}

.tw-prose-2xl :where(hr):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 3em;
  margin-bottom: 3em;
}

.tw-prose-2xl :where(hr + *):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 0;
}

.tw-prose-2xl :where(h2 + *):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 0;
}

.tw-prose-2xl :where(h3 + *):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 0;
}

.tw-prose-2xl :where(h4 + *):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 0;
}

.tw-prose-2xl :where(table):not(:where([class~="tw-not-prose"] *)) {
  font-size: .833333em;
  line-height: 1.4;
}

.tw-prose-2xl :where(thead th):not(:where([class~="tw-not-prose"] *)) {
  padding-bottom: .8em;
  padding-left: .6em;
  padding-right: .6em;
}

.tw-prose-2xl :where(thead th:first-child):not(:where([class~="tw-not-prose"] *)) {
  padding-left: 0;
}

.tw-prose-2xl :where(thead th:last-child):not(:where([class~="tw-not-prose"] *)) {
  padding-right: 0;
}

.tw-prose-2xl :where(tbody td, tfoot td):not(:where([class~="tw-not-prose"] *)) {
  padding: .8em .6em;
}

.tw-prose-2xl :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="tw-not-prose"] *)) {
  padding-left: 0;
}

.tw-prose-2xl :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="tw-not-prose"] *)) {
  padding-right: 0;
}

.tw-prose-2xl :where(.tw-prose-2xl > :first-child):not(:where([class~="tw-not-prose"] *)) {
  margin-top: 0;
}

.tw-prose-2xl :where(.tw-prose-2xl > :last-child):not(:where([class~="tw-not-prose"] *)) {
  margin-bottom: 0;
}

.tw-col-span-12 {
  grid-column: span 12 / span 12 !important;
}

.tw-col-span-3 {
  grid-column: span 3 / span 3 !important;
}

.tw-col-span-4 {
  grid-column: span 4 / span 4 !important;
}

.tw-col-span-8 {
  grid-column: span 8 / span 8 !important;
}

.tw-col-span-9 {
  grid-column: span 9 / span 9 !important;
}

.tw-clear-none {
  clear: none !important;
}

.tw-mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.tw-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.tw--mt-6 {
  margin-top: -1.5rem !important;
}

.tw-mb-3 {
  margin-bottom: .75rem !important;
}

.tw-mb-8 {
  margin-bottom: 2rem !important;
}

.tw-mb-auto {
  margin-bottom: auto !important;
}

.tw-ml-2 {
  margin-left: .5rem !important;
}

.tw-mt-4 {
  margin-top: 1rem !important;
}

.tw-mt-auto {
  margin-top: auto !important;
}

.tw-block {
  display: block !important;
}

.tw-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.tw-grid {
  display: grid !important;
}

.tw-hidden {
  display: none !important;
}

.tw-h-12 {
  height: 3rem !important;
}

.tw-h-20 {
  height: 5rem !important;
}

.tw-h-24 {
  height: 6rem !important;
}

.tw-h-4 {
  height: 1rem !important;
}

.tw-h-5 {
  height: 1.25rem !important;
}

.tw-h-64 {
  height: 16rem !important;
}

.tw-h-auto {
  height: auto !important;
}

.tw-h-full {
  height: 100% !important;
}

.tw-w-4 {
  width: 1rem !important;
}

.tw-w-5 {
  width: 1.25rem !important;
}

.tw-w-auto {
  width: auto !important;
}

.tw-w-full {
  width: 100% !important;
}

.tw-max-w-full {
  max-width: 100% !important;
}

.tw-max-w-screen-2xl {
  max-width: 1536px !important;
}

.tw-cursor-pointer {
  cursor: pointer !important;
}

.tw-break-inside-avoid {
  break-inside: avoid !important;
}

.tw-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

.tw-grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
}

.tw-flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.tw-flex-col {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.tw-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.tw-justify-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.tw-justify-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.tw-justify-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.tw-gap-4 {
  gap: 1rem !important;
}

.tw-gap-8 {
  gap: 2rem !important;
}

.tw-gap-x-4 {
  column-gap: 1rem !important;
}

.tw-gap-y-2 {
  row-gap: .5rem !important;
}

.tw-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse)) !important;
}

.tw-overflow-hidden {
  overflow: hidden !important;
}

.tw-whitespace-normal {
  white-space: normal !important;
}

.tw-break-words {
  overflow-wrap: break-word !important;
}

.tw-border {
  border-width: 1px !important;
}

.tw-border-b {
  border-bottom-width: 1px !important;
}

.tw-border-solid {
  border-style: solid !important;
}

.tw-border-transparent {
  border-color: rgba(0, 0, 0, 0) !important;
}

.tw-bg-gray-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity)) !important;
}

.tw-bg-gray-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(229, 229, 229, var(--tw-bg-opacity)) !important;
}

.tw-bg-gray-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(212, 212, 212, var(--tw-bg-opacity)) !important;
}

.tw-bg-stiftungen-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(165, 15, 23, var(--tw-bg-opacity)) !important;
}

.tw-bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
}

.tw-object-contain {
  object-fit: contain !important;
}

.tw-object-center {
  object-position: center !important;
}

.tw-p-2 {
  padding: .5rem !important;
}

.tw-p-4 {
  padding: 1rem !important;
}

.tw-p-8 {
  padding: 2rem !important;
}

.tw-px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.tw-px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.tw-px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.tw-py-12 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.tw-pb-2 {
  padding-bottom: .5rem !important;
}

.tw-pl-0 {
  padding-left: 0 !important;
}

.tw-pt-0 {
  padding-top: 0 !important;
}

.tw-pt-1 {
  padding-top: .25rem !important;
}

.tw-pt-1\.5 {
  padding-top: .375rem !important;
}

.tw-pt-2 {
  padding-top: .5rem !important;
}

.tw-pt-4 {
  padding-top: 1rem !important;
}

.tw-text-left {
  text-align: left !important;
}

.tw-text-center {
  text-align: center !important;
}

.tw-text-2xl {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}

.tw-text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.tw-text-lg {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.tw-text-sm {
  font-size: .875rem !important;
  line-height: 1.25rem !important;
}

.tw-font-bold {
  font-weight: 700 !important;
}

.tw-font-semibold {
  font-weight: 600 !important;
}

.tw-uppercase {
  text-transform: uppercase !important;
}

.tw-leading-6 {
  line-height: 1.5rem !important;
}

.tw-text-gray-500 {
  --tw-text-opacity: 1 !important;
  color: rgba(115, 115, 115, var(--tw-text-opacity)) !important;
}

.tw-text-gray-700 {
  --tw-text-opacity: 1 !important;
  color: rgba(64, 64, 64, var(--tw-text-opacity)) !important;
}

.tw-text-gray-800 {
  --tw-text-opacity: 1 !important;
  color: rgba(38, 38, 38, var(--tw-text-opacity)) !important;
}

.tw-text-gray-900 {
  --tw-text-opacity: 1 !important;
  color: rgba(23, 23, 23, var(--tw-text-opacity)) !important;
}

.tw-text-green-600 {
  --tw-text-opacity: 1 !important;
  color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
}

.tw-text-red-600 {
  --tw-text-opacity: 1 !important;
  color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
}

.tw-text-white {
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}

.tw-text-yellow-600 {
  --tw-text-opacity: 1 !important;
  color: rgba(202, 138, 4, var(--tw-text-opacity)) !important;
}

.tw-opacity-100 {
  opacity: 1 !important;
}

.tw-shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .1) !important;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow) !important;
}

.tw-transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke !important;
  transition-duration: .15s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.tw-transition-opacity {
  transition-property: opacity !important;
  transition-duration: .15s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.tw-duration-300 {
  transition-duration: .3s !important;
}

.tw-ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

[x-cloak] {
  display: none;
}

.hover\:tw-border-b-gray-500:hover {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgba(115, 115, 115, var(--tw-border-opacity)) !important;
}

.hover\:tw-bg-gray-300:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(212, 212, 212, var(--tw-bg-opacity)) !important;
}

.hover\:tw-no-underline:hover {
  text-decoration-line: none !important;
}

.tw-group:hover .group-hover\:tw-bg-stiftungen-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(149, 10, 17, var(--tw-bg-opacity)) !important;
}

.tw-group:hover .group-hover\:tw-opacity-75 {
  opacity: .75 !important;
}

@media print {
  .print\:tw-hidden {
    display: none !important;
  }
}

@media not all and (min-width: 768px) {
  .max-md\:tw-relative {
    position: relative !important;
  }

  .max-md\:tw-float-none {
    float: none !important;
  }

  .max-md\:tw-block {
    display: block !important;
  }

  .max-md\:tw-bg-white {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }
}

@media (min-width: 640px) {
  .sm\:tw-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .sm\:tw-hidden {
    display: none !important;
  }

  .sm\:tw-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .sm\:tw-px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}

@media (min-width: 768px) {
  .md\:tw-clear-both {
    clear: both !important;
  }

  .md\:tw-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .md\:tw-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .md\:tw-whitespace-nowrap {
    white-space: nowrap !important;
  }
}

@media (min-width: 1024px) {
  .lg\:tw-mx-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .lg\:tw-block {
    display: block !important;
  }

  .lg\:tw-h-64 {
    height: 16rem !important;
  }

  .lg\:tw-w-full {
    width: 100% !important;
  }

  .lg\:tw-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .lg\:tw-self-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .lg\:tw-px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}

@media (min-width: 1280px) {
  .xl\:tw-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}

.aplayer {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #fff;
  border-radius: 2px;
  margin: 5px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: normal;
  position: relative;
  overflow: hidden;
  box-shadow: 0 2px 2px rgba(0, 0, 0, .07), 0 1px 5px rgba(0, 0, 0, .1);
}

.aplayer * {
  box-sizing: content-box;
}

.aplayer svg {
  width: 100%;
  height: 100%;
}

.aplayer svg circle, .aplayer svg path {
  fill: #fff;
}

.aplayer.aplayer-withlist .aplayer-info {
  border-bottom: 1px solid #e9e9e9;
}

.aplayer.aplayer-withlist .aplayer-list {
  display: block;
}

.aplayer.aplayer-withlist .aplayer-icon-order, .aplayer.aplayer-withlist .aplayer-info .aplayer-controller .aplayer-time .aplayer-icon.aplayer-icon-menu {
  display: inline;
}

.aplayer.aplayer-withlrc .aplayer-pic {
  height: 90px;
  width: 90px;
}

.aplayer.aplayer-withlrc .aplayer-info {
  height: 90px;
  margin-left: 90px;
  padding: 10px 7px 0;
}

.aplayer.aplayer-withlrc .aplayer-lrc {
  display: block;
}

.aplayer.aplayer-narrow {
  width: 66px;
}

.aplayer.aplayer-narrow .aplayer-info, .aplayer.aplayer-narrow .aplayer-list {
  display: none;
}

.aplayer.aplayer-narrow .aplayer-body, .aplayer.aplayer-narrow .aplayer-pic {
  height: 66px;
  width: 66px;
}

.aplayer.aplayer-fixed {
  z-index: 99;
  max-width: 400px;
  box-shadow: none;
  margin: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: visible;
}

.aplayer.aplayer-fixed .aplayer-list {
  border: 1px solid #eee;
  border-bottom: none;
  margin-bottom: 65px;
}

.aplayer.aplayer-fixed .aplayer-body {
  z-index: 99;
  max-width: 400px;
  background: #fff;
  margin: 0;
  padding-right: 18px;
  transition: all .3s;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.aplayer.aplayer-fixed .aplayer-lrc {
  z-index: 98;
  pointer-events: none;
  text-shadow: -1px -1px #fff;
  margin: 0;
  display: block;
  position: fixed;
  bottom: 10px;
  left: 0;
  right: 0;
}

.aplayer.aplayer-fixed .aplayer-lrc:after, .aplayer.aplayer-fixed .aplayer-lrc:before {
  display: none;
}

.aplayer.aplayer-fixed .aplayer-info {
  transform-origin: 0 0;
  border-top: 1px solid #e9e9e9;
  border-bottom: none;
  transition: all .3s;
  transform: scaleX(1);
}

.aplayer.aplayer-fixed .aplayer-info .aplayer-music {
  width: calc(100% - 105px);
}

.aplayer.aplayer-fixed .aplayer-miniswitcher {
  display: block;
}

.aplayer.aplayer-fixed.aplayer-narrow .aplayer-info {
  display: block;
  transform: scaleX(0);
}

.aplayer.aplayer-fixed.aplayer-narrow .aplayer-body {
  width: 66px !important;
}

.aplayer.aplayer-fixed.aplayer-narrow .aplayer-miniswitcher .aplayer-icon {
  transform: rotateY(0);
}

.aplayer.aplayer-fixed .aplayer-icon-back, .aplayer.aplayer-fixed .aplayer-icon-forward, .aplayer.aplayer-fixed .aplayer-icon-lrc, .aplayer.aplayer-fixed .aplayer-icon-play {
  display: inline-block;
}

.aplayer.aplayer-fixed .aplayer-icon-back, .aplayer.aplayer-fixed .aplayer-icon-forward, .aplayer.aplayer-fixed .aplayer-icon-menu, .aplayer.aplayer-fixed .aplayer-icon-play {
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 27px;
}

.aplayer.aplayer-fixed .aplayer-icon-back {
  right: 75px;
}

.aplayer.aplayer-fixed .aplayer-icon-play {
  right: 50px;
}

.aplayer.aplayer-fixed .aplayer-icon-forward {
  right: 25px;
}

.aplayer.aplayer-fixed .aplayer-icon-menu {
  right: 0;
}

.aplayer.aplayer-arrow .aplayer-icon-loop, .aplayer.aplayer-arrow .aplayer-icon-order, .aplayer.aplayer-mobile .aplayer-icon-volume-down {
  display: none;
}

.aplayer.aplayer-loading .aplayer-info .aplayer-controller .aplayer-loading-icon {
  display: block;
}

.aplayer.aplayer-loading .aplayer-info .aplayer-controller .aplayer-bar-wrap .aplayer-bar .aplayer-played .aplayer-thumb {
  transform: scale(1);
}

.aplayer .aplayer-body {
  position: relative;
}

.aplayer .aplayer-icon {
  width: 15px;
  height: 15px;
  cursor: pointer;
  opacity: .8;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  font-size: 12px;
  display: inline-block;
}

.aplayer .aplayer-icon path {
  transition: all .2s ease-in-out;
}

.aplayer .aplayer-icon-back, .aplayer .aplayer-icon-forward, .aplayer .aplayer-icon-lrc, .aplayer .aplayer-icon-order, .aplayer .aplayer-icon-play {
  display: none;
}

.aplayer .aplayer-icon-lrc-inactivity svg {
  opacity: .4;
}

.aplayer .aplayer-icon-forward {
  transform: rotate(180deg);
}

.aplayer .aplayer-lrc-content {
  display: none;
}

.aplayer .aplayer-pic {
  float: left;
  height: 66px;
  width: 66px;
  cursor: pointer;
  background-position: 50%;
  background-size: cover;
  transition: all .3s;
  position: relative;
}

.aplayer .aplayer-pic:hover .aplayer-button {
  opacity: 1;
}

.aplayer .aplayer-pic .aplayer-button {
  opacity: .8;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  background: rgba(0, 0, 0, .2);
  border-radius: 50%;
  transition: all .1s;
  position: absolute;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
}

.aplayer .aplayer-pic .aplayer-button path {
  fill: #fff;
}

.aplayer .aplayer-pic .aplayer-hide {
  display: none;
}

.aplayer .aplayer-pic .aplayer-play {
  width: 26px;
  height: 26px;
  border: 2px solid #fff;
  margin: 0 -15px -15px 0;
  bottom: 50%;
  right: 50%;
}

.aplayer .aplayer-pic .aplayer-play svg {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 3px;
  left: 4px;
}

.aplayer .aplayer-pic .aplayer-pause {
  width: 16px;
  height: 16px;
  border: 2px solid #fff;
  bottom: 4px;
  right: 4px;
}

.aplayer .aplayer-pic .aplayer-pause svg {
  height: 12px;
  width: 12px;
  position: absolute;
  top: 2px;
  left: 2px;
}

.aplayer .aplayer-info {
  height: 66px;
  box-sizing: border-box;
  margin-left: 66px;
  padding: 14px 7px 0 10px;
}

.aplayer .aplayer-info .aplayer-music {
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
  cursor: default;
  height: 20px;
  margin: 0 0 13px 5px;
  padding-bottom: 2px;
  overflow: hidden;
}

.aplayer .aplayer-info .aplayer-music .aplayer-title {
  font-size: 14px;
}

.aplayer .aplayer-info .aplayer-music .aplayer-author {
  color: #666;
  font-size: 12px;
}

.aplayer .aplayer-info .aplayer-controller {
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.aplayer .aplayer-info .aplayer-controller .aplayer-bar-wrap {
  -ms-flex: 1;
  flex: 1;
  margin: 0 0 0 5px;
  padding: 4px 0;
  cursor: pointer !important;
}

.aplayer .aplayer-info .aplayer-controller .aplayer-bar-wrap:hover .aplayer-bar .aplayer-played .aplayer-thumb {
  transform: scale(1);
}

.aplayer .aplayer-info .aplayer-controller .aplayer-bar-wrap .aplayer-bar {
  height: 2px;
  width: 100%;
  background: #cdcdcd;
  position: relative;
}

.aplayer .aplayer-info .aplayer-controller .aplayer-bar-wrap .aplayer-bar .aplayer-loaded {
  height: 2px;
  background: #aaa;
  transition: all .5s;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.aplayer .aplayer-info .aplayer-controller .aplayer-bar-wrap .aplayer-bar .aplayer-played {
  height: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.aplayer .aplayer-info .aplayer-controller .aplayer-bar-wrap .aplayer-bar .aplayer-played .aplayer-thumb {
  height: 10px;
  width: 10px;
  cursor: pointer;
  border-radius: 50%;
  margin-top: -4px;
  margin-right: -10px;
  transition: all .3s ease-in-out;
  position: absolute;
  top: 0;
  right: 5px;
  transform: scale(0);
}

.aplayer .aplayer-info .aplayer-controller .aplayer-time {
  height: 17px;
  color: #999;
  padding-left: 7px;
  font-size: 11px;
  position: relative;
  bottom: 4px;
  right: 0;
}

.aplayer .aplayer-info .aplayer-controller .aplayer-time .aplayer-time-inner {
  vertical-align: middle;
}

.aplayer .aplayer-info .aplayer-controller .aplayer-time .aplayer-icon {
  cursor: pointer;
  transition: all .2s;
}

.aplayer .aplayer-info .aplayer-controller .aplayer-time .aplayer-icon path {
  fill: #666;
}

.aplayer .aplayer-info .aplayer-controller .aplayer-time .aplayer-icon.aplayer-icon-loop {
  margin-right: 2px;
}

.aplayer .aplayer-info .aplayer-controller .aplayer-time .aplayer-icon:hover path {
  fill: #000;
}

.aplayer .aplayer-info .aplayer-controller .aplayer-time .aplayer-icon.aplayer-icon-menu, .aplayer .aplayer-info .aplayer-controller .aplayer-time.aplayer-time-narrow .aplayer-icon-menu, .aplayer .aplayer-info .aplayer-controller .aplayer-time.aplayer-time-narrow .aplayer-icon-mode {
  display: none;
}

.aplayer .aplayer-info .aplayer-controller .aplayer-volume-wrap {
  margin-left: 3px;
  display: inline-block;
  position: relative;
  cursor: pointer !important;
}

.aplayer .aplayer-info .aplayer-controller .aplayer-volume-wrap:hover .aplayer-volume-bar-wrap {
  height: 40px;
}

.aplayer .aplayer-info .aplayer-controller .aplayer-volume-wrap .aplayer-volume-bar-wrap {
  width: 25px;
  height: 0;
  z-index: 99;
  transition: all .2s ease-in-out;
  position: absolute;
  bottom: 15px;
  right: -3px;
  overflow: hidden;
}

.aplayer .aplayer-info .aplayer-controller .aplayer-volume-wrap .aplayer-volume-bar-wrap.aplayer-volume-bar-wrap-active {
  height: 40px;
}

.aplayer .aplayer-info .aplayer-controller .aplayer-volume-wrap .aplayer-volume-bar-wrap .aplayer-volume-bar {
  width: 5px;
  height: 35px;
  background: #aaa;
  border-radius: 2.5px;
  position: absolute;
  bottom: 0;
  right: 10px;
  overflow: hidden;
}

.aplayer .aplayer-info .aplayer-controller .aplayer-volume-wrap .aplayer-volume-bar-wrap .aplayer-volume-bar .aplayer-volume {
  width: 5px;
  transition: all .1s;
  position: absolute;
  bottom: 0;
  right: 0;
}

.aplayer .aplayer-info .aplayer-controller .aplayer-loading-icon {
  display: none;
}

.aplayer .aplayer-info .aplayer-controller .aplayer-loading-icon svg {
  animation: 1s linear infinite rotate;
  position: absolute;
}

.aplayer .aplayer-lrc {
  height: 30px;
  text-align: center;
  margin: -10px 0 7px;
  display: none;
  position: relative;
  overflow: hidden;
}

.aplayer .aplayer-lrc:before {
  height: 10%;
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ffffff", endColorstr= "#00ffffff", GradientType= 0);
  background: linear-gradient(#fff 0, rgba(255, 255, 255, 0));
  top: 0;
}

.aplayer .aplayer-lrc:after, .aplayer .aplayer-lrc:before {
  z-index: 1;
  width: 100%;
  content: " ";
  display: block;
  position: absolute;
  overflow: hidden;
}

.aplayer .aplayer-lrc:after {
  height: 33%;
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#00ffffff", endColorstr= "#ccffffff", GradientType= 0);
  background: linear-gradient(rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, .8));
  bottom: 0;
}

.aplayer .aplayer-lrc p {
  color: #666;
  opacity: .4;
  font-size: 12px;
  transition: all .5s ease-out;
  overflow: hidden;
  height: 16px !important;
  margin: 0 !important;
  padding: 0 !important;
  line-height: 16px !important;
}

.aplayer .aplayer-lrc p.aplayer-lrc-current {
  opacity: 1;
  min-height: 16px;
  overflow: visible;
  height: auto !important;
}

.aplayer .aplayer-lrc.aplayer-lrc-hide {
  display: none;
}

.aplayer .aplayer-lrc .aplayer-lrc-contents {
  width: 100%;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
  cursor: default;
  transition: all .5s ease-out;
}

.aplayer .aplayer-list {
  will-change: height;
  transition: all .5s;
  display: none;
  overflow: hidden;
}

.aplayer .aplayer-list.aplayer-list-hide {
  max-height: 0 !important;
}

.aplayer .aplayer-list ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow-y: auto;
}

.aplayer .aplayer-list ol::-webkit-scrollbar {
  width: 5px;
}

.aplayer .aplayer-list ol::-webkit-scrollbar-thumb {
  background-color: #eee;
  border-radius: 3px;
}

.aplayer .aplayer-list ol::-webkit-scrollbar-thumb:hover {
  background-color: #ccc;
}

.aplayer .aplayer-list ol li {
  height: 32px;
  cursor: pointer;
  border-top: 1px solid #e9e9e9;
  margin: 0;
  padding: 0 15px;
  font-size: 12px;
  line-height: 32px;
  transition: all .2s;
  position: relative;
  overflow: hidden;
}

.aplayer .aplayer-list ol li:first-child {
  border-top: none;
}

.aplayer .aplayer-list ol li:hover {
  background: #efefef;
}

.aplayer .aplayer-list ol li.aplayer-list-light {
  background: #e9e9e9;
}

.aplayer .aplayer-list ol li.aplayer-list-light .aplayer-list-cur {
  display: inline-block;
}

.aplayer .aplayer-list ol li .aplayer-list-cur {
  width: 3px;
  height: 22px;
  cursor: pointer;
  display: none;
  position: absolute;
  top: 5px;
  left: 0;
}

.aplayer .aplayer-list ol li .aplayer-list-index {
  color: #666;
  cursor: pointer;
  margin-right: 12px;
}

.aplayer .aplayer-list ol li .aplayer-list-author {
  color: #666;
  float: right;
  cursor: pointer;
}

.aplayer .aplayer-notice {
  opacity: 0;
  color: #fff;
  pointer-events: none;
  color: #909399;
  background-color: #f4f4f5;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 12px;
  transition: all .3s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

.aplayer .aplayer-miniswitcher {
  height: 100%;
  width: 18px;
  background: #e6e6e6;
  border-radius: 0 2px 2px 0;
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.aplayer .aplayer-miniswitcher .aplayer-icon {
  height: 100%;
  width: 100%;
  transition: all .3s;
  transform: rotateY(180deg);
}

.aplayer .aplayer-miniswitcher .aplayer-icon path {
  fill: #666;
}

.aplayer .aplayer-miniswitcher .aplayer-icon:hover path {
  fill: #000;
}

@keyframes aplayer-roll {
  0% {
    left: 0;
  }

  to {
    left: -100%;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

/*# sourceMappingURL=index.fd3d5d13.css.map */
